<template>
  <v-dialog v-model="show" :width="isMobileMode?'100%':'25%'" persistent>
    <v-card id="card">
      <v-system-bar dark id="title">
        <span style="color:white">填写: 联系人信息</span>
        <v-spacer></v-spacer>
        <v-btn icon @click="close">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-system-bar>

      <v-card-text id="card-text">
        <v-form ref="form" v-model="valid" lazy-validation>
          <label>
            联系人
          </label>

          <v-text-field
            v-model="clientInfo.name"
            label="请填写"
            :rules="requireRules"
            background-color="bg"
            single-line
            filled
            dense
          />
          <label>
            公司
          </label>
          <v-text-field
            v-model="clientInfo.corp"
            label="请填写"
            single-line
            background-color="bg"
            filled
            dense
          />
          <label>
            邮箱
          </label>
          <v-text-field
            v-model="clientInfo.email"
            label="请填写"
            :rules="requireRules"
            single-line
            background-color="bg"
            filled
            dense
          />
          <label>
            手机号
          </label>
          <v-text-field
            v-model="clientInfo.phone"
            label="请填写"
            :rules="requireRules"
            single-line
            background-color="bg"
            filled
            dense
          />
          <label>
            应用场景
          </label>
          <v-select
            v-model="clientInfo.scene"
            label="请填写"
            :items="ENUMS.SCENE"
            multiple
            single-line
            background-color="bg"
            filled
            dense
          ></v-select>
        </v-form>
        <div
          class="d-flex justify-space-between mb-6"
          style="padding-top: 20px;margin:0"
        >
          <v-btn
            :disabled="!valid"
            color="primary"
            width="120px"
            height="30px"
            @click="submit"
            type="sumbit"
          >
            提交
          </v-btn>
          <v-btn
            color="disable"
            width="120px"
            height="30px"
            @click="() => $refs.form.reset()"
          >
            <span style="color:white">清空</span>
          </v-btn>
        </div>
      </v-card-text>

      <!-- <v-card-actions>
        
      </v-card-actions> -->
    </v-card>
    <v-snackbar v-model="snackbar" :timeout="timeout" :top="true">
      {{ text }}

      <template v-slot:action="{ attrs }">
        <v-btn color="blue" text v-bind="attrs" @click="snackbar = false">
          Close
        </v-btn>
      </template>
    </v-snackbar>
  </v-dialog>
</template>

<script>
import ENUMS from "../cmn/js/enums";
import CMN from "../cmn/js/common";
import { cloudy } from "../cmn/model";
export default {
  name: "ClientInfoDlg",
  props: {
    // value:{
    //   type: Boolean
    // }
    isMobileMode: {
      type: Boolean,
      default: false,
    },
  },
  


  data() {
    return {
      ENUMS,
      show: false,
      snackbar: false,
      text: "提交信息成功！",
      timeout: 2000,
      clientInfo: {
        name: null,
        corp: "",
        email: "",
        phone: "",
        scene: null,
      },
      valid: true,
      requireRules: [(value) => !!value || "本项必填"],
    };
  },
  mounted() {
    let jsonStr = localStorage.getItem("clientInfo");
    try {
      this.clientInfo = JSON.parse(jsonStr);
      console.log("@@@@234", this.clientInfo);
    } catch {}
    if (!this.clientInfo) {
      //    this.clientInfo = {name:'',corp:'',email:'',phone:'',scene:null}
      this.clientInfo = {};
    }
  },
  watch: {},

  methods: {
    open() {
      this.show = true;
    },
    close() {
      this.show = false;
    },
    submit() {
      if (!this.$refs.form.validate()) return;

      this.$emit("submit", CMN.deepCopy(this.clientInfo));
      this.snackbar = true;
      this.close();
    },
  },
};
</script>

<style scoped>
#card {
  background: var(--v-bg2-base) !important;
  padding-bottom: 1px;
}
#title {
  font-size: 18px;
  font-weight: 700;
  padding-top: 23px;
  padding-bottom: 23px;
  padding-left: 30px;
  padding-right: 10px;
  background: var(--v-primary-base) !important;
}
#card-text {
  width: 90%;
  height: 90%;
  margin: 5%;
  padding: 5%;
  padding-bottom: 1px;
  background: var(--v-bg_white-base) !important;
  border-radius: 5px;
}
</style>
