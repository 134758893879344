



































































































































































































































































































































import Vue from "vue";
import { Component, Prop, Watch } from "vue-property-decorator";

import ENUMS from "../cmn/js/enums";
import CMN from "../cmn/js/common";
import TAGS from "../cmn/js/tags";
import {
  PricePayTimeGranularity,
  ProductCate,
  PricePayType,
  PayMethod,
  getUiItems_DbArchType,
  getUiItems_DbSpecType,
  getUiItems_LbType,
  getUiItems_OsType,
  getUiItems_ZoneType,
} from "../cmn/model";

@Component({ components: {} })
export default class ProductFilters extends Vue {
  @Prop({
    type: String,
    default: function(): String {
      return "华南";
    },
  })
  area!: String;
  @Prop({
    type: String,
    default: function(): String {
      return ProductCate.VM;
    },
  })
  productCate!: ProductCate;
  @Prop({
    type: Object,
    default: function(): Object {
      return {};
    },
  })
  productCoreConf!: any;

  TAGS = TAGS;
  ENUMS = ENUMS;
  // filter=CMN.deepCopy(this.value)
  filter = {};
  ProductCate = ProductCate;
  PricePayType = PricePayType;

  payMethod = new PayMethod();
  spread = 0;

  dbArchTypes = getUiItems_DbArchType();
  dbSpecTypes = getUiItems_DbSpecType();
  lbTypes = getUiItems_LbType();
  osTypes = getUiItems_OsType();
  zoneTypes = getUiItems_ZoneType();


  get coreConfDispTxt() {
    let txt = `【${this.area}】`;
    switch (this.productCate) {
      case ProductCate.VM:
        txt += `【${this.productCoreConf.cpu}核 ${this.productCoreConf.mem}G】【系统盘${this.productCoreConf.sysDiskSize}G】【数据盘${this.productCoreConf.datDiskSize}G】【公网${this.productCoreConf.bandwidth}M】`;
        break;
      case ProductCate.DB:
        txt += `【${this.productCoreConf.cpu}核 ${this.productCoreConf.mem}G】【${this.productCoreConf.engine}-${this.productCoreConf.engineVer}】【存储${this.productCoreConf.datDiskSize}G】`;
        break;
      case ProductCate.LB:
        txt += `【公网${this.productCoreConf.bandwidth}M】`;
        break;
      case ProductCate.OS:
        txt += `【存储${this.productCoreConf.osStoreSize}G】【每月流量${this.productCoreConf.osFlowSize}G】`;
        break;
      case ProductCate.CDN:
        txt += `【每月流量${this.productCoreConf.cdnFlowSize}G】`;
        break;
    }
    return txt;
  }
  get getFilterDetailTxt() {
    let txt = `【${this.payMethod.payType}:${
      this.payMethod.granCnt
    }${this.payTimeGranToText(this.payMethod.granType)}】`;

    // for(let k in this.filter){
    // }

    switch (this.productCate) {
      case ProductCate.VM:
        txt += `【实例类型:${this.tagsTxt(
          this.filter.vmTags,
          this.TAGS.vmType
        )}】【系统盘类型:${this.tagsTxt(
          this.filter.sysDiskTags,
          this.TAGS.diskType
        )}】【数据盘类型:${this.tagsTxt(
          this.filter.diskTags,
          this.TAGS.diskType
        )}】`;
        break;
      case ProductCate.DB:
        txt += `【实例类型:${this.tagsTxt(
          this.filter.dbSpecTags,
          this.dbSpecTypes
        )}】【架构类型:${this.tagsTxt(
          this.filter.dbArchTags,
          this.dbArchTypes
        )}】【存储类型:${this.tagsTxt(
          this.filter.dbDiskTags,
          this.TAGS.diskType
        )}】`;
        break;
      case ProductCate.LB:
        txt += `【实例类型:${this.tagsTxt(this.filter.lbTags, this.lbTypes)}】`;
        break;
      case ProductCate.OS:
        txt += `【存储类型:${this.tagsTxt(this.filter.osTags, this.osTypes)}】`;
        break;
    }
    return txt;
  }
  tagsTxt(tags: string[], uiItems: any[]) {
    if (!tags || tags.length <= 0) return "不限";

    let txts: string[] = [];
    tags.forEach((tag) => {
      uiItems.some((uiItem) => {
        if (uiItem.value == tag) {
          txts.push(uiItem.text);
          return true;
        }
      });
    });

    return txts.join(",");
  }

  get payTimeGranCntRule() {
    let rules = [];
    if (this.filter.payTimeGran === PricePayTimeGranularity.Month) {
      rules.push((input: number) => {
        if (input === undefined || input === null || input <= 0 || input > 11) {
          return "输入范围在1~11";
        }
        return true;
      });
    }
    return rules;
  }

  @Watch("payMethod", { deep: true })
  onPayMethodChanged(n: PayMethod, o: PayMethod) {
    this.$emit("onPayMethodChanged", CMN.deepCopy(n));
  }

  change() {
    console.log(this.filter);
    this.$emit("onFilterChanged", CMN.deepCopy(this.filter));
  }
  payTimeGranToText(payTimeGran: PricePayTimeGranularity) {
    switch (payTimeGran) {
      case PricePayTimeGranularity.Hour:
        return "小时";
      case PricePayTimeGranularity.Day:
        return "天";
      case PricePayTimeGranularity.Week:
        return "周";
      case PricePayTimeGranularity.Month:
        return "月";
      case PricePayTimeGranularity.Year:
        return "年";
      default:
        return "?????";
    }
  }
  // getVesions(){
  //   return ENUMS.DB_ENGINE.filter(r=>r.value == this.filter.type)[0].versions
  // }

  getGranTypes(granType: PricePayType) {
    if (
      granType == PricePayType.PrevPay ||
      this.productCate == ProductCate.OS
    ) {
      return [
        { text: "月", value: 24 * 30 },
        { text: "年", value: 24 * 360 },
      ];
    }
    return [
      { text: "小时", value: 1 },
      { text: "天", value: 24 },
      { text: "月", value: 24 * 30 },
      { text: "年", value: 24 * 360 },
    ];
  }

  resetFilter() {
    this.filter = CMN.deepCopy(this.value);
    console.log("ddddd", this.filter);
  }
}
