<template>
  <div class="dlg">
    <div class="top">
      <div style="display: inline-block;width:47%">
        <label>
          产品大类
        </label>
        <v-select
          v-model="productCate"
          label="请选择"
          :items="ENUMS.PRODUCK_CATE"
          item-value="v"
          item-text="t"
          @change="searchPageData"
          background-color="white"
          hide-details
          clearable
          single-line
          filled
          dense
        ></v-select>
      </div>
      <div style="display: inline-block;width:47%">
        <label>
          关键字
        </label>
        <v-text-field
          v-model="keyword"
          label="请填写"
          @change="searchPageData"
          clearable
          autofocus
          background-color="white"
          single-line
          filled
          dense
        >
          <template slot="append">
            <v-icon @click="searchPageData">
              mdi-magnify
            </v-icon>
          </template>
        </v-text-field>
      </div>
    </div>
    <v-layout>
      <v-flex md12>
        <v-tabs hide-slider height="40px" v-model="tab" background-color="bg">
          <v-tab
            v-for="(data, idx) in this.dataMap4View"
            :key="idx"
            :style="
              Object.keys(dataMap4View)[tab] == idx
                ? 'margin-right:15px;width:150px;background:var(--v-primary-base);color:white'
                : 'margin-right:15px;width:150px;background:white;color:var(--v-primary-base)'
            "
          >
            {{ idx }}</v-tab
          >
          <v-tab-item
            v-for="(data, idx) in this.dataMap4View"
            :key="idx"
            style="background:var(--v-bg-base);padding-top:25px"
          >
            <v-layout row wrap>
              <v-flex
                xs12
                sm6
                md4
                lg3
                xl3
                pa-1
                v-for="(specialOffer, idx2) in data"
                :key="idx2"
              >
                <SpecialOfferCard
                  :specialOffer="specialOffer"
                ></SpecialOfferCard>
              </v-flex>
            </v-layout>
          </v-tab-item>
        </v-tabs>
      </v-flex>
    </v-layout>
  </div>
</template>
<script>
import SpecialOfferCard from "./SpecialOfferCard";
import ENUMS from "../cmn/js/enums";
import DATEUTIL from "../cmn/js/dateUtil";
import CMN from "@/cmn/js/common";
import REQUESTS from "@/cmn/js/requests";

export default {
  components: { SpecialOfferCard },
  data() {
    return {
      ENUMS,
      show: true,
      dataMap: {},
      dataMap4View: {},
      keyword: null,
      productCate: "全部",
      localstoreKey: "specialOffersMap",
      tab: 0,
    };
  },
  mounted: function() {
    this.search();
  },
  methods: {
    async search() {
      this.dataMap = CMN.getLocalstore("specialOffersMap", 5 * 60 * 1000);
      if (!this.dataMap) {
        let endSTs = new Date().valueOf() - 7 * 24 * 60 * 60;
        this.currData = await REQUESTS.getSpecialOffers(endSTs);
        this.dataMap = this.handleData(this.currData);
        CMN.setLocalstore("specialOffersMap", this.dataMap);
        console.log("get specialOffers");
      }
      this.dataMap4View = CMN.deepCopy(this.dataMap);
      console.log("----------------", this.dataMap);
    },

    handleData(oriData) {
      if (!oriData) {
        return null;
      }
      let dataMap = {};

      oriData.forEach((d) => {
        d.begin = DATEUTIL.date2Str(new Date(d.begin));
        d.end = DATEUTIL.date2Str(new Date(d.end));
        let toDay = DATEUTIL.date2Str(new Date());

        if (d.begin <= toDay && d.end >= toDay) {
          // console.log("111111111111",begin,end,toDay)
          d["colour"] = ENUMS.SPECIAL_OFFER_STATUS.ongoing.colour;
        }
        if (d.begin > toDay && d.end > toDay) {
          d["colour"] = ENUMS.SPECIAL_OFFER_STATUS.noStart.colour;
        }
        if (d.begin < toDay && d.end < toDay) {
          d["colour"] = ENUMS.SPECIAL_OFFER_STATUS.ended.colour;
        }

        if (!dataMap[d.manufacturer]) {
          dataMap[d.manufacturer] = [];
        }

        dataMap[d.manufacturer].push(d);
      });

      var thisVue = this;

      Object.keys(dataMap).forEach((vendor) => {
        if (dataMap[vendor] && dataMap[vendor].length > 1) {
          dataMap[vendor] = dataMap[vendor].sort(thisVue.colourSort);
        }
      });

      return dataMap;
    },

    colourSort(a, b) {
      let an, bn;
      switch (a.colour) {
        case ENUMS.SPECIAL_OFFER_STATUS.ongoing.colour:
          an = 0;
          break;
        case ENUMS.SPECIAL_OFFER_STATUS.noStart.colour:
          an = 1;
          break;
        case ENUMS.SPECIAL_OFFER_STATUS.ended.colour:
          an = 2;
          break;
        default:
          an = 3;
      }
      switch (b.colour) {
        case ENUMS.SPECIAL_OFFER_STATUS.ongoing.colour:
          bn = 0;
          break;
        case ENUMS.SPECIAL_OFFER_STATUS.noStart.colour:
          bn = 1;
          break;
        case ENUMS.SPECIAL_OFFER_STATUS.ended.colour:
          bn = 2;
          break;
        default:
          bn = 3;
      }
      if (an == bn) {
        if (a.begin < b.begin) {
          return -1;
        }
        if (a.begin > b.begin) {
          return 1;
        }
      }

      return an - bn;
    },

    searchPageData() {
      console.log("searchPageData", this.dataMap);
      if (this.keyword || this.productCate) {
        let dataMap4View = CMN.deepCopy(this.dataMap);
        let keyword = this.keyword;
        let productCate = this.productCate;

        for (let e of Object.entries(dataMap4View)) {
          let vendor = e[0];
          let offerList = e[1];
          if (offerList) {
            if (productCate && productCate != "全部") {
              offerList = offerList.filter((offer) => {
                if (
                  offer &&
                  offer.productCate &&
                  offer.productCate == productCate
                ) {
                  return true;
                }
                return false;
              });
            }

            if (keyword) {
              let reg = RegExp(keyword.trim());
              offerList = offerList.filter((offer) => {
                for (let fieldValue of Object.values(offer)) {
                  if (fieldValue && reg.test(fieldValue)) {
                    return true;
                  }
                }
                return false;
              });
            }
            dataMap4View[vendor] = offerList;
          }
        }
        this.dataMap4View = dataMap4View;
      }
    },
  },
};
</script>

<style type="text/css">
.input {
  width: 100%;
  height: 100%;
}
.dlg {
  background: var(--v-bg-base);
  padding: 20px;
}
.top {
  justify-content: space-between;
  display: flex;
}
.tab-active {
  background: var(--v-primary-base);
  color: red;
}
</style>
