import Decimal from "decimal.js"
import * as _ from 'lodash'
// import 'reflect-metadata'
// import { JsonProperty, Serializable, serialize, SerializableEntity, deserialize } from 'ts-jackson';


export enum ProductCate {
    VM='主机', 
    DISK='硬盘',
    IP='公网IP',
    DB='数据库',
    DB_DISK='数据库硬盘',
    LB='负载均衡',
    LB_IP='负载均衡IP',
    OS='对象存储',
    OS_PACKAGE = '对象存储资源包',
    CDN='CDN',
    CDN_PACKAGE = 'CDN资源包',


    
    
}

export enum PricePayType{
    PrevPay='预付',
    PostPay='后付'
}
export enum PricePayTimeGranularity{
    Hour=1,
    Day=24,
    Week=24*7,
    Month=24*30,
    Year=24*360
}

export enum Vendor{
    Aliyun='阿里云',
    Tencent='腾讯云',
    Huawei='华为云',
    Baidu='百度云',
}
export const parseVendorFromKey=(key:string):Vendor=>{
    switch(key){
        case 'aliyun':return Vendor.Aliyun
        case 'tencent':return Vendor.Tencent
        case 'huawei':return Vendor.Huawei
        case 'baidu':return Vendor.Baidu
    }
    throw new Error('无效厂商key:'+key)
}
class A{
    
    b=1
}

export class PayMethod{
    
    payType:string=PricePayType.PrevPay
    
    granType:number=PricePayTimeGranularity.Year
    
    granCnt:number=1
    // 
    // a=new A()
    // 
    // b=new Map<string,A>([['a',new A()]])
    // 
    // c=new Array<A>(new A())
}

// let a=new PayMethod()
// a.granCnt=100
// let b=MapUtils.serialize(a)
// a.granCnt=200
// console.log('!!!!!!!!!!!!!!111',b)
// console.log('!!!!!!!!!!!!!!222',MapUtils.deserialize(PayMethod,b),a)
// @Serializable()
export class DimPriceInfo{
    
    dim='?未知计费维度?'
    
    payMethod!:PayMethod
    
    unitName!:string
    
    unitPrice!:Decimal
    
    unitCnt=1
    
    oriPrice!:Decimal
    
    finPrice!:Decimal
}

export class PriceInfo{
    
    dims:Array<DimPriceInfo>=[]
    
    oriPrice:Decimal=new Decimal(0)
    
    finPrice:Decimal=new Decimal(0)
}

export abstract class SpecProduct {
    name!:string;
    area!:string;
    region!:string;
    vendor!:Vendor;
    cate!:ProductCate;
    model!:string;
    tags!:Array<string>;
    specs!:Record<string, unknown>;
    payMethod!:PayMethod;
    price!:PriceInfo;
}

export abstract class SpecProduct_VmDisk extends SpecProduct {
    
    readonly cate:ProductCate=ProductCate.DISK

    size=40
}

export abstract class SpecProduct_Ip extends SpecProduct {
    readonly cate:ProductCate=ProductCate.IP

    /**带宽模式 */
    bandwidth=2
    /**流量模式 */
    flowSize=100
}

export abstract class SpecProduct_Vm extends SpecProduct {
    readonly cate:ProductCate=ProductCate.VM

    cpu:number=2;
    mem:number=4;
    windows:boolean=false
    sysDiskSize=40
    datDiskSize=0
    sysDiskTypes:Array<SpecProduct_VmDisk> =[]; //可选的磁盘
    datDiskTypes:Array<SpecProduct_VmDisk> =[]; //可选的磁盘
    selectedSysDiskType:number=0; //当前选择的磁盘下标
    selectedDatDiskType:number=0; //当前选择的磁盘下标
    bandwidth:number=5
    ipTypes:Array<SpecProduct_Ip>=[]
    selectedIpType=0
}
export enum DbEngine{
    MySQL='MySQL',
    MSSQL='MSSQL',
    PostgreSQL='PostgreSQL'
}


export enum DbArchType{
    Single='单机',
    HA='高可用',
    THREE_NODE='三节点版',
    COLONY='集群版'
    
}

export function getUiItems_DbArchType(){
    return [
        {'text': DbArchType.Single, 'value': '/006/001'},
        {'text': DbArchType.HA, 'value': '/006/002'}, 
        {'text': DbArchType.THREE_NODE, 'value': '/006/003'}, 
        {'text': DbArchType.COLONY, 'value': '/006/004'}, 
    ]
}


export enum DbSpecType{
    GENERAL='通用型',
    EXCLUSIVE='独享型',
    SHARE='共享型',
    ENHANCED='增强型'

}

export function getUiItems_DbSpecType(){
    return [
        {'text':  DbSpecType.GENERAL, 'value': '/005/001'}, 
        {'text':  DbSpecType.EXCLUSIVE, 'value': '/005/002'}, 
        {'text':  DbSpecType.SHARE, 'value': '/005/003'}, 
        {'text':  DbSpecType.ENHANCED, 'value': '/005/004'}, 
    ]
}


export abstract class SpecProduct_Db extends SpecProduct {
    readonly cate:ProductCate=ProductCate.DB

    engine!:DbEngine
    engineVer!:string
    archType!:DbArchType
    spec!:string
    specType!:string
    cpu:number=2;
    mem:number=4;
    storeSize=40
    diskTypes:Array<SpecProduct_DbDisk> =[]; //可选的磁盘
    selectedDiskType:number=0; //当前选择的磁盘下标   
}

export abstract class SpecProduct_DbDisk extends SpecProduct {
    readonly cate:ProductCate=ProductCate.DB_DISK
    engine!:string
    engineVer!:string
    archType!:string
    diskType!:string
    size=40
}

export enum LbType{
    SHARE='共享型',
    EXCLUSIVE='独享型',
    NET='网络型',
    APP='应用型'

}

export function getUiItems_LbType(){
    return [
        {'text': LbType.SHARE, 'value': '/008/001'},
        {'text': LbType.EXCLUSIVE, 'value': '/008/002'},
        {'text': LbType.NET, 'value': '/008/003'},
        {'text': LbType.APP, 'value': '/008/004'},
    ]
}

export enum OsType{
    STANDARD='标准存储型',
    INFREQUENT_ACCES='低频访问型',
    ARCHIVE='归档存储型',
    COLD_ARCHIVE='冷归档存储型',
    DEPTH_COLD_ARCHIVE='深度冷归档存储型'
}

export enum ZoneType{
    SINGLE='单AZ',
    MULTI='多AZ',
}


export function getUiItems_OsType(){
    return [
        {'text': OsType.STANDARD, 'value': '/009/001'},
        {'text': OsType.INFREQUENT_ACCES, 'value': '/009/002'},
        {'text': OsType.ARCHIVE, 'value': '/009/003'},
        {'text': OsType.COLD_ARCHIVE, 'value': '/009/004'},
        {'text': OsType.DEPTH_COLD_ARCHIVE, 'value': '/009/005'},
    ]
}

export function getUiItems_ZoneType(){
    return [
        {'text': ZoneType.SINGLE, 'value': '/010/001'},
        {'text': ZoneType.MULTI,'value': '/010/002'},
    ]
}

export abstract class SpecProduct_Lb extends SpecProduct {
    readonly cate:ProductCate=ProductCate.LB
    lbType!:DbEngine
    spec!:string
    specType!:string
    bandwidth:number=5
    ipTypes:Array<SpecProduct_Ip>=[]
    selectedIpType=0
}

export abstract class SpecProduct_Os extends SpecProduct {
    readonly cate:ProductCate=ProductCate.OS
    osType!:string
    subType!:string
    osStoreSize:number=40
    osFlowSize:number=40
    osStorePackages!:SpecProduct_OsPackage[]
    osFlowPackages!:SpecProduct_OsPackage[]
    osStoreTxt:string = ''
    osFlowTxt:string = ''
}

export abstract class SpecProduct_OsPackage extends SpecProduct {
    readonly cate:ProductCate=ProductCate.OS_PACKAGE
    resourceType!:string
    spec!:string
    duration!:string
}    

export abstract class SpecProduct_Cdn extends SpecProduct {
    readonly cate:ProductCate=ProductCate.CDN
    cdnFlowSize:number=40
    cdnFlowPackages!:SpecProduct_CdnPackage[]
    cdnFlowTxt:string = ''


}

export abstract class SpecProduct_CdnPackage extends SpecProduct {
    readonly cate:ProductCate=ProductCate.CDN_PACKAGE
    resourceType!:string
    spec!:string
    duration!:string
}   

export class Ecs extends SpecProduct_Vm {
    readonly name='ECS'
    readonly vendor=Vendor.Aliyun
}
export class Cvm extends SpecProduct_Vm {
    readonly name='CVM'
    readonly vendor=Vendor.Tencent
}
export class HuaweiEcs extends SpecProduct_Vm {
    readonly name='ECS'
    readonly vendor=Vendor.Huawei
}
export class Bcc extends SpecProduct_Vm {
    readonly name='BCC'
    readonly vendor=Vendor.Baidu
}

export class AliyunDisk extends SpecProduct_VmDisk {
    readonly name='云盘'
    readonly vendor=Vendor.Aliyun
}
export class TencentDisk extends SpecProduct_VmDisk {
    readonly name='云盘'
    readonly vendor=Vendor.Tencent
}
export class HuaweiDisk extends SpecProduct_VmDisk {
    readonly name='云盘'
    readonly vendor=Vendor.Huawei
}
export class BaiduDisk extends SpecProduct_VmDisk {
    readonly name='云盘'
    readonly vendor=Vendor.Baidu
}
export class AliyunEip extends SpecProduct_Ip {
    readonly name='EIP'
    readonly vendor=Vendor.Aliyun
}
export class TencentIp extends SpecProduct_Ip {
    readonly name='公网IP'
    readonly vendor=Vendor.Tencent
}
export class HuaweiIp extends SpecProduct_Ip {
    readonly name='公网IP'
    readonly vendor=Vendor.Huawei
}
export class BaiduIp extends SpecProduct_Ip {
    readonly name='公网IP'
    readonly vendor=Vendor.Baidu
}

export class AliyunDb extends SpecProduct_Db {
    readonly name='RDS'
    readonly vendor=Vendor.Aliyun
}
export class TencentDb extends SpecProduct_Db {
    readonly name='CDB'
    readonly vendor=Vendor.Tencent
}
export class HuaweiDb extends SpecProduct_Db {
    readonly name='RDS'
    readonly vendor=Vendor.Huawei
}
export class BaiduDb extends SpecProduct_Db {
    readonly name='RDS'
    readonly vendor=Vendor.Baidu
}
export class AliyunDbDisk extends SpecProduct_DbDisk {
    readonly name='云盘'
    readonly vendor=Vendor.Aliyun
}
export class TencentDbDisk extends SpecProduct_DbDisk {
    readonly name='云盘'
    readonly vendor=Vendor.Tencent
}
export class HuaweiDbDisk extends SpecProduct_DbDisk {
    readonly name='云盘'
    readonly vendor=Vendor.Huawei
}
export class BaiduDbDisk extends SpecProduct_DbDisk {
    readonly name='云盘'
    readonly vendor=Vendor.Baidu
}

export class AliyunLb extends SpecProduct_Lb {
    readonly name='SLB'
    readonly vendor=Vendor.Aliyun
}
export class TencentLb extends SpecProduct_Lb {
    readonly name='CLB'
    readonly vendor=Vendor.Tencent
    netType!:String

}
export class HuaweiLb extends SpecProduct_Lb {
    readonly name='ELB'
    readonly vendor=Vendor.Huawei
}
export class BaiduLb extends SpecProduct_Lb {
    readonly name='BLB'
    readonly vendor=Vendor.Baidu
}



export class AliyunOs extends SpecProduct_Os {
    readonly name='OSS'
    readonly vendor=Vendor.Aliyun
}
export class TencentOs extends SpecProduct_Os {
    readonly name='COS'
    readonly vendor=Vendor.Tencent
    netType!:String

}
export class HuaweiOs extends SpecProduct_Os {
    readonly name='OBS'
    readonly vendor=Vendor.Huawei
}
export class BaiduOs extends SpecProduct_Os {
    readonly name='BOS'
    readonly vendor=Vendor.Baidu
}

export class AliyunOsPackage extends SpecProduct_OsPackage {
    readonly name='对象存储资源包'
    readonly vendor=Vendor.Aliyun
}
export class TencentOsPackage extends SpecProduct_OsPackage {
    readonly name='对象存储资源包'
    readonly vendor=Vendor.Tencent

}
export class HuaweiOsPackage extends SpecProduct_OsPackage {
    readonly name='对象存储资源包'
    readonly vendor=Vendor.Huawei
}
export class BaiduOsPackage extends SpecProduct_OsPackage {
    readonly name='对象存储资源包'
    readonly vendor=Vendor.Baidu
}

export class AliyunCdn extends SpecProduct_Cdn {
    readonly name='CDN'
    readonly vendor=Vendor.Aliyun
}
export class TencentCdn extends SpecProduct_Cdn {
    readonly name='CDN'
    readonly vendor=Vendor.Tencent

}
export class HuaweiCdn extends SpecProduct_Cdn {
    readonly name='CDN'
    readonly vendor=Vendor.Huawei
}
export class BaiduCdn extends SpecProduct_Cdn {
    readonly name='CDN'
    readonly vendor=Vendor.Baidu
}

export class AliyunCdnPackage extends SpecProduct_CdnPackage {
    readonly name='CDN资源包'
    readonly vendor=Vendor.Aliyun
}
export class TencentCdnPackage extends SpecProduct_CdnPackage {
    readonly name='CDN资源包'
    readonly vendor=Vendor.Tencent

}
export class HuaweiCdnPackage extends SpecProduct_CdnPackage {
    readonly name='CDN资源包'
    readonly vendor=Vendor.Huawei
}
export class BaiduCdnPackage extends SpecProduct_CdnPackage {
    readonly name='CDN资源包'
    readonly vendor=Vendor.Baidu
}
