import Decimal from "decimal.js"
import {BaseParser} from './PriceInfoParser'
import { DimPriceInfo,HuaweiDb,HuaweiDbDisk, PayMethod, PriceInfo, PricePayTimeGranularity, PricePayType, ProductCate,DbArchType, SpecProduct, Vendor } from "./model"

import {PRODUCT_MANAGERS, ProductManager_Db} from './ProductManger'

export class Parser4HuaweiDb extends BaseParser{
    declare productManager:ProductManager_Db
    priceInfos:any={}
    specProducts:Array<HuaweiDb>=[]

    init(rawInfo:Array<any>): void {
        this.specProducts=[]
        rawInfo.forEach((item)=>{
            try{
                let product=this.parseFromJson(item)
                this.priceInfos[this.getPk(product)]=item
                this.specProducts.push(product)
            }catch(e){
                console.log('解析出错',e)
            }
        })
        
    }


    calPrice(specProduct: HuaweiDb, payMethod:PayMethod): PriceInfo {
        let rawPriceInfo=this.priceInfos[this.getPk(specProduct)]
        let dim=new DimPriceInfo()
        dim.payMethod=payMethod
        dim.dim='HuaweiDb:计算资源费用'
        if(payMethod.payType==PricePayType.PrevPay){
            if(rawPriceInfo.monthlyPrice == null || rawPriceInfo.monthlyPrice == undefined)
                return null
                
            let monthlyPrice=new Decimal(rawPriceInfo.monthlyPrice)
            dim.unitName='元/月'
            dim.unitPrice=monthlyPrice
            dim.unitCnt=payMethod.granType/(24*30)*payMethod.granCnt
            dim.oriPrice=dim.unitPrice.mul(dim.unitCnt)
                
            let yearlyPrices=[
                new Decimal(rawPriceInfo.yearlyPrice?rawPriceInfo.yearlyPrice:monthlyPrice.mul(12)),
                new Decimal(rawPriceInfo['2yearPrice']?rawPriceInfo['2yearPrice']:rawPriceInfo.yearlyPrice*2),
                new Decimal(rawPriceInfo['3yearPrice']?rawPriceInfo['3yearPrice']:rawPriceInfo.yearlyPrice*3),
            ]

           

            switch(payMethod.granType){
                /**  优惠规则
                     1年享受10个月
                    2年享受20个月
                    3年享受30个月
                */
                case PricePayTimeGranularity.Year:
                    if(payMethod.granCnt<=3)
                        dim.finPrice=yearlyPrices[payMethod.granCnt-1]
                    else//第3年开始 每年的价格为:3年价格-2年价格
                        dim.finPrice=yearlyPrices[2].add( yearlyPrices[2].minus(yearlyPrices[1]).mul(new Decimal(payMethod.granCnt-3)))
                    break
                case PricePayTimeGranularity.Month:
                    dim.finPrice=monthlyPrice.mul(payMethod.granCnt)
            }
        }else{
            if(rawPriceInfo.hourlyPrice == null || rawPriceInfo.hourlyPrice == undefined)
                return null
            dim.unitName='元/小时'
            dim.unitPrice=new Decimal(rawPriceInfo.hourlyPrice)
            dim.unitCnt=payMethod.granType*payMethod.granCnt
            dim.finPrice=dim.oriPrice=dim.unitPrice.mul(dim.unitCnt)

        }

        let priceInfo=new PriceInfo()
        priceInfo.dims.push(dim)
        
        if(specProduct.storeSize>0){
            let disk=specProduct.diskTypes[specProduct.selectedDiskType]
            disk.size=specProduct.storeSize
            let diskPriceInfo=PRODUCT_MANAGERS!.get(ProductCate.DB_DISK)!.calPrice(disk,payMethod)
            priceInfo.dims=priceInfo.dims.concat(diskPriceInfo.dims)
        }

   
        priceInfo.dims.forEach(dim=>{
            priceInfo.finPrice=priceInfo.finPrice.add(dim.finPrice)
            priceInfo.oriPrice=priceInfo.oriPrice.add(dim.oriPrice)
        })

        specProduct.price=priceInfo
        return priceInfo
    }

    parseFromJson(jsonO: any): HuaweiDb|null {
        
        let inst:HuaweiDb = new HuaweiDb()
        inst.region=jsonO.region
        inst.engine = jsonO.dbType
        inst.engineVer = jsonO.dbVersion
        inst.archType = jsonO.series
        inst.spec = jsonO.spec
        inst.specType=jsonO.specType
        inst.cpu=jsonO.cpu
        inst.mem=jsonO.memory
        inst.tags=jsonO.tags
        inst.storeSize=this.productManager.datDiskSize
        inst.model=jsonO.series+jsonO.spec+jsonO.specType


        //todo:判断是否有可使用的硬盘，没有不显示实例
        if(inst.storeSize>0){
            let filter: Map<string, any> = new Map([['region',inst.region],['engine',inst.engine],['archType',inst.archType]])
            inst.diskTypes = PRODUCT_MANAGERS.get(ProductCate.DB_DISK)?.filterProductsInVendor(inst.vendor,filter)
            if(!inst.diskTypes || inst.diskTypes.length<=0) return null//没可使用的硬盘 vm实例也不显示
            
            let types:string[] = []
            inst.diskTypes = inst.diskTypes.filter((disk)=>{
                if( types.indexOf(disk.model)==-1){
                    types.push(disk.model)
                    return true                    
                }
                return false
            })
            
            

        }

        return inst
    }
    
}


export class Parser4HuaweiDbDisk extends BaseParser{
    priceInfos:any={}
    specProducts:Array<HuaweiDbDisk>=[]

    calPrice(specProduct: HuaweiDbDisk, payMethod:PayMethod): PriceInfo {
        let rawPriceInfo=this.priceInfos[this.getPk(specProduct)]
        let dim=new DimPriceInfo()
        dim.payMethod=payMethod
        dim.dim='云盘:存储费用'

        if(payMethod.payType==PricePayType.PrevPay){
            if(rawPriceInfo.monthlyPrice == null || rawPriceInfo.monthlyPrice == undefined)
                return null
            let monthlyPrice=new Decimal(rawPriceInfo.monthlyPrice)

            dim.unitName='元/(GB*月)'
            dim.unitPrice=new Decimal(rawPriceInfo.monthlyPrice)
            dim.unitCnt=specProduct.size*(payMethod.granType/(24*30)*payMethod.granCnt)
            dim.oriPrice=dim.unitPrice.mul(dim.unitCnt)

            let yearlyPrices=[
                new Decimal(rawPriceInfo.yearlyPrice?rawPriceInfo.yearlyPrice:monthlyPrice.mul(12)),
                new Decimal(rawPriceInfo['2yearPrice']?rawPriceInfo['2yearPrice']:rawPriceInfo.yearlyPrice*2),
                new Decimal(rawPriceInfo['3yearPrice']?rawPriceInfo['3yearPrice']:rawPriceInfo.yearlyPrice*3),
                new Decimal(rawPriceInfo['4yearPrice']?rawPriceInfo['4yearPrice']:rawPriceInfo.yearlyPrice*4),
                new Decimal(rawPriceInfo['5yearPrice']?rawPriceInfo['5yearPrice']:rawPriceInfo.yearlyPrice*5),
            ]

            switch(payMethod.granType){
          
                case PricePayTimeGranularity.Year:
                    if(payMethod.granCnt<=5)
                        dim.finPrice=yearlyPrices[payMethod.granCnt-1].mul(specProduct.size)
                    else//第3年开始 每年的价格为:3年价格-2年价格
                        dim.finPrice=yearlyPrices[4].add( yearlyPrices[4].minus(yearlyPrices[3]).mul(new Decimal(payMethod.granCnt-5)))
                    break
                case PricePayTimeGranularity.Month:
                    dim.finPrice=monthlyPrice.mul(payMethod.granCnt)
            }

        }else{
            if(rawPriceInfo.hourlyPrice == null || rawPriceInfo.hourlyPrice == undefined)
                return null
            dim.unitName='元/(GB*小时)'
            dim.unitPrice=new Decimal(rawPriceInfo.hourlyPrice)
            dim.unitCnt=specProduct.size*(payMethod.granType*payMethod.granCnt)
            dim.finPrice=dim.oriPrice=dim.unitPrice.mul(dim.unitCnt)
        }

        let priceInfo=new PriceInfo()
        priceInfo.dims=[dim]
        priceInfo.finPrice=dim.finPrice
        priceInfo.oriPrice=dim.oriPrice

        specProduct.price=priceInfo
        return priceInfo
    }
    parseFromJson(jsonO: any): HuaweiDbDisk {
        let inst:HuaweiDbDisk=new HuaweiDbDisk()
        inst.tags=jsonO.tags
        inst.diskType=jsonO.diskType
        inst.engine=jsonO.dbType
        inst.engineVer=jsonO.dbVersion
        inst.archType=jsonO.series
        inst.region=jsonO.region
        inst.model=inst.engine+inst.archType+inst.diskType

        return inst
    }
    
}

