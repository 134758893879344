import $ from "jquery";

let request = function(url, reqJson = null, hdrs = null,baseUrl=config.BASE_URL,returnRsp=false) {
  return new Promise((resolve, reject) => {
    $.ajax(baseUrl + url, {
      type: reqJson == null ? "GET" : "POST",
      dataType: "json",
      timeout: 5000,
      async: true,
      data: reqJson == null ? undefined : JSON.stringify(reqJson),
      cache: false,
      headers: { "Content-Type": "application/json", ...hdrs },
      // xhrFields: {
      //   withCredentials: true, // 这里设置了withCredentials
      // },
      success: function(rspJson) {
        
        if (rspJson.code == 1000) {
          window.location.href = rspJson.data.url;
        }
        if(returnRsp){
          resolve(rspJson);
        }else{
          resolve(rspJson.data);

        }
       
      },
      error: function(e) {
        reject(e);
      },
    });
  });
};

let getVm = (vCpu, mem, tags) => {
  return request(
    "/api/vm?" + "c=" + vCpu + "&m=" + mem + "&tags=" + encodeURIComponent(JSON.stringify(tags))
  );
};

let getIp = (tags) => {
  return request("/api/ip?tags=" + encodeURIComponent(JSON.stringify(tags)));
};

let getDisk = (tags) => {
  return request("/api/disk?tags=" + encodeURIComponent(JSON.stringify(tags)));
};

let getSpecialOffers = (endSTs) => {
  return request("/api/specialOffer?" + "endSTs=" + endSTs);
};

let saveClient = (clientInfo) => {
  return request("/api/saveClient", clientInfo);
};

let getDb = (vCpu, mem, engine, engineVer, tags) => {
  /**
     
    let date = {
        'aliyun':[
        { 
        "id":41030,
        "region":"华北2（北京）",
        "zone":"华北2可用区J",
        "dbType":"PostgreSQL",
        "dbVersion":"11",
        "series":"高可用版",
        "diskType":"ESSDPL2云盘",
        "spec":"pg.x2.medium.2c",
        "specType":"独享套餐",
        "cpu":2,
        "memory":4,
        "connectNum":"400",
        "iops":"N/A",
        "ioBandwidth":"",
        "hourlyPrice":1.1450000000,
        "monthlyPrice":550.0000000000,
        "tags":["/001/001/001/003", "/004/003", "/007/017", "/006/002", "/005/002"],
        "createTs":"2021-09-01 18:32:07",
        "updateTs":"2021-10-08 00:19:18"
        },
        { 
        "id":41050,
        "region":"华北2（北京）",
        "zone":"华北2可用区J",
        "dbType":"PostgreSQL",
        "dbVersion":"11",
        "series":"高可用版",
        "diskType":"ESSDPL2云盘",
        "spec":"pg.n2.medium.2c",
        "specType":"通用型",
        "cpu":2,
        "memory":4,
        "connectNum":"400",
        "iops":"N/A",
        "ioBandwidth":"",
        "hourlyPrice":1.0410000000,
        "monthlyPrice":500.0000000000,
        "tags":["/001/001/001/003", "/004/003", "/007/017", "/006/002", "/005/001"],
        "createTs":"2021-09-01 18:32:07",
        "updateTs":"2021-10-08 00:19:17"
        }] }
    return date
     */
  if (!tags) {
    tags = [];
  }
  engineVer = engineVer.replace("R2", ""); //2008R2版本,去除R2字段，方便后台查询

  return request(
    "/api/db?" +
      "c=" +
      vCpu +
      "&m=" +
      mem +
      "&dbType=" +
      engine +
      "&dbVersion=" +
      engineVer +
      "&tags=" +
      JSON.stringify(tags)
  );
};

let getDbDisk = (tags) => {
  return request("/api/dbDisk?tags=" + encodeURIComponent(JSON.stringify(tags)) );
};

let getLb = (tags, bandwidth) => {
  let hasIp = false;
  if (bandwidth > 0) {
    hasIp = true;
  }
  return request("/api/lb?tags=" +  encodeURIComponent(JSON.stringify(tags))+ "&hasIp=" + hasIp);
};

let getLbIp = (tags) => {
  return request("/api/lbIp?tags=" +  encodeURIComponent(JSON.stringify(tags)));
};

let getOs = (tags) => {
  return request("/api/os?tags=" + encodeURIComponent(JSON.stringify(tags)));
};

let getOsPackage = (tags) => {
  return request("/api/osPackage?tags=" + encodeURIComponent(JSON.stringify(tags)));
};

let getCdn = (tags) => {
  return request("/api/cdn?tags=" + encodeURIComponent(JSON.stringify(tags)));
};

let getCdnPackage = (tags) => {
  return request("/api/cdnPackage?tags=" + encodeURIComponent(JSON.stringify(tags)));
};

let sendPhoneVerityCode = (phone) => {
  return request(`/api/sendVerifyCode?phone=${phone}&platform=${window.config.platform}`,null,null,window.config.COMM_SEVICE_URL,true);
};

let genWeChatQrcodeUrl = (phone,authCode=null) => {
  let req = {
    "platform": window.config.platform,
    "client": "",
    "phone": phone,
    "authCode": authCode
  }
  return request(`/api/cloudy/genQrcodeUrl`,req,null,window.config.COMM_SEVICE_URL,true);
};

let isFollowed = (phone) => {
  return request(`/api/isFollowed?phone=${phone}`,null,null,window.config.COMM_SEVICE_URL,true);
};



export default {
  getVm,
  getIp,
  getDisk,
  getSpecialOffers,
  saveClient,
  getDb,
  getDbDisk,
  getLb,
  getLbIp,
  getOs,
  getOsPackage,
  getCdn,
  getCdnPackage,
  sendPhoneVerityCode,
  genWeChatQrcodeUrl,
  isFollowed,
};
