import Decimal from "decimal.js"
import {BaseParser} from './PriceInfoParser'
import { DimPriceInfo,BaiduDb,BaiduDbDisk, PayMethod, PriceInfo, PricePayTimeGranularity, PricePayType, DbArchType, SpecProduct, Vendor,ProductCate } from "./model"

import {PRODUCT_MANAGERS, ProductManager_Db} from './ProductManger'

export class Parser4BaiduDb extends BaseParser{
    declare productManager:ProductManager_Db
    priceInfos:any={}
    specProducts:Array<BaiduDb>=[]

    init(rawInfo:Array<any>): void {
        this.specProducts=[]
        rawInfo.forEach((item)=>{
            try{
                let product=this.parseFromJson(item)
                this.priceInfos[this.getPk(product)]=item
                this.specProducts.push(product)
            }catch(e){
                console.log('解析出错',e)
            }
        })
        
    }

    calPrice(specProduct: BaiduDb, payMethod:PayMethod): PriceInfo {
        let rawPriceInfo=this.priceInfos[this.getPk(specProduct)]

        let dim=new DimPriceInfo()
        dim.payMethod=payMethod
        dim.dim='BaiduDb:计算资源费用'
        if(payMethod.payType==PricePayType.PrevPay){
            if(rawPriceInfo.monthlyPrice == null || rawPriceInfo.monthlyPrice == undefined){
                return null
            }
            let monthlyPrice=new Decimal(rawPriceInfo.monthlyPrice)
            dim.unitName='元/月'
            dim.unitPrice=monthlyPrice
            dim.unitCnt=payMethod.granType/(24*30)*payMethod.granCnt
            dim.oriPrice=dim.unitPrice.mul(dim.unitCnt)
            
            let discount=1
            switch(payMethod.granType){
                /**  优惠规则
                       1年83折，2年7折，3年5折
                */
                case PricePayTimeGranularity.Year:
                    if(payMethod.granCnt==1)
                        discount = 0.83
                    else if(payMethod.granCnt==2)
                        discount = 0.7
                    else if(payMethod.granCnt>=3)
                        discount = 0.5
                    break
            }
            dim.finPrice=dim.oriPrice.mul(discount)
        }else{
            if(rawPriceInfo.minutelyPrice == null || rawPriceInfo.minutelyPrice == undefined)
                return null
            dim.unitName='元/小时'
            dim.unitPrice=new Decimal(rawPriceInfo.minutelyPrice).mul(60)
            dim.unitCnt=payMethod.granType*payMethod.granCnt
            dim.finPrice=dim.oriPrice=dim.unitPrice.mul(dim.unitCnt)
        }

        let priceInfo=new PriceInfo()
        priceInfo.dims.push(dim)
        

        if(specProduct.storeSize>0){
            let disk=specProduct.diskTypes[specProduct.selectedDiskType]
            disk.size=specProduct.storeSize
            let diskPriceInfo=PRODUCT_MANAGERS!.get(ProductCate.DB_DISK)!.calPrice(disk,payMethod)
            priceInfo.dims=priceInfo.dims.concat(diskPriceInfo.dims)
        }

   
        priceInfo.dims.forEach(dim=>{
            priceInfo.finPrice=priceInfo.finPrice.add(dim.finPrice)
            priceInfo.oriPrice=priceInfo.oriPrice.add(dim.oriPrice)
        })

        specProduct.price=priceInfo
        return priceInfo
    }

    parseFromJson(jsonO: any): BaiduDb|null {
       
        let inst:BaiduDb = new BaiduDb()
        inst.region=jsonO.region
        inst.engine = jsonO.dbType
        inst.engineVer = jsonO.dbVersion
        inst.archType = jsonO.series
        inst.spec=jsonO.spec
        inst.specType=jsonO.specType
        inst.cpu=jsonO.cpu
        inst.mem=jsonO.memory
        inst.tags=jsonO.tags
        inst.storeSize=this.productManager.datDiskSize
        inst.model=jsonO.series+jsonO.spec+jsonO.specType

        //todo:判断是否有可使用的硬盘，没有不显示实例
        if(inst.storeSize>0){
            let filter: Map<string, any> = new Map([['region',inst.region],['engine',inst.engine],['archType',inst.archType]])
            inst.diskTypes = PRODUCT_MANAGERS.get(ProductCate.DB_DISK)?.filterProductsInVendor(inst.vendor,filter)
            if(!inst.diskTypes || inst.diskTypes.length<=0) return null//没可使用的硬盘 vm实例也不显示
        }

        return inst
    }
    
}


export class Parser4BaiduDbDisk extends BaseParser{
    priceInfos:any={}
    specProducts:Array<BaiduDbDisk>=[]


    calPrice(specProduct: BaiduDbDisk, payMethod:PayMethod): PriceInfo {
        let rawPriceInfo=this.priceInfos[this.getPk(specProduct)]
        let dim=new DimPriceInfo()
        dim.payMethod=payMethod
        dim.dim='云盘:存储费用'
        if(payMethod.payType==PricePayType.PrevPay){
            if(rawPriceInfo.monthlyPrice == null || rawPriceInfo.monthlyPrice == undefined){
                return null
            }
            let monthlyPrice=new Decimal(rawPriceInfo.monthlyPrice)
            dim.unitName='元/月'
            dim.unitPrice=monthlyPrice
            dim.unitCnt=payMethod.granType/(24*30)*payMethod.granCnt
            dim.oriPrice=dim.unitPrice.mul(dim.unitCnt)
            
            let discount=1
            switch(payMethod.granType){
                /**  优惠规则
                       1年83折，2年7折，3年5折
                */
                case PricePayTimeGranularity.Year:
                    if(payMethod.granCnt==1)
                        discount = 0.83
                    else if(payMethod.granCnt==2)
                        discount = 0.7
                    else if(payMethod.granCnt>=3)
                        discount = 0.5
                    break
            }
            dim.finPrice=dim.oriPrice.mul(discount)
        }else{
            if(rawPriceInfo.minutelyPrice == null || rawPriceInfo.minutelyPrice == undefined)
                return null

            dim.unitName='元/小时'
            dim.unitPrice=new Decimal(rawPriceInfo.minutelyPrice).mul(60)
            dim.unitCnt=payMethod.granType*payMethod.granCnt
            dim.finPrice=dim.oriPrice=dim.unitPrice.mul(dim.unitCnt)
        }

        let priceInfo=new PriceInfo()
        priceInfo.dims=[dim]
        priceInfo.finPrice=dim.finPrice
        priceInfo.oriPrice=dim.oriPrice

        specProduct.price=priceInfo
        return priceInfo
    }
    parseFromJson(jsonO: any): BaiduDbDisk {
        let inst:BaiduDbDisk=new BaiduDbDisk()
        inst.tags=jsonO.tags
        inst.diskType=jsonO.diskType
        inst.engine=jsonO.dbType
        inst.engineVer=jsonO.dbVersion
        inst.archType=jsonO.series
        inst.region=jsonO.region
        inst.model=inst.engine+inst.archType+inst.diskType
        return inst
    }
    
}
