import Decimal from "decimal.js"
import {BaseParser, BaseParser4Cdn} from './PriceInfoParser'
import { DimPriceInfo,AliyunCdn,PayMethod, PriceInfo, PricePayTimeGranularity, PricePayType,ProductCate, AliyunCdnPackage, SpecProduct_CdnPackage, SpecProduct_Cdn} from "./model"

import {PRODUCT_MANAGERS, ProductManager_Cdn, ProductManager_CdnPackage} from './ProductManger'


export class Parser4AliyunCdn extends BaseParser4Cdn{
    declare productManager:ProductManager_Cdn
    priceInfos:any={}
    specProducts:Array<AliyunCdn>=[]

    init(rawInfo:Array<any>): void {
        this.specProducts=[]
        rawInfo.forEach((item)=>{
            try{
                let product=this.parseFromJson(item)
                if(!product) return
                this.priceInfos[this.getPk(product)]=item
                this.specProducts.push(product)
            }catch(e){
                console.log('解析出错',e)
            }
        })
        
    }

    calPrice(specProduct: AliyunCdn, payMethod:PayMethod): PriceInfo {
        let rawPriceInfo=this.priceInfos[this.getPk(specProduct)]
        let priceInfo=new PriceInfo() 
        if(payMethod.payType==PricePayType.PrevPay){

            if(specProduct.cdnFlowPackages.length == 0 || this.productManager.cdnFlowSize<=0 ){
                    return null
            }
            //计算流量包
            priceInfo = this.computePackage(specProduct.cdnFlowSize, payMethod,specProduct.cdnFlowPackages)
            if(!priceInfo) return null
        
            specProduct.cdnFlowTxt = ''
            priceInfo.dims.forEach(dim=>{
                specProduct.cdnFlowTxt += `【${dim.dim}】x ${dim.unitCnt}\n`
            }) 
           
        }else{
            let dim = new DimPriceInfo()
            dim.payMethod = payMethod
            //计算流量
            dim.dim='AliyunCdn:流量费用'
                
            if(rawPriceInfo.priceLte10TB == null){
                return null
            }else{
                dim.unitPrice = new Decimal(0)
                let tempSize = specProduct.cdnFlowSize

                let priceLevels = [
                    [1*1024*1024,rawPriceInfo.priceGt1PB],
                    [100*1024,rawPriceInfo.price100TBTo1PB],
                    [50*1024,rawPriceInfo.price50TBTo100TB],
                    [10*1024,rawPriceInfo.price10TBTo50TB],
                    [0,rawPriceInfo.priceLte10TB],
                ]

                for(let priceLevel of priceLevels){
                    let size = priceLevel[0]
                    let price = priceLevel[1]
                    if(tempSize > size && price){
                        dim.unitPrice = dim.unitPrice.add(new Decimal(price).mul(tempSize-size))
                        tempSize = size
                    }
                }
            }
 
            dim.unitName = rawPriceInfo.priceUnit+'/月'
            dim.unitCnt=payMethod.granType/PricePayTimeGranularity.Month*payMethod.granCnt
            dim.finPrice=dim.oriPrice=dim.unitPrice.mul(dim.unitCnt)
            priceInfo.dims.push(dim)

        }

        priceInfo.dims.forEach(dim=>{
            priceInfo.finPrice=priceInfo.finPrice.add(dim.finPrice)
            priceInfo.oriPrice=priceInfo.oriPrice.add(dim.oriPrice)
        })

        specProduct.price=priceInfo
        return priceInfo
    }

    parseFromJson(jsonO: any): AliyunCdn|null {
        let inst:AliyunCdn = new AliyunCdn()
        inst.region=jsonO.region
        inst.tags=jsonO.tags
        inst.model='-'
        inst.area=this.productManager.area
        inst.cdnFlowSize=this.productManager.cdnFlowSize
        inst.cdnFlowTxt=this.productManager.cdnFlowSize+''

        let regionTag = null
        try {
            regionTag = inst.tags.filter(tag=> tag.startsWith('/001/001/001'))[0]
        } catch (error) {
            console.log(error);
        }
    
        if(inst.cdnFlowSize>0){

            inst.cdnFlowPackages = PRODUCT_MANAGERS.get(ProductCate.CDN_PACKAGE)?.filterProductsInVendor(inst.vendor,new Map([['regionTag',regionTag]]))
            if(!inst.cdnFlowPackages) return null//没可使用的资源包，不显示实例
        }

        return inst
    }
    
}


export class Parser4AliyunCdnPackage extends BaseParser{
    declare productManager:ProductManager_CdnPackage
    priceInfos:any={}
    specProducts:Array<AliyunCdnPackage>=[]

    init(rawInfo:Array<any>): void {
        this.specProducts=[]
        rawInfo.forEach((item)=>{
            try{
                let product=this.parseFromJson(item)
                if(!product) return
                this.priceInfos[this.getPk(product)]=item
                this.specProducts.push(product)
            }catch(e){
                console.log('解析出错',e)
            }
        })
        
    }

    getPk(specProduct:SpecProduct):string{
        return specProduct.region+specProduct.model+specProduct.spec + specProduct.duration
    }

    calPrice(specProduct: AliyunCdnPackage, payMethod:PayMethod): PriceInfo {
        let rawPriceInfo=this.priceInfos[this.getPk(specProduct)]
        let priceInfo=new PriceInfo()        
        let dim=new DimPriceInfo()
        dim.payMethod=payMethod
        dim.unitPrice=dim.finPrice=dim.oriPrice = new Decimal(rawPriceInfo.price)
        dim.unitCnt = 1
        dim.unitName = '元'
        priceInfo.dims.push(dim)

        priceInfo.dims.forEach(dim=>{
            priceInfo.finPrice=priceInfo.finPrice.add(dim.finPrice)
            priceInfo.oriPrice=priceInfo.oriPrice.add(dim.oriPrice)
        })

        specProduct.price=priceInfo
        return priceInfo
    }

    parseFromJson(jsonO: any): AliyunCdnPackage|null {
        let inst:AliyunCdnPackage = new AliyunCdnPackage()
        inst.region=jsonO.region
        inst.resourceType = jsonO.resourceType
        inst.spec = jsonO.spec
        inst.duration = jsonO.duration
        inst.tags=jsonO.tags
        inst.model=inst.resourceType
        inst.area=this.productManager.area

        return inst
    }
    
}

