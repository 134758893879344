


























































































































































































































































































































































































































































import Vue from "vue";
import { Component, Prop, Watch } from "vue-property-decorator";

import * as IvcVue from "../cmn/IvcVue";
import ENUMS from "../cmn/js/enums";
import CMN from "../cmn/js/common";
import REQUESTS from "@/cmn/js/requests";


@Component({ components: {} })
export default class ProductConfTabs extends Vue {
  // @Prop(String) selectedTab=null

  enums: any = ENUMS;
  
  @Prop({
    type: String,
    default: "华南",
  })
  area!: String;
  @Prop({
    type: String,
    default: "数据库",
  })

  productCate!: String;

  selectedArea: String = "华南";
  selectedCate: String = "数据库";

  cateParams: Record<string, any> = {
    主机: {
      cpu: 2,
      mem: 4,
      sysDiskSize: 40,
      datDiskSize: 0,
      bandwidth: 5,
    },
    数据库: {
      cpu: 2,
      mem: 8,
      datDiskSize: 100,
      engine: "MySQL",
      engineVer: "5.7",
    },
    负载均衡: {
      bandwidth: 5,
    },
    对象存储: {
      osStoreSize: 5120,
      osFlowSize: 1024,
    },
    CDN: {
      cdnFlowSize: 100,
    },
  };


  focusStatus: Record<string, any> = {
    sysDiskSize: false,
    datDiskSize: false,
    bandwidth: false,
  };


  phone:String = null;
  phone4AskFollow:String = null; 
  authCode:String = null;
  valid = true;
  requireRules = [(value) => !!value || "本项必填"];
  requireRules4AuthCode = [(value) => !!value || "本项必填", (value) => (!!value && value.length<=4)|| "长度不能大于4"];
  showDialog = false;
  qrcodeUrl = null
  showQrcodeDialog = false
  isFollowIntervalId = null



  created() {
    // alert(this.selectedCate)
    this.cateParams = CMN.getLocalstore(
      IvcVue.LocalstoreKey.CONF_LIST,
      -1,
      this.cateParams
    );
  }
  mounted() {
    // this.show(0)
    this.selectedArea = this.area;
    this.selectedCate = this.productCate;
  }

  get isMobileMode() {
    return CMN.judgeIsMobileMode(this);
  }

  show(cateName: string) {
    
    if( !CMN.isCanCheckInfo() ){
      let phone = CMN.getLocalstore('phone')
      if(phone){
        this.phone = phone
        this.authCode = 'noNeed'
        this.genWeChatQrcodeUrl()
        
      }else{
        this.showDialog = true
      }
    }else{
        console.log("selected", this.cateParams[cateName], this.selectedArea);
        this.$emit(
          "selected",
          cateName,
          CMN.deepCopy(this.cateParams[cateName]),
          this.selectedArea
        );
        CMN.setLocalstore(IvcVue.LocalstoreKey.CONF_LIST, this.cateParams);
    }

   
  }


  getCurrProductCoreConf() {
    return this.cateParams[this.selectedCate];
  }

  getEngineVers(engine: string) {
    let versions = null;
    for (let t of ENUMS.DB_ENGINE) {
      if (t.value == engine) {
        versions = t.versions;
        break;
      }
    }
    return versions;
  }
  changeEngine(cateParam: any) {
    // (itmes)=>{cateParam.engineVer = items[0].value }
    cateParam.engineVer = this.getEngineVers(cateParam.engine)![0].value;
  }
  close() {
    this.$emit("close");
  }

  submit() {
    if (!this.$refs.form.validate()) return;
    this.genWeChatQrcodeUrl()
  }

  async sendPhoneVerityCode(){
    let rsp = await REQUESTS.sendPhoneVerityCode(this.phone)
    if(rsp.code == 0){
      this.$ivcApp.showNotify('验证码发送成功',IvcVue.NotifyLevel.INFO);
    }else{
      this.$ivcApp.showNotify(rsp.msg, IvcVue.NotifyLevel.ERROR);
    }
  }

  async genWeChatQrcodeUrl(){
    let rsp = await REQUESTS.genWeChatQrcodeUrl(this.phone,this.authCode)
    if(rsp.code == 0){
      this.qrcodeUrl = rsp.data.qrcodeUrl
      if(this.qrcodeUrl){
          this.showQrcodeDialog = true
          this.showDialog = false
          this.phone4AskFollow = this.phone
          CMN.setLocalstore('phone',this.phone)
          if(this.$refs.form){
            this.$refs.form.reset()
          }
          this.startAskWechatFollowInterval()
      }else{
        this.$ivcApp.showNotify("获取的二维码出错，请重新尝试或联系我们！",IvcVue.NotifyLevel.ERROR);
      }
    
    }else{
        this.$ivcApp.showNotify(rsp.msg,IvcVue.NotifyLevel.ERROR);
    }
  }

  async isFollowed(){
    let rsp = await REQUESTS.isFollowed(this.phone4AskFollow)
    if(rsp.code == 0){
      let isFollowed = rsp.data.isFollowed
      if(isFollowed){
        CMN.setLocalstore('isFollowed',isFollowed)
        clearInterval(this.isFollowIntervalId)
      } 
    }
  }

  startAskWechatFollowInterval(){
    if(! this.isFollowIntervalId ){
       this.isFollowIntervalId = setInterval(this.isFollowed, 2000)
    }
   
  }



}
