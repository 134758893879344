













































































































































































































































































































import Vue from "vue";
import * as IvcVue from "../cmn/IvcVue";
import { Component, Prop } from "vue-property-decorator";
import CMN from "../cmn/js/common";
import TAGS from "../cmn/js/tags";
import { head } from "lodash";
import ENUMS from "@/cmn/js/enums";
import chunk from "lodash/chunk";

import {
  PayMethod,
  PricePayTimeGranularity,
  PricePayType,
  ProductCate,
  SpecProduct,
  Vendor,
} from "@/cmn/model";
import Decimal from "decimal.js";

class CartItem {
  product!: SpecProduct;
  cnt = 1;
  selected = true;
}

@Component({ components: {} })
export default class Cart extends Vue {
  @Prop({
    type: Number,
    default: function(): Number {
      return 0;
    },
  })
  value!: Number;

  PricePayType = PricePayType;
  TAGS = TAGS;

  cartItemsByCate: Record<ProductCate, CartItem[]> = {
    主机: [],
    硬盘: [],
    公网IP: [],
    数据库: [],
    负载均衡: [],
    对象存储: [],
    CDN: [],
  };

  tabs = ["主机", "数据库", "负载均衡", "对象存储", "CDN"];
  tab = 0;

  chunk = chunk;

  created() {
    //拿上次的记录
    this.loadCartItems();
  }

  initTab() {
    let count = 0;
    for (let i = 0; i < this.tabs.length; i++) {
      let cate = this.tabs[i];
      console.log(cate);
      if (this.cartItemsByCate[<ProductCate>cate].length > 0) {
        this.tab = count;
        break;
      }
      count++;
    }
  }

  headers(productCate: ProductCate): any[] {
    let ret = [
      { title: "厂商", k: "vendor" },
      { title: "地区", k: "area" },
      { title: "产品名", k: "name" },
      { title: "型号", k: "model" },
    ];

    switch (productCate) {
      case ProductCate.VM:
        ret = ret.concat([
          { title: "vCPU", k: "cpu" },
          { title: "内存", k: "mem" },
          { title: "系统盘", k: "sysDisk" },
          { title: "数据盘", k: "datDisk" },
          { title: "公网IP", k: "ip" },
        ]);
        break;
      case ProductCate.DB:
        ret = ret.concat([
          { title: "引擎", k: "engine" },
          { title: "版本", k: "engineVer" },
          { title: "架构", k: "archType" },
          { title: "规格", k: "specType" },
          { title: "vCPU", k: "cpu" },
          { title: "内存", k: "mem" },
          { title: "数据盘", k: "dbDisk" },
        ]);
        break;

      case ProductCate.LB:
        ret = ret.concat([
          { title: "类型", k: "lbType" },
          { title: "规格", k: "specType" },
          { title: "公网IP", k: "ip" },
        ]);
        break;
      case ProductCate.OS:
        ret = ret.concat([
          { title: "子类型", k: "subType" },
          { title: "存储大小", k: "osStoreSize" },
          { title: "流量大小", k: "osFlowSize" },
        ]);

        break;
      case ProductCate.CDN:
        ret = ret.concat([{ title: "流量大小", k: "cdnFlowTxt" }]);

        break;
    }

    ret = ret.concat([
      { title: "购买模式", k: "payMethod" },
      { title: "单价", k: "price" },
      { title: "数量", k: "cnt" },
      { title: "总价", k: "totalPrice" },
    ]);

    return ret;
  }

  //computed
  get cnt() {
    let cnt = 0;
    for (let cate in this.cartItemsByCate) {
      let items = this.cartItemsByCate[<ProductCate>cate];
      items.forEach((item) => {
        cnt += item.cnt;
      });
    }
    return cnt;
  }
  get selectedCnt() {
    let cnt = 0;
    for (let cate in this.cartItemsByCate) {
      let items = this.cartItemsByCate[<ProductCate>cate];
      items.forEach((item) => {
        if (item.selected) cnt += item.cnt;
      });
    }
    return cnt;
  }

  get totalAmt() {
    let amt = new Decimal(0);
    for (let cate in this.cartItemsByCate) {
      let items = this.cartItemsByCate[<ProductCate>cate];
      items.forEach((item) => {
        if (item.selected)
          amt = amt.add(item.product.price.finPrice.mul(item.cnt));
      });
    }
    this.saveCartItems();
    this.$emit("input", this.cnt);
    return amt.toNumber().toFixed(2);
  }

  get rowCntToShow() {
    return Math.round(window.innerWidth / 350) > 0
      ? Math.round(window.innerWidth / 350)
      : 1;
  }

  // remove(index:number){
  //   // this.cartItems.splice(index,1)
  //   // this.$emit("input", this.cnt)

  //   // this.saveCartItems()
  // }
  add(product: SpecProduct) {
    let newCartItem = new CartItem();
    newCartItem.product = product;
    console.log("new-cart-item", newCartItem);
    this.cartItemsByCate[product.cate].push(newCartItem);
    // this.$emit("input", this.cnt)
    // this.initCollects4Table()
    // this.saveCartItems()
  }
  saveCartItems() {
    CMN.setLocalstore(IvcVue.LocalstoreKey.CART_LIST, this.cartItemsByCate);
  }
  loadCartItems() {
    let cartItemsByCate: Record<ProductCate, CartItem[]> = CMN.getLocalstore(
      IvcVue.LocalstoreKey.CART_LIST,
      -1,
      {
        主机: [],
        硬盘: [],
        公网IP: [],
        数据库: [],
        负载均衡: [],
        对象存储: [],
        CDN: [],
      }
    );

    for (let cate in cartItemsByCate) {
      cartItemsByCate[<ProductCate>cate].forEach((cartItem) => {
        cartItem.product.price.oriPrice = new Decimal(
          cartItem.product.price.oriPrice
        );
        cartItem.product.price.finPrice = new Decimal(
          cartItem.product.price.finPrice
        );
        cartItem.product.price.dims.forEach((dimPrice) => {
          dimPrice.unitPrice = new Decimal(dimPrice.unitPrice);
          dimPrice.oriPrice = new Decimal(dimPrice.oriPrice);
          dimPrice.finPrice = new Decimal(dimPrice.finPrice);
        });
      });
    }
    this.cartItemsByCate = cartItemsByCate;
  }

  payTimeGranToText(payTimeGran: PricePayTimeGranularity) {
    switch (payTimeGran) {
      case PricePayTimeGranularity.Hour:
        return "小时";
      case PricePayTimeGranularity.Day:
        return "天";
      case PricePayTimeGranularity.Week:
        return "周";
      case PricePayTimeGranularity.Month:
        return "月";
      case PricePayTimeGranularity.Year:
        return "年";
      default:
        return "?????";
    }
  }
  generateDspByTag(product: SpecProduct, tags: any) {
    let str = "";
    console.log(product, tags);
    if (product.tags) {
      product.tags.forEach((o) => {
        let tag = CMN.findItemInEnums(tags, o);
        if (tag) {
          if (!str) {
            str = tag.text;
          } else {
            str = str + "," + tag.text;
          }
        }
      });
    }
    return product.model + "(" + str + ")";
  }
}
