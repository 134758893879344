import Decimal from "decimal.js"
import {BaseParser} from './PriceInfoParser'
import {DimPriceInfo,TencentLb,TencentIp,PayMethod, PriceInfo, PricePayTimeGranularity, PricePayType,ProductCate} from "./model"

import {PRODUCT_MANAGERS, ProductManager_Lb} from './ProductManger'

export class Parser4TencentLb extends BaseParser{
    declare productManager:ProductManager_Lb
    priceInfos:any={}
    specProducts:Array<TencentLb>=[]

    init(rawInfo:Array<any>): void {
        this.specProducts=[]
        rawInfo.forEach((item)=>{
            try{
                let product=this.parseFromJson(item)
                this.priceInfos[this.getPk(product)]=item
                this.specProducts.push(product)
            }catch(e){
                console.log('解析出错',e)
            }
        })
        
    }

    calPrice(specProduct: TencentLb, payMethod:PayMethod): PriceInfo {
        
        let rawPriceInfo=this.priceInfos[this.getPk(specProduct)]

        let dim=new DimPriceInfo()
        dim.payMethod=payMethod
        dim.dim='TencentLb:计算资源费用'
        if(payMethod.payType==PricePayType.PrevPay){

            //内网的只有后付方式
            if(rawPriceInfo.instMonthlyPrice == null || rawPriceInfo.instMonthlyPrice == undefined)
                return null

            let monthlyPrice=new Decimal(rawPriceInfo.instMonthlyPrice)
            dim.unitName='元/月'
            dim.unitPrice=monthlyPrice
            dim.unitCnt=payMethod.granType/(24*30)*payMethod.granCnt
            dim.oriPrice=dim.unitPrice.mul(dim.unitCnt)

            let discount=1
            if(payMethod.granType==PricePayTimeGranularity.Year){
                // 年付83折
                discount = 0.83
            }
            dim.finPrice=dim.oriPrice.mul(discount)
            

        }else{

            if(rawPriceInfo.instHourlyPrice == null || rawPriceInfo.instHourlyPrice == undefined)
                return null
            
            dim.unitName='元/小时'
            dim.unitPrice=new Decimal(rawPriceInfo.instHourlyPrice)
            dim.unitCnt=payMethod.granType*payMethod.granCnt
            let discount=1
            if(dim.unitCnt >=6){
                // 半年8.8折
                discount = 8.8
            }
            dim.finPrice=dim.oriPrice=dim.unitPrice.mul(dim.unitCnt).mul(discount)
        }

        let priceInfo=new PriceInfo()
        priceInfo.dims.push(dim)

        //EIP维度
        if(specProduct.bandwidth>0){
            let eip=specProduct.ipTypes[specProduct.selectedIpType]
            eip.bandwidth=specProduct.bandwidth
            let eipPriceInfo=PRODUCT_MANAGERS!.get(ProductCate.LB_IP)!.calPrice(eip,payMethod)
            priceInfo.dims=priceInfo.dims.concat(eipPriceInfo.dims)
        }

        priceInfo.dims.forEach(dim=>{
            priceInfo.finPrice=priceInfo.finPrice.add(dim.finPrice)
            priceInfo.oriPrice=priceInfo.oriPrice.add(dim.oriPrice)
        })

        specProduct.price=priceInfo
        return priceInfo
    }

    parseFromJson(jsonO: any): TencentLb|null {
        let inst:TencentLb = new TencentLb()
        inst.region=jsonO.region
        inst.lbType = jsonO.instType
        inst.spec = '-'
        inst.specType=jsonO.specType
        inst.netType=jsonO.netType
        inst.tags=jsonO.tags
        inst.model=inst.lbType+inst.netType 
        inst.area=this.productManager.area
        inst.bandwidth=this.productManager.bandwidth  
        
        let regionTag = null
        try {
            inst.tags.forEach(tag => {
                if(tag.startsWith('/001')){
                    regionTag = tag
                    return
                }
            });
        } catch (error) {
            console.error(error);
        }
        
        if(inst.bandwidth>0){
            inst.ipTypes=PRODUCT_MANAGERS.get(ProductCate.LB_IP)?.filterProductsInVendor(inst.vendor,new Map([['regionTag',regionTag]]))
            if(!inst.ipTypes || inst.ipTypes.length<=0) return null//没可使用的公网 lb也不显示
        }
        return inst
    }
    
}


export class Parser4TencentLbIp extends BaseParser{
    priceInfos:any={}
    specProducts:Array<TencentIp>=[]

    calPrice(specProduct: TencentIp, payMethod:PayMethod): PriceInfo {
        let rawPriceInfo=this.priceInfos[this.getPk(specProduct)]

        let dim=new DimPriceInfo()
        dim.payMethod=payMethod
        if(payMethod.payType==PricePayType.PrevPay){
            dim.dim='公网IP:带宽费用'
            let monthlyPriceOfBandwidth = [
                new Decimal(rawPriceInfo["1MonthEq1M"]),
                new Decimal(rawPriceInfo["1MonthEq2M"]),
                new Decimal(rawPriceInfo["1MonthEq3M"]),
                new Decimal(rawPriceInfo["1MonthEq4M"]),
                new Decimal(rawPriceInfo["1MonthEq5M"]),
                new Decimal(rawPriceInfo["1MonthGte6M"]),
            ]

            dim.unitName=`元/(${specProduct.bandwidth}Mb*月)`
            dim.unitCnt=payMethod.granType/(24*30)*payMethod.granCnt
        

            if (specProduct.bandwidth <=5 ){
                
                dim.unitPrice=monthlyPriceOfBandwidth[specProduct.bandwidth-1]
            }else {
                // >=6m,5m的价格加上（bandwidth-5m）* (>=6m的单价)
                dim.unitPrice=monthlyPriceOfBandwidth[4].add(monthlyPriceOfBandwidth[5].mul(specProduct.bandwidth-5))
            }
            dim.oriPrice=dim.unitPrice.mul(dim.unitCnt)

            let discount=1
            if(payMethod.granType==PricePayTimeGranularity.Year){
                discount = 0.83
            }else if(payMethod.granType==PricePayTimeGranularity.Month && payMethod.granCnt>5){
                discount = 0.88
            }

            dim.finPrice=dim.oriPrice.mul(discount)
        }else{
            dim.dim='公网IP:流量费用'
            dim.unitName='元/GB'
            dim.unitPrice=new Decimal(rawPriceInfo['1Gb'])
            dim.unitCnt=0//TODO 目前只显示单价 specProduct.flowSize
            dim.finPrice=dim.oriPrice=dim.unitPrice.mul(dim.unitCnt)
        }

        let priceInfo=new PriceInfo()
        priceInfo.dims=[dim]
        priceInfo.finPrice=dim.finPrice
        priceInfo.oriPrice=dim.oriPrice

        specProduct.price=priceInfo
        return priceInfo
    }

    parseFromJson(jsonO: any): TencentIp {
        let inst=new TencentIp()
        inst.tags=jsonO.tags
        inst.model='多线BGP'
        inst.region=jsonO.region
        return inst
    }
    
}

