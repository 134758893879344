import Decimal from "decimal.js"
import {BaseParser, BaseParser4Cdn} from './PriceInfoParser'
import { DimPriceInfo,TencentCdn,PayMethod, PriceInfo, PricePayTimeGranularity, PricePayType,ProductCate, TencentCdnPackage, SpecProduct_CdnPackage, SpecProduct_Cdn} from "./model"

import {PRODUCT_MANAGERS, ProductManager_Cdn, ProductManager_CdnPackage} from './ProductManger'


export class Parser4TencentCdn extends BaseParser4Cdn{
    declare productManager:ProductManager_Cdn
    priceInfos:any={}
    specProducts:Array<TencentCdn>=[]

    init(rawInfo:Array<any>): void {
        this.specProducts=[]
        rawInfo.forEach((item)=>{
            try{
                let product=this.parseFromJson(item)
                if(!product) return
                this.priceInfos[this.getPk(product)]=item
                this.specProducts.push(product)
            }catch(e){
                console.log('解析出错',e)
            }
        })
        
    }

    calPrice(specProduct: TencentCdn, payMethod:PayMethod): PriceInfo {
        let rawPriceInfo=this.priceInfos[this.getPk(specProduct)]
        let priceInfo=new PriceInfo() 
        if(payMethod.payType==PricePayType.PrevPay){

            if(specProduct.cdnFlowPackages.length == 0 || this.productManager.cdnFlowSize<=0 ){
                    return null
            }
            //计算流量包
            if(payMethod.payType==PricePayType.PrevPay){

                if(specProduct.cdnFlowPackages.length == 0 || this.productManager.cdnFlowSize<=0 ){
                        return null
                }
                //计算流量包
                priceInfo = this.computePackage(specProduct.cdnFlowSize, payMethod,specProduct.cdnFlowPackages)
                if(!priceInfo) return null
            
                specProduct.cdnFlowTxt = ''
                priceInfo.dims.forEach(dim=>{
                    specProduct.cdnFlowTxt += `【${dim.dim}】x ${dim.unitCnt}\n`
                }) 
            }

           
        }else{
            let dim = new DimPriceInfo()
            dim.payMethod = payMethod
            //计算流量
            dim.dim='TencentCdn:流量费用'
                
            if(rawPriceInfo.priceLte2TB == null){
                return null
            }else{
                dim.unitPrice = new Decimal(0)
                let tempSize = specProduct.cdnFlowSize

                let priceLevels = [
                    [100*1024,rawPriceInfo.priceGt100TB],
                    [50*1024,rawPriceInfo.price50TBTo100TB],
                    [10*1024,rawPriceInfo.price10TBTo50TB],
                    [2*1024,rawPriceInfo.price2TBTo10TB],
                    [0,rawPriceInfo.priceLte2TB],
                ]

                for(let priceLevel of priceLevels){
                    let size = priceLevel[0]
                    let price = priceLevel[1]
                    if(tempSize > size && price){
                        dim.unitPrice = dim.unitPrice.add(new Decimal(price).mul(tempSize-size))
                        tempSize = size
                    }
                }
            }

            dim.unitName = rawPriceInfo.priceUnit+'/月'
            dim.unitCnt=payMethod.granType/PricePayTimeGranularity.Month*payMethod.granCnt
            dim.finPrice=dim.oriPrice=dim.unitPrice.mul(dim.unitCnt)
            priceInfo.dims.push(dim)

        }

        priceInfo.dims.forEach(dim=>{
            priceInfo.finPrice=priceInfo.finPrice.add(dim.finPrice)
            priceInfo.oriPrice=priceInfo.oriPrice.add(dim.oriPrice)
        })

        specProduct.price=priceInfo
        return priceInfo
    }

   
    parseFromJson(jsonO: any): TencentCdn|null {
        let inst:TencentCdn = new TencentCdn()
        inst.region=jsonO.region
        inst.tags=jsonO.tags
        inst.model='-'
        inst.area=this.productManager.area
        inst.cdnFlowSize=this.productManager.cdnFlowSize 
        inst.cdnFlowTxt=this.productManager.cdnFlowSize+''


        let regionTags = null
        try {
            regionTags = inst.tags.filter(tag=> tag.startsWith('/001/001/001'))
        } catch (error) {
            console.log(error);
        }
    
        if(inst.cdnFlowSize>0){
            inst.cdnFlowPackages = PRODUCT_MANAGERS.get(ProductCate.CDN_PACKAGE)?.filterProductsInVendor(inst.vendor,new Map([['regionTags',regionTags]]))
            if(!inst.cdnFlowPackages) return null//没可使用的资源包，不显示实例
        }

        return inst
    }
    
}


export class Parser4TencentCdnPackage extends BaseParser{
    declare productManager:ProductManager_CdnPackage
    priceInfos:any={}
    specProducts:Array<TencentCdnPackage>=[]

    init(rawInfo:Array<any>): void {
        this.specProducts=[]
        rawInfo.forEach((item)=>{
            try{
                let product=this.parseFromJson(item)
                if(!product) return
                this.priceInfos[this.getPk(product)]=item
                this.specProducts.push(product)
            }catch(e){
                console.log('解析出错',e)
            }
        })
        
    }

    getPk(specProduct:SpecProduct):string{
        return specProduct.region+specProduct.model+specProduct.spec + specProduct.duration
    }

    calPrice(specProduct: TencentCdnPackage, payMethod:PayMethod): PriceInfo {
        let rawPriceInfo=this.priceInfos[this.getPk(specProduct)]
        let priceInfo=new PriceInfo()        
        let dim=new DimPriceInfo()
        dim.payMethod=payMethod
        dim.unitPrice=dim.finPrice=dim.oriPrice = new Decimal(rawPriceInfo.price)
        dim.unitCnt = 1
        dim.unitName = '元'
        priceInfo.dims.push(dim)

        priceInfo.dims.forEach(dim=>{
            priceInfo.finPrice=priceInfo.finPrice.add(dim.finPrice)
            priceInfo.oriPrice=priceInfo.oriPrice.add(dim.oriPrice)
        })

        specProduct.price=priceInfo
        return priceInfo
    }

    parseFromJson(jsonO: any): TencentCdnPackage|null {
        let inst:TencentCdnPackage = new TencentCdnPackage()
        inst.region=jsonO.region
        inst.resourceType = jsonO.resourceType
        inst.spec = jsonO.spec
        inst.duration = jsonO.duration
        inst.tags=jsonO.tags
        inst.model=inst.resourceType
        inst.area=this.productManager.area

        return inst
    }
    
}

