import Decimal from "decimal.js"
import {BaseParser, BaseParser4Os} from './PriceInfoParser'
import { DimPriceInfo,TencentOs,PayMethod, PriceInfo, PricePayTimeGranularity, PricePayType,ProductCate, TencentOsPackage, SpecProduct_OsPackage, SpecProduct} from "./model"

import {PRODUCT_MANAGERS, ProductManager_Os, ProductManager_OsPackage} from './ProductManger'
import CMN from "../cmn/js/common";


export class Parser4TencentOs extends BaseParser4Os{
    declare productManager:ProductManager_Os
    priceInfos:any={}
    specProducts:Array<TencentOs>=[]

    init(rawInfo:Array<any>): void {
        this.specProducts=[]
        rawInfo.forEach((item)=>{
            try{
                let product=this.parseFromJson(item)
                if(!product) return
                this.priceInfos[this.getPk(product)]=item
                this.specProducts.push(product)
            }catch(e){
                console.log('解析出错',e)
            }
        })
        
    }

    getPk(specProduct:SpecProduct):string{
       
        return specProduct.region+specProduct.model+specProduct.subType
    }

    getOsStoreSize_GB(obj:SpecProduct_OsPackage){
        let size = 0
        try {

            if(obj.spec.indexOf('T')>-1){
                size = parseInt( obj.spec.replace('T/每月','') ) * 1024
            }else if(obj.spec.indexOf('P')>-1){
                size = parseInt( obj.spec.replace('P/每月','') ) * 1024 *1024
            }
            else{
                size = parseInt( obj.spec.replace('G/每月','') )
            }
        } catch (error) {
            console.error('资源包存储类型转换失败：'+error);
        }
        
        return size
    }

    
    calPrice(specProduct: TencentOs, payMethod:PayMethod): PriceInfo {
        let rawPriceInfo=this.priceInfos[this.getPk(specProduct)]

        let priceInfo=new PriceInfo()        
    
        if(payMethod.payType==PricePayType.PrevPay){
            if(specProduct.osStorePackages.length == 0 || specProduct.osFlowPackages.length == 0
                || this.productManager.osStoreSize<=0 || this.productManager.osFlowSize<=0 ){
                    return null
                }
            //计算存储包
            let priceInfoTemp = this.computePackage(specProduct.osStoreSize,payMethod,specProduct.osStorePackages)
            if(!priceInfoTemp) return null
            priceInfo.dims = priceInfoTemp.dims    

            specProduct.osStoreTxt = ''
            priceInfoTemp.dims.forEach(dim=>{
                specProduct.osStoreTxt += `【${dim.dim.replace('AliyunOs:','')}】x ${dim.unitCnt}\n`
            }) 

            //计算流量包
            priceInfoTemp = this.computePackage(specProduct.osFlowSize, payMethod,specProduct.osFlowPackages)
            if(!priceInfoTemp) return null
            priceInfo.dims.concat(priceInfoTemp.dims)         
            

            specProduct.osFlowTxt = ''
            priceInfoTemp.dims.forEach(dim=>{
                specProduct.osFlowTxt += `【${dim.dim.replace('AliyunOs:','')}】x ${dim.unitCnt}\n`
            })    

           
        }else{
            let dim=new DimPriceInfo()
            dim.payMethod=payMethod
            dim.dim='TencentOs:存储费用'

            if(rawPriceInfo.price == null){
              return null
            }
            dim.unitPrice = new Decimal(rawPriceInfo.price).mul(specProduct.osStoreSize)
            dim.unitName=rawPriceInfo.priceUnit
            dim.unitCnt=payMethod.granType/PricePayTimeGranularity.Month*payMethod.granCnt
            dim.finPrice=dim.oriPrice=dim.unitPrice.mul(dim.unitCnt)
            priceInfo.dims.push(dim)

            //计算流量
            if(specProduct.osFlowSize>0){
                let dim=new DimPriceInfo()
                dim.payMethod=payMethod
                dim.dim='TencentOs:流量费用'
                if(rawPriceInfo.priceFlow == null){
                    return null
                }
                
                dim.unitPrice = new Decimal(rawPriceInfo.priceFlow).mul(specProduct.osFlowSize)
                dim.unitName = rawPriceInfo.priceUnitFlow
                dim.unitCnt=payMethod.granType/PricePayTimeGranularity.Month*payMethod.granCnt
                dim.finPrice=dim.oriPrice=dim.unitPrice.mul(dim.unitCnt)
                priceInfo.dims.push(dim)
                   
            }
        }
        
        priceInfo.dims.forEach(dim=>{
            priceInfo.finPrice=priceInfo.finPrice.add(dim.finPrice)
            priceInfo.oriPrice=priceInfo.oriPrice.add(dim.oriPrice)
        })

        specProduct.price=priceInfo
        return priceInfo
    }

    parseFromJson(jsonO: any): TencentOs|null {
        let inst:TencentOs = new TencentOs()
        inst.region=jsonO.region
        inst.osType = jsonO.storageType
        inst.subType = jsonO.billingSubItem == ''?'单AZ':jsonO.billingSubItem
        inst.tags=jsonO.tags
        inst.model=inst.osType
        inst.area=this.productManager.area
        inst.osStoreSize=this.productManager.osStoreSize
        inst.osFlowSize=this.productManager.osFlowSize 
        
        if(inst.osStoreSize>0){
            inst.osStorePackages = PRODUCT_MANAGERS.get(ProductCate.OS_PACKAGE)?.filterProductsInVendor(inst.vendor,new Map())
            let resourceType = ''
            if(inst.osType.indexOf('标准')>-1){
                resourceType = '标准存储容量包'
            }else if(inst.osType.indexOf('低频')>-1){
                resourceType = '低频存储容量包'
            }else if(inst.osType == '归档存储'){
                resourceType = '归档存储容量包'
            }else if(inst.osType == '深度归档存储'){
                resourceType = '深度归档存储容量包'
            }
            
            inst.osStorePackages = inst.osStorePackages.filter(p => p.resourceType == resourceType && p.region == inst.region )
            if(!inst.osStorePackages) return null//没可使用的资源包，不显示实例

        }
        
        if(inst.osFlowSize>0){
            let resourceType = '外网下行流量包'
            inst.osFlowPackages = PRODUCT_MANAGERS.get(ProductCate.OS_PACKAGE)?.filterProductsInVendor(inst.vendor,new Map([['resourceType',resourceType]]))
            
            inst.osFlowPackages = inst.osFlowPackages.filter(p => p.resourceType == resourceType && p.region == inst.region )
            if(!inst.osStorePackages) return null//没可使用的资源包，不显示实例
        }
        return inst
    }
    
}


export class Parser4TencentOsPackage extends BaseParser{
    declare productManager:ProductManager_OsPackage
    priceInfos:any={}
    specProducts:Array<TencentOsPackage>=[]

    init(rawInfo:Array<any>): void {
        this.specProducts=[]
        rawInfo.forEach((item)=>{
            try{
                let product=this.parseFromJson(item)
                this.priceInfos[this.getPk(product)]=item
                this.specProducts.push(product)
            }catch(e){
                console.log('解析出错',e)
            }
        })
        
    }

    getPk(specProduct:SpecProduct):string{
        return specProduct.region+specProduct.model+specProduct.spec + specProduct.duration
    }

    calPrice(specProduct: TencentOsPackage, payMethod:PayMethod): PriceInfo {
        let rawPriceInfo=this.priceInfos[this.getPk(specProduct)]
        let priceInfo=new PriceInfo()        
        let dim=new DimPriceInfo()
        dim.payMethod=payMethod
        dim.unitPrice=dim.finPrice=dim.oriPrice = new Decimal(rawPriceInfo.price)
        dim.unitCnt = 1
        dim.unitName = '元'
        priceInfo.dims.push(dim)

        priceInfo.dims.forEach(dim=>{
            priceInfo.finPrice=priceInfo.finPrice.add(dim.finPrice)
            priceInfo.oriPrice=priceInfo.oriPrice.add(dim.oriPrice)
        })

        specProduct.price=priceInfo
        return priceInfo
    }

    parseFromJson(jsonO: any): TencentOsPackage|null {
        let inst:TencentOsPackage = new TencentOsPackage()
        inst.region=jsonO.region
        inst.resourceType = jsonO.resourceType
        inst.spec = jsonO.spec
        inst.duration = jsonO.duration
        inst.tags=jsonO.tags
        inst.model=inst.resourceType
        inst.area=this.productManager.area

        return inst
    }
    
}


