import Decimal from "decimal.js"
import { DimPriceInfo, PayMethod, PriceInfo, PricePayTimeGranularity, ProductCate, SpecProduct, SpecProduct_Cdn, SpecProduct_CdnPackage, SpecProduct_Os, SpecProduct_OsPackage} from "./model"
import {IProductManager, PRODUCT_MANAGERS} from './ProductManger'
import CMN from "../cmn/js/common";

/**
 * 解释后端的价格信息, 提取商品列表 提供计价方法
 */
export interface IProductPriceParser{
    /**
     * owner
     */
    productManager:IProductManager
    /**
     * 用后台响应的数据初始化
     * @param rawInfo 后台响应的商品&价格数据
     */
    init(rawInfo:any):void;
    /**
     * 对指定的具体商品计价
     * @param specProduct 计价的具体商品
     * @param payMethod 付费方式
     */
    calPrice(specProduct:SpecProduct, payMethod:PayMethod):PriceInfo;
    /**
     * 获取可选的商品列表
     */
    getSpecProducts():Array<SpecProduct>;
}


export abstract class BaseParser implements IProductPriceParser{
    productManager:IProductManager
    /**
     * 价格信息by PK
     */
    priceInfos:any={}
    /**
     * 可选商品列表
     */
    specProducts:Array<SpecProduct>=[]

    constructor(productManager:IProductManager){
        this.productManager=productManager
    }

    /**
     * 提取商品的pk
     * @param specProduct 
     * @returns 
     */
    getPk(specProduct:SpecProduct):string{
        return specProduct.region+specProduct.model
    }
    

    init(rawInfo:Array<any>): void {
        this.specProducts=[]
        rawInfo.forEach((item)=>{
            try{
                let product=this.parseFromJson(item)
                if(!product) return
                this.priceInfos[this.getPk(product)]=item
                this.specProducts.push(product)
            }catch(e){
                console.log('解析出错',e)
            }
        })
        
    }
    getSpecProducts(): SpecProduct[] {
        return this.specProducts
    }
    abstract calPrice(specProduct: SpecProduct, payMethod: PayMethod): PriceInfo
    /**
     * 从后台原始数据中解析出商品
     * @param jsonO 
     */
    abstract parseFromJson(jsonO: any): SpecProduct|null
}


export abstract class BaseParser4Os extends BaseParser{
    
    getOsStoreSize_GB(obj:SpecProduct_OsPackage){
        let size = 0
        try {
            if(obj.spec.indexOf('TB')>-1){
                size = parseInt( obj.spec.replace('TB','') ) * 1024
            }else if(obj.spec.indexOf('PB')>-1){
                size = parseInt( obj.spec.replace('PB','') ) * 1024 *1024
            }
            else{
                size = parseInt( obj.spec.replace('GB','') )
            }
        } catch (error) {
            console.error('资源包存储类型转换失败：'+error);
        }
        
        return size
    }

    getMonthDuration(obj:SpecProduct_OsPackage){
        let month = 0
        try {
            if(obj.duration.indexOf('月')>-1){
                month = parseInt(obj.duration.replace('个月',''))
            }
            if(obj.duration.indexOf('年')>-1){
                month = parseInt(obj.duration.replace('年',''))*12
            }
        } catch (error) {
            console.error('解析month错误');
            
        }
        return month
       
    }


    // findPackageByDuration(packages:SpecProduct_OsPackage[],payMethod:PayMethod){
    //     if (!packages || packages.length == 0) return null

        
    //     let matchPackage = null

    //     //查询时长匹配的包
    //     if(payMethod.granType == PricePayTimeGranularity.Year){
    //         let isMin = false
    //         let tempPackages =  packages.filter(p=> this.getMonthDuration(p) == payMethod.granCnt * 12 )
    //         if( !tempPackages || tempPackages.length==0){
    //             tempPackages = packages.filter( p=>p.duration.indexOf('年')>-1)
    //             tempPackages.sort((a:any,b:any)=>{
    //                     let d1 =  this.getMonthDuration(a)
    //                     let d2 =  this.getMonthDuration(b)
    //                     return d1 - d2
    //             })
    //             isMin = true
    //         }
    //         matchPackage = CMN.deepCopy( tempPackages[0] )
    //         matchPackage.isMin = isMin

    //     }else if(payMethod.granType == PricePayTimeGranularity.Month){
    //         let isMin = false
    //         let tempPackages =  packages.filter(p=> this.getMonthDuration(p) == payMethod.granCnt )
    //         if( !tempPackages || tempPackages.length==0){
    //             tempPackages = packages.filter( p=>p.duration.indexOf('月')>-1)
    //             tempPackages.sort((a:any,b:any)=>{
    //                     let d1 =  this.getMonthDuration(a)
    //                     let d2 =  this.getMonthDuration(b)
    //                     return d1 - d2
    //             })
    //             isMin = true
    //         }
    //         matchPackage = CMN.deepCopy( tempPackages[0] )
    //         matchPackage.isMin = isMin
    //     }
    //     return matchPackage
    // }

    findPackageByDuration(packages:SpecProduct_CdnPackage[],payMethod:PayMethod){
        if (!packages || packages.length == 0) return null

        let matchPackage = null

        //查询时长匹配的包
        if(payMethod.granType == PricePayTimeGranularity.Year){
            let isMin = false
            let tempPackages =  packages.filter(p=> this.getMonthDuration(p) == payMethod.granCnt * 12 )
            if( !tempPackages || tempPackages.length==0){
                tempPackages = packages.filter( p=>p.duration.indexOf('年')>-1)
                tempPackages.sort((a:any,b:any)=>{
                        let d1 =  this.getMonthDuration(a)
                        let d2 =  this.getMonthDuration(b)
                        return d1 - d2
                })
                isMin = true
            }
            if(tempPackages && tempPackages.length>0){
                matchPackage = CMN.deepCopy( tempPackages[0] )
                matchPackage.isMin = isMin
            }else{
                //没有年包则返回最小的包
                matchPackage = CMN.deepCopy(packages[packages.length-1])
                matchPackage.isMin = isMin
            }
        }
        
        if(payMethod.granType == PricePayTimeGranularity.Month){
            let isMin = false
            let tempPackages =  packages.filter(p=> this.getMonthDuration(p) == payMethod.granCnt )
            if( !tempPackages || tempPackages.length==0){
                tempPackages = packages.filter( p=>p.duration.indexOf('月')>-1)
                tempPackages.sort((a:any,b:any)=>{
                        let d1 =  this.getMonthDuration(a)
                        let d2 =  this.getMonthDuration(b)
                        return d1 - d2
                })
                isMin = true
            }
            if(tempPackages && tempPackages.length>0){
                matchPackage = CMN.deepCopy( tempPackages[0] )
                matchPackage.isMin = isMin
            }
            
        }

        if( matchPackage == null ){
            //没有包则返回所有包中最小的包
            matchPackage = CMN.deepCopy(packages[packages.length-1])
            matchPackage.isMin = true
        }
        return matchPackage
    }



    findPackages(size:number, payMethod:PayMethod,packages:SpecProduct_OsPackage[]){
        if (!packages || packages.length == 0) return null

        packages.sort((a:any,b:any)=>{
            let d1 =  this.getOsStoreSize_GB(a)
            let d2 =  this.getOsStoreSize_GB(b)
            return d2-d1
        })

        let matchInfo:any = {}
        let computeSize = 0

        packages.forEach(p=>{
            let pSize:number = this.getOsStoreSize_GB(p)
            while(true){
                if(computeSize+pSize<=size){
                    computeSize+=pSize
                    if(!matchInfo[p.spec]){
                        //根据时间维度获取包
                        matchInfo[p.spec] = 1
                    }else{
                        matchInfo[p.spec]+=1
                    }
                }else{
                    break
                }
            } 
        })

        let finalPackages:any[] = []

        Object.keys(matchInfo).forEach(spec=>{
            let specPackages = packages.filter(p=>p.spec == spec)
            let matchPackage =  this.findPackageByDuration(specPackages,payMethod)
            matchPackage.cnt = matchInfo[spec]
            if(matchPackage){
                finalPackages.push(matchPackage)
            }
        })

        return finalPackages
    }

    computePackage(size:number,payMethod:PayMethod,packages:SpecProduct_OsPackage[]){

        let finalPackages = this.findPackages(size,payMethod,packages)
        if (!finalPackages || finalPackages.length == 0) return null

        let priceInfo=new PriceInfo() 
       

        //计算资源包价格和显示文本
        finalPackages.forEach(p=>{
            let dim = new DimPriceInfo()
            let price = PRODUCT_MANAGERS.get(ProductCate.OS_PACKAGE)?.calPrice(p,payMethod).oriPrice

            dim.payMethod = payMethod
            if(p.isMin){   //获取的是最小时间段的包
                let monthDuration = this.getMonthDuration(p)
                if(payMethod.granType == PricePayTimeGranularity.Year){
                    if(monthDuration == 12){
                        dim.dim=`${ p.spec}-${ p.region}-${ p.resourceType}-${p.duration}`
                        dim.unitName = '元/'+p.duration
                        dim.unitCnt = p.cnt*payMethod.granCnt
                        dim.unitPrice = price
                    }else if(monthDuration>12){
                        let duration = '1年'
                        dim.dim=`${ p.spec}-${ p.region}-${ p.resourceType}-${duration}-虚构`
                        dim.unitName = '元/'+duration
                        dim.unitCnt =  p.cnt*payMethod.granCnt
                        dim.unitPrice = price?.div(monthDuration/12)
                    }else{
                        let duration = '1个月'
                        dim.dim=`${ p.spec}-${ p.region}-${ p.resourceType}-${duration}-虚构`
                        dim.unitName = '元/'+duration
                        dim.unitCnt =  p.cnt*payMethod.granCnt * 12
                        dim.unitPrice = price?.div(monthDuration)
                    }
                }else{
                    if(monthDuration == 1){
                        dim.dim=`${ p.spec}-${ p.region}-${ p.resourceType}-${p.duration}`
                        dim.unitName = '元/'+p.duration
                        dim.unitCnt = p.cnt*payMethod.granCnt
                        dim.unitPrice = price
                    }else{
                        let duration = '1月'
                        dim.dim=`${ p.spec}-${ p.region}-${ p.resourceType}-${duration}-虚构`
                        dim.unitName = '元/'+duration
                        dim.unitCnt =  p.cnt*payMethod.granCnt
                        dim.unitPrice = price?.div(monthDuration)
                    }
                }

            }else{  //时间段完全匹配的包
                dim.dim=`${ p.spec}-${ p.region}-${ p.resourceType}-${p.duration}`
                dim.unitName = '元/'+p.duration
                dim.unitCnt =  p.cnt*1
                dim.unitPrice = price
            }
            dim.oriPrice = dim.finPrice =  dim.unitPrice.mul(dim.unitCnt)
            priceInfo.dims.push(dim)
            
        })
        
        return priceInfo
    }
}


export abstract class BaseParser4Cdn extends BaseParser{
    
    getStoreOrFlowSize_GB(obj:SpecProduct_CdnPackage){
        let size = 0
        try {
            if(obj.spec.indexOf('TB')>-1){
                size = parseInt( obj.spec.replace('TB','') ) * 1024
            }else if(obj.spec.indexOf('PB')>-1){
                size = parseInt( obj.spec.replace('PB','') ) * 1024 *1024
            }
            else{
                size = parseInt( obj.spec.replace('GB','') )
            }
        } catch (error) {
            console.error('资源包存储类型转换失败：'+error);
        }
        
        return size
    }

    getMonthDuration(obj:SpecProduct_CdnPackage){
        let month = 0
        try {
            if(obj.duration.indexOf('月')>-1){
                month = parseInt(obj.duration.replace('个月',''))
            }
            if(obj.duration.indexOf('年')>-1){
                month = parseInt(obj.duration.replace('年',''))*12
            }
        } catch (error) {
            console.error('解析month错误');
            
        }
        return month
       
    }


    findPackageByDuration(packages:SpecProduct_CdnPackage[],payMethod:PayMethod){
        if (!packages || packages.length == 0) return null

        let matchPackage = null

        //查询时长匹配的包
        if(payMethod.granType == PricePayTimeGranularity.Year){
            let isMin = false
            let tempPackages =  packages.filter(p=> this.getMonthDuration(p) == payMethod.granCnt * 12 )
            if( !tempPackages || tempPackages.length==0){
                tempPackages = packages.filter( p=>p.duration.indexOf('年')>-1)
                tempPackages.sort((a:any,b:any)=>{
                        let d1 =  this.getMonthDuration(a)
                        let d2 =  this.getMonthDuration(b)
                        return d1 - d2
                })
                isMin = true
            }
            if(tempPackages && tempPackages.length>0){
                matchPackage = CMN.deepCopy( tempPackages[0] )
                matchPackage.isMin = isMin
            }else{
                //没有年包则返回最小的包
                matchPackage = CMN.deepCopy(packages[packages.length-1])
                matchPackage.isMin = isMin
            }
        }
        
        if(payMethod.granType == PricePayTimeGranularity.Month){
            let isMin = false
            let tempPackages =  packages.filter(p=> this.getMonthDuration(p) == payMethod.granCnt )
            if( !tempPackages || tempPackages.length==0){
                tempPackages = packages.filter( p=>p.duration.indexOf('月')>-1)
                tempPackages.sort((a:any,b:any)=>{
                        let d1 =  this.getMonthDuration(a)
                        let d2 =  this.getMonthDuration(b)
                        return d1 - d2
                })
                isMin = true
            }
            if(tempPackages && tempPackages.length>0){
                matchPackage = CMN.deepCopy( tempPackages[0] )
                matchPackage.isMin = isMin
            }
            
        }

        if( matchPackage == null ){
            //没有包则返回所有包中最小的包
            matchPackage = CMN.deepCopy(packages[packages.length-1])
            matchPackage.isMin = true
        }
        return matchPackage
    }

    findPackages(size:number, payMethod:PayMethod,packages:SpecProduct_CdnPackage[]){
        if (!packages || packages.length == 0) return null

        packages.sort((a:any,b:any)=>{
            let d1 =  this.getStoreOrFlowSize_GB(a)
            let d2 =  this.getStoreOrFlowSize_GB(b)
            return d2-d1
        })

        let matchInfo:any = {}
        let computeSize = 0

        packages.forEach(p=>{
            let pSize:number = this.getStoreOrFlowSize_GB(p)
            while(true){
                if(computeSize+pSize<=size){
                    computeSize+=pSize
                    if(!matchInfo[p.spec]){
                        matchInfo[p.spec] = 1
                    }else{
                        matchInfo[p.spec]+=1
                    }
                }else{
                    break
                }
            } 
        })

        let finalPackages:any[] = []

        Object.keys(matchInfo).forEach(spec=>{
            let specPackages = packages.filter(p=>p.spec == spec)
            let matchPackage =  this.findPackageByDuration(specPackages,payMethod)
            matchPackage.cnt = matchInfo[spec]
            if(matchPackage){
                finalPackages.push(matchPackage)
            }
        })

        return finalPackages
    }

    computePackage(size:number,payMethod:PayMethod,packages:SpecProduct_CdnPackage[]){

        let finalPackages = this.findPackages(size,payMethod,packages)
        if (!finalPackages || finalPackages.length == 0) return null

        let priceInfo=new PriceInfo() 
       

        //计算资源包价格和显示文本
        finalPackages.forEach(p=>{
            let dim = new DimPriceInfo()
            let price = PRODUCT_MANAGERS.get(ProductCate.CDN_PACKAGE)?.calPrice(p,payMethod).oriPrice

            dim.payMethod = payMethod
            if(p.isMin){   //获取的是最小时间段的包
                let monthDuration = this.getMonthDuration(p)
                if(payMethod.granType == PricePayTimeGranularity.Year){
                    if(monthDuration == 12){
                        dim.dim=`${ p.spec}-${ p.region}-${ p.resourceType}-${p.duration}`
                        dim.unitName = '元/'+p.duration
                        dim.unitCnt = p.cnt*payMethod.granCnt
                        dim.unitPrice = price
                    }else if(monthDuration>12){
                        let duration = '1年'
                        dim.dim=`${ p.spec}-${ p.region}-${ p.resourceType}-${duration}-虚构`
                        dim.unitName = '元/'+duration
                        dim.unitCnt =  p.cnt*payMethod.granCnt
                        dim.unitPrice = price?.div(monthDuration/12)
                    }else{
                        let duration = '1个月'
                        dim.dim=`${ p.spec}-${ p.region}-${ p.resourceType}-${duration}-虚构`
                        dim.unitName = '元/'+duration
                        dim.unitCnt =  p.cnt*payMethod.granCnt * 12
                        dim.unitPrice = price?.div(monthDuration)
                    }
                }else{
                    if(monthDuration == 1){
                        dim.dim=`${ p.spec}-${ p.region}-${ p.resourceType}-${p.duration}`
                        dim.unitName = '元/'+p.duration
                        dim.unitCnt = p.cnt*payMethod.granCnt
                        dim.unitPrice = price
                    }else{
                        let duration = '1月'
                        dim.dim=`${ p.spec}-${ p.region}-${ p.resourceType}-${duration}-虚构`
                        dim.unitName = '元/'+duration
                        dim.unitCnt =  p.cnt*payMethod.granCnt
                        dim.unitPrice = price?.div(monthDuration)
                    }
                }

            }else{  //时间段完全匹配的包
                dim.dim=`${ p.spec}-${ p.region}-${ p.resourceType}-${p.duration}`
                dim.unitName = '元/'+p.duration
                dim.unitCnt =  p.cnt*1
                dim.unitPrice = price
            }
            dim.oriPrice = dim.finPrice =  dim.unitPrice.mul(dim.unitCnt)
            priceInfo.dims.push(dim)
            
        })
        
        return priceInfo
    }
}