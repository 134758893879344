












































































































































































































































































































import Vue from "vue";
import * as IvcVue from "./cmn/IvcVue";
import Component from "vue-class-component";
import CMN from "./cmn/js/common";
import ProductConfTabs from "./components/ProductConfTabs.vue";
import ProductFilters from "./components/ProductFilters.vue";
import ProductBrowser from "./components/ProductBrowser.vue";
import ClientInfoDlg from "./components/ClientInfoDlg.vue";
import Cart from "./components/Cart.vue";
import SpecialOffer from "./components/SpecialOffer.vue";
import REQUESTS from "@/cmn/js/requests";
import ProductConfToast from "./components/ProductConfToast.vue";

import { PayMethod, ProductCate, SpecProduct, Vendor } from "./cmn/model";
import {
  ProductManager_Cdn,
  ProductManager_Db,
  ProductManager_Lb,
  ProductManager_Os,
  ProductManager_Vm,
  PRODUCT_MANAGERS,
} from "./cmn/ProductManger";

import '@mdi/font/css/materialdesignicons.css'

@Component({
  components: {
    ProductConfTabs,
    ProductFilters,
    ProductBrowser,
    ClientInfoDlg,
    Cart,
    SpecialOffer,
    ProductConfToast,
  },
})
export default class App extends Vue {
  showWelcomeDialog = false
  showActivities = false;
  activities = [
    { img: require("./assets/actv1.jpeg") },
    { img: require("./assets/actv1.jpeg") },
  ];
  showMenu = true;
  mini = false;
  showCart = false;
  cartCnt = 0;
  productCate = ProductCate.VM;
  productCoreConf: any = {};
  area = "华南";
  productFilter = [];
  payMethod = new PayMethod();

  productSearchResult: Map<Vendor, SpecProduct[]> = new Map();

  //alert
  notify = {
    show: false,
    color: "primary",
    txt: "添加收藏夹成功！",
    timeoutMs: 2000,
  };

  cheapestProduct = null;

  created() {
  }

  mounted() {
    //配置用本页面为通知显示页面
    console.log("ivcapp", this, this.$ivcApp);
    this.$ivcApp.setNotifyVue(this);
    this.showWelcomeDialog = this.isMobileMode
    let that = this
    setTimeout(function f(){
      that.showWelcomeDialog = false
    }, 5000);
    //一打开时默认显示第一个配置的比价
    // this.area=this.$refs.productCoreConfs.selectedArea
    // this.productCate=this.$refs.productCoreConfs.selectedCate
    this.productCoreConf = this.$refs.productCoreConfs.getCurrProductCoreConf();
    this.onSelectedProductCoreConf(
      this.productCate,
      this.productCoreConf,
      this.area
    );
    
    this.showMenu = !this.isMobileMode;

    // this.saveSales()
  }
  get isMobileMode() {
    return CMN.judgeIsMobileMode(this);
  }

  async onSelectedProductCoreConf(
    productCate: ProductCate,
    productCoreConf: any,
    area: string
  ) {
    this.showMenu = false;

    this.productCate = productCate;
    this.productCoreConf = productCoreConf;
    this.area = area;
    let empty = true;

    if (productCate == ProductCate.VM) {
      let vmManager = <ProductManager_Vm>PRODUCT_MANAGERS.get(ProductCate.VM);
      vmManager.cpu = productCoreConf.cpu;
      vmManager.mem = productCoreConf.mem;
      vmManager.sysDiskSize = productCoreConf.sysDiskSize;
      vmManager.datDiskSize = productCoreConf.datDiskSize;
      vmManager.bandwidth = productCoreConf.bandwidth;
      vmManager.area = area;
      await vmManager.loadProductsAndPrices();
      this.productSearchResult = vmManager.filterProducts(
        new Map(Object.entries(this.productFilter))
      );

      this.productSearchResult.forEach((v, k) => {
        if (v.length > 0) empty = false;
      });
      // this.subtitle=`当前配置一: 区域【${vmConf.region4Tab}】 CPU【${vmConf.vCpu}vCPU】内存【${vmConf.mem}G】硬盘【${vmConf.diskSize>0?vmConf.diskSize+'G':'不需要'}】公网【${vmConf.bandwidth>0?vmConf.bandwidth+'M':'不需要'}】`
    }

    if (productCate == ProductCate.DB) {
      let dbManager = <ProductManager_Db>PRODUCT_MANAGERS.get(ProductCate.DB);
      dbManager.cpu = productCoreConf.cpu;
      dbManager.mem = productCoreConf.mem;
      dbManager.engine = productCoreConf.engine;
      dbManager.engineVer = productCoreConf.engineVer;
      dbManager.datDiskSize = productCoreConf.datDiskSize;
      dbManager.area = area;

      await dbManager.loadProductsAndPrices();

      this.productSearchResult = dbManager.filterProducts(
        new Map(Object.entries(this.productFilter))
      );

      this.productSearchResult.forEach((v, k) => {
        if (v.length > 0) empty = false;
      });
    }

    if (productCate == ProductCate.LB) {
      let lbManager = <ProductManager_Lb>PRODUCT_MANAGERS.get(ProductCate.LB);
      lbManager.bandwidth = productCoreConf.bandwidth;
      lbManager.area = area;
      await lbManager.loadProductsAndPrices();

      this.productSearchResult = lbManager.filterProducts(
        new Map(Object.entries(this.productFilter))
      );

      this.productSearchResult.forEach((v, k) => {
        if (v.length > 0) empty = false;
      });
    }

    if (productCate == ProductCate.OS) {
      let osManager = <ProductManager_Os>PRODUCT_MANAGERS.get(ProductCate.OS);
      osManager.osStoreSize = parseInt(productCoreConf.osStoreSize);
      if (!(osManager.osStoreSize > 0)) {
        this.showNotify(
          "输入的存储量格式错误,请重新输入",
          IvcVue.NotifyLevel.ERROR
        );
        osManager.osStoreSize = 40;
        this.productSearchResult = new Map();
        return;
      }

      osManager.osFlowSize = parseInt(productCoreConf.osFlowSize);
      if (!(osManager.osFlowSize > 0)) {
        this.showNotify(
          "输入的流出流量格式错误,请重新输入",
          IvcVue.NotifyLevel.ERROR
        );
        osManager.osFlowSize = 40;
        this.productSearchResult = new Map();

        return;
      }

      // osManager.osRequetCnt = productCoreConf.osRequetCnt

      osManager.area = area;

      await osManager.loadProductsAndPrices();

      this.productSearchResult = osManager.filterProducts(
        new Map(Object.entries(this.productFilter))
      );      

      this.productSearchResult.forEach((v, k) => {
        if (v.length > 0) empty = false;
      });
    }

    if (productCate == ProductCate.CDN) {
      let osManager = <ProductManager_Cdn>PRODUCT_MANAGERS.get(ProductCate.CDN);

      osManager.cdnFlowSize = parseInt(productCoreConf.cdnFlowSize);
      if (!(osManager.cdnFlowSize > 0)) {
        this.showNotify(
          "输入的流出流量格式错误,请重新输入",
          IvcVue.NotifyLevel.ERROR
        );
        osManager.cdnFlowSize = 40;
        this.productSearchResult = new Map();
        return;
      }

      osManager.area = area;

      await osManager.loadProductsAndPrices();

      this.productSearchResult = osManager.filterProducts(
        new Map(Object.entries(this.productFilter))
      );

      this.productSearchResult.forEach((v, k) => {
        if (v.length > 0) empty = false;
      });
      
    }

    if (empty) {
      this.showNotify("无该配置, 请重新选择配置", IvcVue.NotifyLevel.ERROR);
      return;
    }

    // console.log("this.productSearchResult", this.productSearchResult)
  }

  onPayMethodChanged(payMethod: PayMethod) {
    // alert('onPayMethodChanged:'+JSON.stringify(payMethod))
    this.payMethod = payMethod;
  }
  onFilterChanged(filter: any) {
    // alert('onFilterChanged:'+JSON.stringify(filter))
    // let vmManager= <ProductManager_Vm>PRODUCT_MANAGERS.get(ProductCate.VM)
    // this.productSearchResult=vmManager.filterProducts(new Map(Object.entries(filter)))
    this.productFilter = filter;
    let pManager = <any>PRODUCT_MANAGERS.get(this.productCate);
    this.productSearchResult = pManager.filterProducts(
      new Map(Object.entries(filter))
    );
  }

  onSubmitCart() {
    // alert(JSON.stringify(this.$refs.cart.cartItems))
    this.$refs.dlgClientInfo.open();
  }

  async onConfirmedClientInfo(clientInfo: any) {
    
    
    try {
        localStorage.setItem("clientInfo", JSON.stringify(clientInfo));
        let carts =  localStorage.getItem(IvcVue.LocalstoreKey.CART_LIST)
        clientInfo['carts'] = carts
        
        let info = await REQUESTS.saveClient(clientInfo);
        localStorage.setItem(window.config.KEY_NAME.CLIENT, info.id);
        this.showNotify(
          "信息保存成功",
          IvcVue.NotifyLevel.SUCC
        );
    
    } catch (e) {
      console.error("信息保存错误:", e);
      this.showNotify(
          "信息保存失败",
          IvcVue.NotifyLevel.ERROR
      );
    }
    

    // alert(JSON.stringify(clientInfo))
  }

  saveSales() {
    let sales = this.$route.query[window.config.KEY_NAME.SALES];
    if (sales) {
      localStorage.setItem(window.config.KEY_NAME.SALES, sales);
    }
  }

  showNotify(msg: string, level: IvcVue.NotifyLevel = IvcVue.NotifyLevel.INFO) {
    switch (level) {
      case IvcVue.NotifyLevel.SUCC:
        this.notify.color = "green";
        break;
      case IvcVue.NotifyLevel.WARN:
        this.notify.color = "orange";
        break;
      case IvcVue.NotifyLevel.ERROR:
        this.notify.color = "red";
        break;
      default:
        this.notify.color = "blue";
    }
    this.notify.txt = msg;
    this.notify.show = true;
  }
  addCart(product: SpecProduct) {
    // alert(JSON.stringify(product))
    this.$refs.cart.add(product);
    this.$ivcApp.showNotify("添加收藏夹成功！");
  }
  onProductListUpdated(venderItems: Record<string, any>[]) {
    console.debug("产品列表变更", venderItems);
    if (!venderItems || venderItems.length <= 0) {
      this.cheapestProduct = null;
      return;
    }

    try {
      this.cheapestProduct = venderItems[0].regions[0].products[0];
      console.debug("最低价产品已变更", this.cheapestProduct);
    } catch (e) {
      console.exception("获取最便宜产品失败");
      this.cheapestProduct = null;
    }
  }
  openDlg() {
    this.showActivities = true;
  }
  closeDlg() {
    this.showActivities = false;
  }
  turn2OnlineSevice() {
    let salesKey = window.config.KEY_NAME.SALES;
    let clientKey = window.config.KEY_NAME.CLIENT;
    let sales = localStorage.getItem(salesKey);
    let client = localStorage.getItem(clientKey);
    window.open(
      `${window.config.ONLINE_SEVICE_URL}?${salesKey}=${sales}&${clientKey}=${client}`,
      "_blank"
    );
  }
  configTabClick(showMenu: boolean) {
    this.showMenu = showMenu;
  }
  showCartClick() {
    this.showCart = !this.showCart;
    this.$refs.cart.initTab();
  }
}
