import Decimal from "decimal.js"
import {BaseParser} from './PriceInfoParser'
import {DimPriceInfo, HuaweiDisk, HuaweiEcs, HuaweiIp, PayMethod, PriceInfo, PricePayTimeGranularity, PricePayType, ProductCate, SpecProduct, Vendor } from "./model"
import {PRODUCT_MANAGERS, ProductManager_Vm} from './ProductManger'

export class Parser4HuaweiEcs extends BaseParser{
    declare productManager:ProductManager_Vm
    priceInfos:any={}
    specProducts:Array<HuaweiEcs>=[]


    init(rawInfo:Array<any>): void {
        this.specProducts=[]
        rawInfo.forEach((item)=>{
            try{
                let product=this.parseFromJson(item)
                this.priceInfos[this.getPk(product)]=item
                this.specProducts.push(product)
            }catch(e){
                console.log('解析出错',e)
            }
        })
        
    }

    calPrice(specProduct: HuaweiEcs, payMethod:PayMethod): PriceInfo {
        let rawPriceInfo=this.priceInfos[this.getPk(specProduct)]

        let dim=new DimPriceInfo()
        dim.payMethod=payMethod
        dim.dim='ECS:计算资源费用'
        if(payMethod.payType==PricePayType.PrevPay){
            if(!rawPriceInfo.monthlyPrice){
                return null//TODO
            }
            let monthlyPrice=new Decimal(rawPriceInfo.monthlyPrice)
            dim.unitName='元/月'
            dim.unitPrice=monthlyPrice
            dim.unitCnt=payMethod.granType/(24*30)*payMethod.granCnt
            dim.finPrice=dim.oriPrice=dim.unitPrice.mul(dim.unitCnt)

            if(payMethod.granType==PricePayTimeGranularity.Year){
                let yearlyPrices=rawPriceInfo.yearlyPrice==null?[
                    monthlyPrice.mul(12),
                    monthlyPrice.mul(12 * 2),
                    monthlyPrice.mul(12 * 3)]:[
                    new Decimal(rawPriceInfo.yearlyPrice),
                    new Decimal(rawPriceInfo['2yearPrice']==null?rawPriceInfo.yearlyPrice*2:rawPriceInfo['2yearPrice']),
                    new Decimal(rawPriceInfo['3yearPrice']==null?rawPriceInfo.yearlyPrice*3:rawPriceInfo['3yearPrice'])]

                if(payMethod.granCnt<=3){
                    dim.finPrice=yearlyPrices[payMethod.granCnt-1]
                }else{
                    dim.finPrice=yearlyPrices[2].div(3).mul(payMethod.granCnt)
                }
            }
        }else{

            if(rawPriceInfo.hourlyPrice == null || rawPriceInfo.hourlyPrice == undefined)
                return null

            let hourPrice=new Decimal(rawPriceInfo.hourlyPrice)

            dim.unitName='元/小时'
            dim.unitCnt=payMethod.granType*payMethod.granCnt
            dim.unitPrice=hourPrice
            dim.finPrice=dim.oriPrice=dim.unitPrice.mul(dim.unitCnt)
        }

        let priceInfo=new PriceInfo()
        priceInfo.dims.push(dim)
// debugger
        if(specProduct.bandwidth>0){
            let eip=specProduct.ipTypes[specProduct.selectedIpType]
            eip.bandwidth=specProduct.bandwidth
            let eipPriceInfo=PRODUCT_MANAGERS!.get(ProductCate.IP)!.calPrice(eip,payMethod)
            priceInfo.dims=priceInfo.dims.concat(eipPriceInfo.dims)
        }
        if(specProduct.sysDiskSize>0){
            let disk=specProduct.sysDiskTypes[specProduct.selectedSysDiskType]
            disk.size=specProduct.sysDiskSize
            let diskPriceInfo=PRODUCT_MANAGERS!.get(ProductCate.DISK)!.calPrice(disk,payMethod)
            priceInfo.dims=priceInfo.dims.concat(diskPriceInfo.dims)
        }
        if(specProduct.datDiskSize>0){
            let disk=specProduct.datDiskTypes[specProduct.selectedDatDiskType]
            disk.size=specProduct.datDiskSize
            let diskPriceInfo=PRODUCT_MANAGERS!.get(ProductCate.DISK)!.calPrice(disk,payMethod)
            priceInfo.dims=priceInfo.dims.concat(diskPriceInfo.dims)
        }

        priceInfo.dims.forEach(dim=>{
            priceInfo.finPrice=priceInfo.finPrice.add(dim.finPrice)
            priceInfo.oriPrice=priceInfo.oriPrice.add(dim.oriPrice)
        })

        specProduct.price=priceInfo
        return priceInfo
    }

    parseFromJson(jsonO: any): HuaweiEcs|null {
        let inst:HuaweiEcs=new HuaweiEcs()
        inst.cpu=jsonO.cpu
        inst.mem=jsonO.memory
        inst.tags=jsonO.tags
        inst.model=jsonO.spec
        inst.region=jsonO.region

        inst.area=this.productManager.area
        inst.bandwidth=this.productManager.bandwidth
        inst.sysDiskSize=this.productManager.sysDiskSize
        inst.datDiskSize=this.productManager.datDiskSize
        
        if(inst.sysDiskSize>0 || inst.datDiskSize>0){
            inst.sysDiskTypes=inst.datDiskTypes=PRODUCT_MANAGERS.get(ProductCate.DISK)?.filterProductsInVendor(inst.vendor,new Map([['region',inst.region]]))
            if(!inst.sysDiskTypes || inst.sysDiskTypes.length<=0) return null//没可使用的硬盘 vm实例也不显示
        }

        if(inst.bandwidth>0){
            inst.ipTypes=PRODUCT_MANAGERS.get(ProductCate.IP)?.filterProductsInVendor(inst.vendor,new Map([['region',inst.region]]))
            if(!inst.ipTypes || inst.ipTypes.length<=0) return null//没可使用的公网 vm也不显示
        }

        return inst
    }
    
}
export class Parser4HuaweiDisk extends BaseParser{
    priceInfos:any={}
    specProducts:Array<HuaweiDisk>=[]

    calPrice(specProduct: HuaweiDisk, payMethod:PayMethod): PriceInfo {
        let rawPriceInfo=this.priceInfos[this.getPk(specProduct)]

        let dim=new DimPriceInfo()
        dim.payMethod=payMethod
        dim.dim='云盘:存储费用'
        if(payMethod.payType==PricePayType.PrevPay){
            dim.unitName='元/(GB*月)'
            dim.unitPrice=new Decimal(rawPriceInfo.monthlyPrice)
            dim.unitCnt=specProduct.size*(payMethod.granType/(24*30)*payMethod.granCnt)
            dim.finPrice=dim.oriPrice=dim.unitPrice.mul(dim.unitCnt)

            let yearlyPriceOf1G=rawPriceInfo.yearlyPrice==null?[
                dim.unitPrice.mul(12),
                dim.unitPrice.mul(12 * 2),
                dim.unitPrice.mul(12 * 3),
                dim.unitPrice.mul(12 * 4),
                dim.unitPrice.mul(12 * 5),
            ]:[
                new Decimal(rawPriceInfo.yearlyPrice),
                new Decimal(rawPriceInfo['2yearPrice']),
                new Decimal(rawPriceInfo['3yearPrice']),
                new Decimal(rawPriceInfo['4yearPrice']==null?rawPriceInfo.yearlyPrice*4*0.6:rawPriceInfo['4yearPrice']),
                new Decimal(rawPriceInfo['5yearPrice']==null?rawPriceInfo.yearlyPrice*5*0.6:rawPriceInfo['5yearPrice']),
            ]

            if(payMethod.granType==PricePayTimeGranularity.Year){
                if(payMethod.granCnt<=5){
                    dim.finPrice=yearlyPriceOf1G[payMethod.granCnt-1].mul(specProduct.size)
                }else{
                    dim.finPrice=yearlyPriceOf1G[4].mul(specProduct.size)
                }
            }
        }else{
            dim.unitName='元/(GB*小时)'
            dim.unitPrice=new Decimal(rawPriceInfo.hourlyPrice)
            dim.unitCnt=specProduct.size*(payMethod.granType*payMethod.granCnt)
            dim.finPrice=dim.oriPrice=dim.unitPrice.mul(dim.unitCnt)
        }

        let priceInfo=new PriceInfo()
        priceInfo.dims=[dim]
        priceInfo.finPrice=dim.finPrice
        priceInfo.oriPrice=dim.oriPrice

        specProduct.price=priceInfo
        return priceInfo
    }
    parseFromJson(jsonO: any): HuaweiDisk {
        let inst:HuaweiDisk=new HuaweiDisk()
        inst.tags=jsonO.tags
        inst.model=jsonO.type
        inst.region=jsonO.region
        return inst
    }
    
}

export class Parser4HuaweiIp extends BaseParser{
    priceInfos:any={}
    specProducts:Array<HuaweiIp>=[]

    generateYearlyPrices(rawPriceInfo:any){
        let oneYearPrice = rawPriceInfo['按1年带宽计费(元/Mbit/s)'].match(/\d+\.\d+/g)
        return oneYearPrice = [
            new Decimal(oneYearPrice[0]),
            oneYearPrice[2]==null?new Decimal(0):new Decimal(oneYearPrice[1])
        ]
    }
    generateMonthlyPrices(rawPriceInfo:any){
        let oneMonthPrice = rawPriceInfo['按1个月带宽计费(元/Mbit/s)'].match(/\d+\.\d+/g)
        return [
            new Decimal(oneMonthPrice[0]),
            oneMonthPrice[2]==null?new Decimal(0):new Decimal(oneMonthPrice[1])
        ]
    }

    calPrice(specProduct: HuaweiIp, payMethod:PayMethod): PriceInfo {
        let rawPriceInfo=this.priceInfos[this.getPk(specProduct)]

        let dim=new DimPriceInfo()
        dim.payMethod=payMethod
        if(payMethod.payType==PricePayType.PrevPay){
            dim.dim='公网IP:带宽费用'
            let monthlyPriceOfBandwidth = this.generateMonthlyPrices(rawPriceInfo)

            dim.unitName=`元/(${specProduct.bandwidth}Mb*月)`
            dim.unitCnt=payMethod.granType/(24*30)*payMethod.granCnt
            if (specProduct.bandwidth < 6){
                dim.unitPrice=monthlyPriceOfBandwidth[0].mul(specProduct.bandwidth)
                dim.finPrice=dim.oriPrice=dim.unitPrice.mul(dim.unitCnt)
            }else {
                dim.unitPrice=monthlyPriceOfBandwidth[0].mul(5).add(monthlyPriceOfBandwidth[1].mul(specProduct.bandwidth-5))
                dim.finPrice=dim.oriPrice=dim.unitPrice.mul(dim.unitCnt)
            }

            if(payMethod.granType==PricePayTimeGranularity.Year){
                let yearlyPrices=this.generateYearlyPrices(rawPriceInfo)
                if(specProduct.bandwidth<6){
                    dim.finPrice=yearlyPrices[0].mul(payMethod.granCnt).mul(specProduct.bandwidth)
                }else{
                    dim.finPrice=yearlyPrices[0].mul(payMethod.granCnt).mul(5).add(yearlyPrices[1].mul(payMethod.granCnt).mul(specProduct.bandwidth-5))
                }
            }
        }else{
            dim.dim='公网IP:流量费用'
            dim.unitName='元/GB'
            dim.unitPrice=new Decimal(rawPriceInfo["按流量计费(元/GB)"].match(/\d+\.\d+/g)[0])
            dim.unitCnt=0//TODO 目前只显示单价 specProduct.flowSize
            dim.finPrice=dim.oriPrice=dim.unitPrice.mul(dim.unitCnt)
        }

        let priceInfo=new PriceInfo()
        priceInfo.dims=[dim]
        priceInfo.finPrice=dim.finPrice
        priceInfo.oriPrice=dim.oriPrice

        specProduct.price=priceInfo
        return priceInfo
    }

    parseFromJson(jsonO: any): HuaweiIp {
        let inst=new HuaweiIp()
        inst.tags=jsonO.tags
        inst.model='多线BGP'
        inst.region=jsonO.region
        return inst
    }
    
}