import Decimal from "decimal.js"
import {BaseParser} from './PriceInfoParser'
import { DimPriceInfo,AliyunLb,PayMethod, PriceInfo, PricePayTimeGranularity, PricePayType,ProductCate} from "./model"

import {PRODUCT_MANAGERS, ProductManager_Lb} from './ProductManger'

export class Parser4AliyunLb extends BaseParser{
    declare productManager:ProductManager_Lb
    priceInfos:any={}
    specProducts:Array<AliyunLb>=[]

    init(rawInfo:Array<any>): void {
        this.specProducts=[]
        rawInfo.forEach((item)=>{
            try{
                let product=this.parseFromJson(item)
                this.priceInfos[this.getPk(product)]=item
                this.specProducts.push(product)
            }catch(e){
                console.log('解析出错',e)
            }
        })
        
    }

    calPrice(specProduct: AliyunLb, payMethod:PayMethod): PriceInfo {
        let rawPriceInfo=this.priceInfos[this.getPk(specProduct)]
        /**
         *  CLB
            私网：实例费，其他免费。
            公网：EIP费+实例费
            ALB
            私网：实例费+性能容量单位LCU费
            公网：实例费+性能容量单位LCU费+EIP费用
         */

        let dim=new DimPriceInfo()
        dim.payMethod=payMethod
        dim.dim='AliyunLb:实例版本费用'
        if(payMethod.payType==PricePayType.PrevPay){

            //ALB只有后付方式
            if(!rawPriceInfo.instMonthlyPrice)
                return null

            let monthlyPrice=new Decimal(rawPriceInfo.instMonthlyPrice)
            dim.unitName='元/月'
            dim.unitPrice=monthlyPrice
            dim.unitCnt=payMethod.granType/(24*30)*payMethod.granCnt
            dim.oriPrice=dim.unitPrice.mul(dim.unitCnt)

            let discount=1
            if(payMethod.granType==PricePayTimeGranularity.Year){
                // 年付85折
                discount = 0.85
            }
            dim.finPrice=dim.oriPrice.mul(discount)

        }else{
            if(rawPriceInfo.instHourlyPrice == null || rawPriceInfo.instHourlyPrice == undefined)
                return null
            dim.unitName='元/小时'
            dim.unitPrice=new Decimal(rawPriceInfo.instHourlyPrice)
            dim.unitCnt=payMethod.granType*payMethod.granCnt
            dim.finPrice=dim.oriPrice=dim.unitPrice.mul(dim.unitCnt)
        }

        let priceInfo=new PriceInfo()
        priceInfo.dims.push(dim)

        //LCU维度
        if(payMethod.payType==PricePayType.PostPay && rawPriceInfo.instType == 'ALB'){
            let dim=new DimPriceInfo()
            dim.payMethod=payMethod
            dim.dim='AliyunLb:LCU费用'
            dim.unitName='元/LCU'
            dim.unitPrice=new Decimal(rawPriceInfo.lcuPrice)
            dim.unitCnt= 0//TODO 目前只显示单价
            dim.finPrice=dim.oriPrice=dim.unitPrice.mul(dim.unitCnt)
            priceInfo.dims.push(dim)

        }

        //EIP维度
        if(specProduct.bandwidth>0){
            let eip=specProduct.ipTypes[specProduct.selectedIpType]
            eip.bandwidth=specProduct.bandwidth
            let eipPriceInfo=PRODUCT_MANAGERS!.get(ProductCate.LB_IP)!.calPrice(eip,payMethod)
            priceInfo.dims=priceInfo.dims.concat(eipPriceInfo.dims)
        }

        priceInfo.dims.forEach(dim=>{
            priceInfo.finPrice=priceInfo.finPrice.add(dim.finPrice)
            priceInfo.oriPrice=priceInfo.oriPrice.add(dim.oriPrice)
        })

        specProduct.price=priceInfo
        return priceInfo
    }

    parseFromJson(jsonO: any): AliyunLb|null {
        let inst:AliyunLb = new AliyunLb()
        inst.region=jsonO.region
        inst.lbType = jsonO.instType
        inst.spec = jsonO.spec
        inst.specType=jsonO.specType
        inst.tags=jsonO.tags
        inst.model=inst.lbType+inst.spec+inst.specType  
        inst.area=this.productManager.area
        inst.bandwidth=this.productManager.bandwidth                   
        
        let regionTag = null
        try {
            inst.tags.forEach(tag => {
                if(tag.startsWith('/001')){
                    regionTag = tag
                    return
                }
            });
        } catch (error) {
            console.error(error);
        }
        
        if(inst.bandwidth>0){
            inst.ipTypes=PRODUCT_MANAGERS.get(ProductCate.LB_IP)?.filterProductsInVendor(inst.vendor,new Map([['regionTag',regionTag]]))
            if(!inst.ipTypes || inst.ipTypes.length<=0) return null//没可使用的公网 lb也不显示
        }

        return inst
    }
    
}

