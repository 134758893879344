import Decimal from "decimal.js"
import {BaseParser} from './PriceInfoParser'
import { DimPriceInfo,HuaweiLb,PayMethod, PriceInfo, PricePayTimeGranularity, PricePayType,ProductCate} from "./model"

import {PRODUCT_MANAGERS, ProductManager_Lb} from './ProductManger'

export class Parser4HuaweiLb extends BaseParser{
    declare productManager:ProductManager_Lb
    priceInfos:any={}
    specProducts:Array<HuaweiLb>=[]

    init(rawInfo:Array<any>): void {
        this.specProducts=[]
        rawInfo.forEach((item)=>{
            try{
                let product=this.parseFromJson(item)
                this.priceInfos[this.getPk(product)]=item
                this.specProducts.push(product)
            }catch(e){
                console.log('解析出错',e)
            }
        })
        
    }

    calPrice(specProduct: HuaweiLb, payMethod:PayMethod): PriceInfo {
        // huaweiLb 只有后付费方式
        if(payMethod.payType==PricePayType.PrevPay){
            return null
        }
        
        // 共享型实例费暂时为0
        let rawPriceInfo=this.priceInfos[this.getPk(specProduct)]
        if(rawPriceInfo.instHourlyPrice == null || rawPriceInfo.instHourlyPrice == undefined)
            return null

        let dim=new DimPriceInfo()
        dim.payMethod=payMethod
        dim.dim='HuaweiLb:计算资源费用'
        dim.unitName='元/小时'
        dim.unitPrice=new Decimal(rawPriceInfo.instHourlyPrice)
        dim.unitCnt=payMethod.granType*payMethod.granCnt
        dim.finPrice=dim.oriPrice=dim.unitPrice.mul(dim.unitCnt)

        let priceInfo=new PriceInfo()
        priceInfo.dims.push(dim)

        //LCU维度 独享型才有
        if(payMethod.payType==PricePayType.PostPay && rawPriceInfo.instType.indexOf('独享型')>-1){
            let dim=new DimPriceInfo()
            dim.payMethod=payMethod
            dim.dim='HuaweiLb:LCU费用'
            dim.unitName='元/小时'
            //huawei 每个lcu单价为0.0417, 数据库中实例的 lcuPrice = lcuSize * 0.0417
            dim.unitPrice=new Decimal(rawPriceInfo.lcuPrice)
            dim.unitCnt= 1*payMethod.granCnt*payMethod.granType //TODO 目前只显示单价
            dim.finPrice=dim.oriPrice=dim.unitPrice.mul(dim.unitCnt)
            priceInfo.dims.push(dim)

        }

        //EIP维度
        if(specProduct.bandwidth>0){
            let eip=specProduct.ipTypes[specProduct.selectedIpType]
            eip.bandwidth=specProduct.bandwidth
            let eipPriceInfo=PRODUCT_MANAGERS!.get(ProductCate.LB_IP)!.calPrice(eip,payMethod)
            priceInfo.dims=priceInfo.dims.concat(eipPriceInfo.dims)
        }

        priceInfo.dims.forEach(dim=>{
            priceInfo.finPrice=priceInfo.finPrice.add(dim.finPrice)
            priceInfo.oriPrice=priceInfo.oriPrice.add(dim.oriPrice)
        })

        specProduct.price=priceInfo
        return priceInfo
    }

    parseFromJson(jsonO: any): HuaweiLb|null {
        let inst:HuaweiLb = new HuaweiLb()
        inst.region=jsonO.region
        inst.lbType = jsonO.instType
        inst.spec = '-'
        inst.specType=jsonO.specType
        inst.tags=jsonO.tags
        inst.model=inst.lbType+inst.specType  
        inst.area=this.productManager.area
        inst.bandwidth=this.productManager.bandwidth                   
            
        let regionTag = null
        try {
            inst.tags.forEach(tag => {
                if(tag.startsWith('/001')){
                    regionTag = tag
                    return
                }
            });
        } catch (error) {
            console.error(error);
        }
        
        if(inst.bandwidth>0){
            inst.ipTypes=PRODUCT_MANAGERS.get(ProductCate.LB_IP)?.filterProductsInVendor(inst.vendor,new Map([['regionTag',regionTag]]))
            if(!inst.ipTypes || inst.ipTypes.length<=0) return null//没可使用的公网 lb也不显示
        }

        return inst
    }
    
}

