import Decimal from "decimal.js"
import {BaseParser} from './PriceInfoParser'
import {Cvm, DimPriceInfo, PayMethod, PriceInfo, PricePayTimeGranularity, PricePayType, ProductCate, SpecProduct, TencentDisk, TencentIp, Vendor } from "./model"
import {PRODUCT_MANAGERS, ProductManager_Vm} from './ProductManger'


export class Parser4Cvm extends BaseParser{
    declare productManager:ProductManager_Vm
    priceInfos:any={}
    specProducts:Array<Cvm>=[]
    
    init(rawInfo:Array<any>): void {
        this.specProducts=[]
        rawInfo.forEach((item)=>{
            try{
                let product=this.parseFromJson(item)
                this.priceInfos[this.getPk(product)]=item
                this.specProducts.push(product)
            }catch(e){
                console.log('解析出错',e)
            }
        })
        
    }

    calPrice(specProduct: Cvm, payMethod:PayMethod): PriceInfo {
        let rawPriceInfo=this.priceInfos[this.getPk(specProduct)]

        let dim=new DimPriceInfo()
        dim.payMethod=payMethod
        dim.dim='CVM:计算资源费用'
        if(payMethod.payType==PricePayType.PrevPay){
            let monthlyPrice=new Decimal(rawPriceInfo.standard)
            dim.unitName='元/月'
            dim.unitPrice=monthlyPrice
            dim.unitCnt=payMethod.granType/(24*30)*payMethod.granCnt
            dim.oriPrice=dim.unitPrice.mul(dim.unitCnt)

            let discount=1
            switch(payMethod.granType){
                case PricePayTimeGranularity.Year:
                    if(specProduct.model == "标准型S5" && ["广州","北京", "上海", "南京"].indexOf(specProduct.region) != -1){// S5特惠地区
                        if(payMethod.granCnt==1)
                            discount=0.69
                        else if(payMethod.granCnt==2)
                            discount=0.55
                        else if(payMethod.granCnt==3)
                            discount=0.41
                        else if(payMethod.granCnt==4)
                            discount=0.33
                        else
                            discount=0.27
                    }else{
                        if(payMethod.granCnt==1)
                            discount=0.83
                        else if(payMethod.granCnt==2)
                            discount=0.70
                        else
                            discount=0.5
                    }

                    break
                case PricePayTimeGranularity.Month:
                    if(specProduct.model == "标准型S5" && ["广州","北京", "上海", "南京"].indexOf(specProduct.region) != -1){// S5特惠地区
                        if(payMethod.granCnt<=2)
                            discount=0.87
                        else if(payMethod.granCnt<=5)
                            discount=0.78
                        else// if(payMethod.granCnt<=11)
                            discount=0.73
                    }else{
                        if(payMethod.granCnt>=6)
                            discount=0.88
                    }
            }

            dim.finPrice=dim.oriPrice.mul(discount)
        }else{
            
            if(rawPriceInfo.hourlyT1Price == null || rawPriceInfo.hourlyT1Price == undefined)
                return null

            let hourPrices=[
                new Decimal(rawPriceInfo.hourlyT1Price),
                new Decimal(rawPriceInfo.hourlyT2Price?rawPriceInfo.hourlyT2Price:rawPriceInfo.hourlyT1Price),
                new Decimal(rawPriceInfo.hourlyT3Price?rawPriceInfo.hourlyT3Price:rawPriceInfo.hourlyT1Price),
            ]

            dim.unitName='元/小时'
            dim.unitCnt=payMethod.granType*payMethod.granCnt
            dim.unitPrice=new Decimal(hourPrices[0])
            dim.oriPrice=dim.unitPrice.mul(dim.unitCnt)
            if(dim.unitCnt<=96){
                dim.finPrice=dim.oriPrice
            }else if(dim.unitCnt<=360){
                dim.finPrice=dim.unitPrice.mul(96).add(hourPrices[1].mul(dim.unitCnt-96))
            }else{
                dim.finPrice=dim.unitPrice.mul(96).add(hourPrices[1].mul(dim.unitCnt-360)).add(hourPrices[2].mul(dim.unitCnt-360))
            }
            
        }

        let priceInfo=new PriceInfo()
        priceInfo.dims.push(dim)
// debugger
        if(specProduct.bandwidth>0){
            let eip=specProduct.ipTypes[specProduct.selectedIpType]
            eip.bandwidth=specProduct.bandwidth
            let eipPriceInfo=PRODUCT_MANAGERS!.get(ProductCate.IP)!.calPrice(eip,payMethod)
            priceInfo.dims=priceInfo.dims.concat(eipPriceInfo.dims)
        }
        if(specProduct.sysDiskSize>0){
            let disk=specProduct.sysDiskTypes[specProduct.selectedSysDiskType]
            disk.size=specProduct.sysDiskSize
            let diskPriceInfo=PRODUCT_MANAGERS!.get(ProductCate.DISK)!.calPrice(disk,payMethod)
            priceInfo.dims=priceInfo.dims.concat(diskPriceInfo.dims)
        }
        if(specProduct.datDiskSize>0){
            let disk=specProduct.datDiskTypes[specProduct.selectedDatDiskType]
            disk.size=specProduct.datDiskSize
            let diskPriceInfo=PRODUCT_MANAGERS!.get(ProductCate.DISK)!.calPrice(disk,payMethod)
            priceInfo.dims=priceInfo.dims.concat(diskPriceInfo.dims)
        }

        priceInfo.dims.forEach(dim=>{
            priceInfo.finPrice=priceInfo.finPrice.add(dim.finPrice)
            priceInfo.oriPrice=priceInfo.oriPrice.add(dim.oriPrice)
        })

        specProduct.price=priceInfo
        return priceInfo
    }

    parseFromJson(jsonO: any): Cvm|null {
        let inst:Cvm=new Cvm()
        inst.cpu=jsonO.cpu
        inst.mem=jsonO.memory
        inst.tags=jsonO.tags
        inst.model=jsonO.type
        inst.region=jsonO.region

        inst.area=this.productManager.area
        inst.bandwidth=this.productManager.bandwidth
        inst.sysDiskSize=this.productManager.sysDiskSize
        inst.datDiskSize=this.productManager.datDiskSize
        
        if(inst.sysDiskSize>0 || inst.datDiskSize>0){
            inst.sysDiskTypes=inst.datDiskTypes=PRODUCT_MANAGERS.get(ProductCate.DISK)?.filterProductsInVendor(inst.vendor,new Map([['region',inst.region]]))
            if(!inst.sysDiskTypes || inst.sysDiskTypes.length<=0) return null//没可使用的硬盘 vm实例也不显示
        }

        if(inst.bandwidth>0){
            inst.ipTypes=PRODUCT_MANAGERS.get(ProductCate.IP)?.filterProductsInVendor(inst.vendor,new Map([['region',inst.region]]))
            if(!inst.ipTypes || inst.ipTypes.length<=0) return null//没可使用的公网 vm也不显示
        }

        return inst
    }
    
}
export class Parser4TencentDisk extends BaseParser{
    priceInfos:any={}
    specProducts:Array<TencentDisk>=[]

    calPrice(specProduct: TencentDisk, payMethod:PayMethod): PriceInfo {
        let rawPriceInfo=this.priceInfos[this.getPk(specProduct)]

        let dim=new DimPriceInfo()
        dim.payMethod=payMethod
        dim.dim='云盘:存储费用'
        if(payMethod.payType==PricePayType.PrevPay){
            dim.unitName='元/(GB*月)'
            dim.unitPrice=new Decimal(rawPriceInfo.monthlyPrice)
            dim.unitCnt=specProduct.size*(payMethod.granType/(24*30)*payMethod.granCnt)
            dim.oriPrice=dim.unitPrice.mul(dim.unitCnt)

            let discount=1
            if(payMethod.granType==PricePayTimeGranularity.Year){
                if(payMethod.granCnt==1){
                    discount = 0.83
                }else if(payMethod.granCnt==2){
                    discount = 0.7
                }else if(payMethod.granCnt>2){
                    discount=0.5
                }
            }else if(payMethod.granType==PricePayTimeGranularity.Month){
                if(payMethod.granCnt>=6){
                    discount=0.88
                }
            }
            dim.finPrice=dim.oriPrice.mul(discount)
        }else{
            dim.unitName='元/(GB*小时)'
            dim.unitPrice=new Decimal(rawPriceInfo.hourlyPrice).div(100)
            dim.unitCnt=specProduct.size*(payMethod.granType*payMethod.granCnt)
            dim.finPrice=dim.oriPrice=dim.unitPrice.mul(dim.unitCnt)
        }

        let priceInfo=new PriceInfo()
        priceInfo.dims=[dim]
        priceInfo.finPrice=dim.finPrice
        priceInfo.oriPrice=dim.oriPrice

        specProduct.price=priceInfo
        return priceInfo
    }
    parseFromJson(jsonO: any): TencentDisk {
        let inst:TencentDisk=new TencentDisk()
        inst.tags=jsonO.tags
        inst.model=jsonO.type
        inst.region=jsonO.region
        return inst
    }
    
}

export class Parser4TencentIp extends BaseParser{
    priceInfos:any={}
    specProducts:Array<TencentIp>=[]

    calPrice(specProduct: TencentIp, payMethod:PayMethod): PriceInfo {
        let rawPriceInfo=this.priceInfos[this.getPk(specProduct)]

        let dim=new DimPriceInfo()
        dim.payMethod=payMethod
        if(payMethod.payType==PricePayType.PrevPay){
            dim.dim='公网IP:带宽费用'
            let monthlyPriceOfBandwidth = [
                new Decimal(rawPriceInfo["1MonthLe2M"]),
                new Decimal(rawPriceInfo["1MonthLe5M"]),
                new Decimal(rawPriceInfo["1MonthGt5M"])
            ]

            dim.unitName=`元/(${specProduct.bandwidth}Mb*月)`
            dim.unitCnt=payMethod.granType/(24*30)*payMethod.granCnt
            if (specProduct.bandwidth < 3){
                dim.unitPrice=monthlyPriceOfBandwidth[0].mul(specProduct.bandwidth)
            }else if (specProduct.bandwidth < 6){
                dim.unitPrice=monthlyPriceOfBandwidth[0].mul(2).add(monthlyPriceOfBandwidth[1].mul(specProduct.bandwidth-2))
            }else {
                dim.unitPrice=monthlyPriceOfBandwidth[0].mul(2).add(monthlyPriceOfBandwidth[1].mul(3)).add(monthlyPriceOfBandwidth[2].mul(specProduct.bandwidth-5))
            }
            dim.oriPrice=dim.unitPrice.mul(dim.unitCnt)

            let discount=1
            if(payMethod.granType==PricePayTimeGranularity.Year){
                discount = 0.83
            }else if(payMethod.granType==PricePayTimeGranularity.Month && payMethod.granCnt>5){
                discount = 0.88
            }

            dim.finPrice=dim.oriPrice.mul(discount)
        }else{
            dim.dim='公网IP:流量费用'
            dim.unitName='元/GB'
            dim.unitPrice=new Decimal(rawPriceInfo['1Gb'])
            dim.unitCnt=0//TODO 目前只显示单价 specProduct.flowSize
            dim.finPrice=dim.oriPrice=dim.unitPrice.mul(dim.unitCnt)
        }

        let priceInfo=new PriceInfo()
        priceInfo.dims=[dim]
        priceInfo.finPrice=dim.finPrice
        priceInfo.oriPrice=dim.oriPrice

        specProduct.price=priceInfo
        return priceInfo
    }

    parseFromJson(jsonO: any): TencentIp {
        let inst=new TencentIp()
        inst.tags=jsonO.tags
        inst.model='多线BGP'
        inst.region=jsonO.region
        return inst
    }
    
}