import _ from "lodash";
import vuetify from "../../main";

let CMN = (() => {
  let getQueryString = (name) => {
    var reg = new RegExp("(^|&)" + name + "=([^&]*)(&|$)", "i");
    var r = window.location.search.substr(1).match(reg);
    if (r != null) return unescape(r[2]);
    return null;
  };

  let getItemPropInObjArr = (
    objArr,
    matchItemKeyName,
    matchItemKeyVal,
    targetPropName
  ) => {
    for (let i = 0; i < objArr.length; ++i) {
      let item = objArr[i];
      if (item[matchItemKeyName] == matchItemKeyVal)
        return item[targetPropName];
    }
    return null;
  };

  let deepCopy = (obj) => {
    return _.cloneDeep(obj);
    // return JSON.parse(JSON.stringify(obj));
  };
  let shallowCopy = (obj) => {
    return _.clone(obj);
  };

  let fireDownload = (wrapContent, fileName) => {
    console.log("wrapContent", wrapContent);
    const link = document.createElement("a");
    link.href = encodeURI(wrapContent);
    link.download = fileName;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };
  let fireDownloadFile = (content, mime, fileName) => {
    console.log("content", content);
    let wrapContent = `data:${mime},${content}`;
    fireDownload(wrapContent, fileName);
  };
  //createDownloadCsv('A,B,中文\n1,2,测试\n','test.csv')
  let fireDownloadCsv = (content, fileName) => {
    let csvContent = "\uFEFF" + content;
    fireDownloadFile(csvContent, "text/csv;charset=utf-8", fileName);
  };

  /**
   * 根据指定字段和值获取对象数组中配置的对象
   * @param {*} keyName 对象的某个字段名称  'xx'
   * @param {*} vArr 字段要匹配的值的数组 [12]
   * @param {*} objectArr 对象数组 [{xx:12,yy:11}]
   */
  let getSubObjectArr = (keyName, vArr, objectArr) => {
    if (!keyName || !vArr || !objectArr) {
      return [];
    }
    let sub = objectArr.filter((o) => {
      if (vArr.find((v) => v == o[keyName])) {
        return true;
      }
      return false;
    });
    // console.log("%%%%%@@@@@@@@",vArr,sub)
    return sub;
  };
  /**
   * 根据api返回数据和formDef生成子formDef
   * @param {*} data data:api返回的data
   * @param {*} formDef formDef:js定义的总formDef
   */
  let genFormDefsByApiData = (data, formDef) => {
    if (data && data instanceof Array && data.length > 0) {
      return CMN.getSubObjectArr("key", Object.keys(data[0]), formDef);
    }

    return formDef;
  };

  /**
   *  根据filter筛选值和formDef生成子formDef
   * @param {*} seledVs filter筛选值数组，如[1,2.3],对应enum中的v值
   * @param {*} enumKeyName enum中要返回的值的key名称
   * @param {*} Enum 筛选项使用的枚举
   * @param {*} formDef 定义的总formdef
   * @param {*} fixedFormKeys table要固定显示的字段名称
   */
  let genFormDefsByFilter = (
    seledVs,
    enumKeyName,
    Enum,
    formDef,
    fixedFormKeys = null
  ) => {
    if (seledVs && enumKeyName && Enum && formDef) {
      let subEnum = CMN.getSubObjectArr("v", seledVs, Enum);
      let formKeys = [];

      if (fixedFormKeys && fixedFormKeys instanceof Array) {
        formKeys = formKeys.concat(fixedFormKeys);
      }

      if (subEnum) {
        formKeys = formKeys.concat(subEnum.map((o) => o[enumKeyName]));
      }

      return CMN.getSubObjectArr("key", formKeys, formDef);
    }

    return formDef;
  };

  let travelJson = (curObj, cb, level = 0, parent = null) => {
    if (cb(curObj, level, parent)) return;
    if (curObj instanceof Array) {
      curObj.forEach((arrItem) => {
        travelJson(arrItem, cb, level + 1, curObj);
      });
    } else if (curObj instanceof Object) {
      for (let k in curObj) {
        let mapItem = curObj[k];
        travelJson(mapItem, cb, level + 1, curObj);
      }
    } else {
      return;
    }
  };
  let findItemInEnums = (enums, value) => {
    for (let i = 0; i < enums.length; i++) {
      if (enums[i].value === value) {
        return enums[i];
      }
    }
    return null;
  };
  let findAllValueInEnums = (enums) => {
    let result = [];
    for (let i = 0; i < enums.length; i++) {
      result.push(enums[i].value);
    }
    return result;
  };
  let findTextsInEnumByValues = (enums, values) => {
    let result = [];
    values.forEach((item) => {
      result.push(findItemInEnums(enums, item).text);
    });
    return result;
  };

  let setLocalstore = (k, v) => {
    localStorage.setItem(
      k,
      JSON.stringify({
        ts: new Date().getTime(),
        ver: "1.0",
        v: v,
      })
    );
  };
  let getLocalstore = (k, expDurMs = -1, def = null) => {
    let wrapedStr = localStorage.getItem(k);
    if (wrapedStr == null) return def; //找不到

    let wrapedV = JSON.parse(wrapedStr);

    if (expDurMs < 0) {
      //永不过期
      return wrapedV.v;
    }

    if (new Date().getTime() - wrapedV.ts >= expDurMs) {
      //过期
      return def;
    }

    return wrapedV.v;
  };

  let judgeIsMobileMode = (vue) => {
    switch (vue.$vuetify.breakpoint.name) {
      case "xs":
      case "sm":
        return true;
      case "md":
      case "lg":
      case "xl":
        return false;
      default:
        return false;
    }
  };

  //全角转半角
  function ToCDB(str) {
    var tmp = "";
    for (var i = 0; i < str.length; i++) {
      if (str.charCodeAt(i) > 65248 && str.charCodeAt(i) < 65375) {
        tmp += String.fromCharCode(str.charCodeAt(i) - 65248);
      } else {
        tmp += String.fromCharCode(str.charCodeAt(i));
      }
    }
    return tmp;
  }
  // 半角转全角
  function ToDBC(txtstring) {
    var tmp = "";
    for (var i = 0; i < txtstring.length; i++) {
      if (txtstring.charCodeAt(i) == 32) {
        tmp = tmp + String.fromCharCode(12288);
      }
      if (txtstring.charCodeAt(i) < 127) {
        tmp = tmp + String.fromCharCode(txtstring.charCodeAt(i) + 65248);
      }
    }
    return tmp;
  }

  function  isCanCheckInfo(){
    let isFollowed = getLocalstore('isFollowed')
    if(isFollowed != true){
      let checkCount = getLocalstore('checkCount',-1,0)
      if(checkCount && checkCount>4){
          return false
      }
      setLocalstore('checkCount',checkCount+1)
    }
    return true
    
    
  }

  
  let entity = {
    travelJson,
    deepCopy,
    shallowCopy,
    getQueryString,
    getItemPropInObjArr,
    fireDownloadCsv,
    fireDownloadFile,
    getSubObjectArr,
    genFormDefsByApiData,
    genFormDefsByFilter,
    setLocalstore,
    getLocalstore,

    findItemInEnums,
    findAllValueInEnums,
    findTextsInEnumByValues,

    judgeIsMobileMode,
    ToCDB,
    isCanCheckInfo,
  };

  return entity;
})();
export default CMN;
