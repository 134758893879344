<template>
  <div>
    <v-img
      src="../../public/images/ProductConfToastOpenIcon.png"
      :id="isMobileMode ? 'tab-open-mobile' : 'tab-open'"
      @click="click()"
      v-if="!value"
    ></v-img>
    <v-img
      src="../../public/images/ProductConfToastCloseIcon.png"
      id="tab-close"
      @click="click()"
      height="180px"
      width="26px"
      v-else-if="value && !isMobileMode"
    ></v-img>
  </div>
</template>
<script>
export default {
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    isMobileMode: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    click() {
      this.$emit("input", !this.value);
    },
  },
};
</script>

<style type="text/css">
#tab-open {
  height: 180px;
  width: 33px;
  cursor: pointer;
  position: absolute;
  left: 0%;
  top: 15%;
  /* transform: translate(0%, -50%); */
  z-index: 99;
}
#tab-close {
  /* height: 210px;
  width: 33px; */
  cursor: pointer;
  position: absolute;
  left: 0%;
  top: 15%;
  /* transform: translate(0%, -50%); */
  z-index: 99;
}

#tab-open-mobile {
  height: 180px;
  width: 33px;
  cursor: pointer;
  position: fixed;
  left: 0%;
  top: 50%;
  transform: translate(0%, -50%);
  z-index: 99;
}
</style>
