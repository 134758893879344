import Decimal from "decimal.js"
import {BaseParser} from './PriceInfoParser'
import { AliyunDisk, AliyunEip, DimPriceInfo, Ecs, PayMethod, PriceInfo, PricePayTimeGranularity, PricePayType, ProductCate, SpecProduct, Vendor } from "./model"

import {PRODUCT_MANAGERS, ProductManager_Vm} from './ProductManger'


export class Parser4Ecs extends BaseParser{
    declare productManager:ProductManager_Vm
    priceInfos:any={}
    specProducts:Array<Ecs>=[]


    init(rawInfo:Array<any>): void {
        this.specProducts=[]
        rawInfo.forEach((item)=>{
            try{
                let product=this.parseFromJson(item)
                if(!product){
                    console.log('eeee',item);
                    
                    return
                }
                this.priceInfos[this.getPk(product)]=item
                this.specProducts.push(product)
            }catch(e){
                console.log('解析出错',e)
            }
        })
        
    }

    calPrice(specProduct: Ecs, payMethod:PayMethod): PriceInfo {
        let rawPriceInfo=this.priceInfos[this.getPk(specProduct)]

        let dim=new DimPriceInfo()
        dim.payMethod=payMethod
        dim.dim='ECS:计算资源费用'
        if(payMethod.payType==PricePayType.PrevPay){
            let monthlyPrice=new Decimal(rawPriceInfo.monthlyPrice)
            dim.unitName='元/月'
            dim.unitPrice=monthlyPrice
            dim.unitCnt=payMethod.granType/(24*30)*payMethod.granCnt
            dim.oriPrice=dim.unitPrice.mul(dim.unitCnt)

            let yearlyPrices=[
                new Decimal(rawPriceInfo.yearlyPrice),
                new Decimal(rawPriceInfo['2yearPrice']),
                new Decimal(rawPriceInfo['3yearPrice']),
                new Decimal(rawPriceInfo['4yearPrice']),
                new Decimal(rawPriceInfo['5yearPrice'])]
            switch(payMethod.granType){
                case PricePayTimeGranularity.Year:
                    if(payMethod.granCnt<=5)
                        dim.finPrice=yearlyPrices[payMethod.granCnt-1]
                    else//第6年开始 每年的价格为:5年价格-4年价格
                        dim.finPrice=yearlyPrices[4].add(yearlyPrices[4].minus(yearlyPrices[3]).mul(new Decimal(payMethod.granCnt-5)))
                    break
                case PricePayTimeGranularity.Month:
                    dim.finPrice=monthlyPrice.mul(payMethod.granCnt)
            }
        }else{
            if(rawPriceInfo.hourlyPrice == null || rawPriceInfo.hourlyPrice == undefined)
                return null
            dim.unitName='元/小时'
            dim.unitPrice=new Decimal(rawPriceInfo.hourlyPrice)
            dim.unitCnt=payMethod.granType*payMethod.granCnt
            dim.finPrice=dim.oriPrice=dim.unitPrice.mul(dim.unitCnt)
        }

        let priceInfo=new PriceInfo()
        priceInfo.dims.push(dim)
// debugger
        if(specProduct.bandwidth>0){
            let eip=specProduct.ipTypes[specProduct.selectedIpType]
            eip.bandwidth=specProduct.bandwidth
            let eipPriceInfo=PRODUCT_MANAGERS!.get(ProductCate.IP)!.calPrice(eip,payMethod)
            priceInfo.dims=priceInfo.dims.concat(eipPriceInfo.dims)
        }
        if(specProduct.sysDiskSize>0){
            let disk=specProduct.sysDiskTypes[specProduct.selectedSysDiskType]
            disk.size=specProduct.sysDiskSize
            let diskPriceInfo=PRODUCT_MANAGERS!.get(ProductCate.DISK)!.calPrice(disk,payMethod)
            priceInfo.dims=priceInfo.dims.concat(diskPriceInfo.dims)
        }
        if(specProduct.datDiskSize>0){
            let disk=specProduct.datDiskTypes[specProduct.selectedDatDiskType]
            disk.size=specProduct.datDiskSize
            let diskPriceInfo=PRODUCT_MANAGERS!.get(ProductCate.DISK)!.calPrice(disk,payMethod)
            priceInfo.dims=priceInfo.dims.concat(diskPriceInfo.dims)
        }

        priceInfo.dims.forEach(dim=>{
            priceInfo.finPrice=priceInfo.finPrice.add(dim.finPrice)
            priceInfo.oriPrice=priceInfo.oriPrice.add(dim.oriPrice)
        })

        specProduct.price=priceInfo
        return priceInfo
    }

    parseFromJson(jsonO: any): Ecs|null {
        //{
//   id : 1, 
//   "region" : "cn-heyuan",
//   "system" : "windows",
//   "network" : "vpc",
//   "isIO" : "true",
//   "instanceId" : "ecs.s6-c1m1.small",
//   "vCpu" : 1,
//   "memory" : 1,
//   "intranetGbps" : 0.1,
//   "intranetPPS" : 15,
//   "hourlyPrice" : "0.11",
//   "weeklyPrice" : "9.23",
//   "standard" : "33.0",
//   "monthlyPrice" : "33.0",
//   "yearlyPrice" : "336.6",
//   "2yearPrice" : "554.4",
//   "3yearPrice" : "653.4",
//   "4yearPrice" : "712.8",
//   "5yearPrice" : "752.4",
//   tags : [1,2,3,4]
//  }

        let inst:Ecs=new Ecs()
        inst.cpu=jsonO.vCpu
        inst.mem=jsonO.memory
        // inst.windows=jsonO.system=="windows"
        inst.tags=jsonO.tags
        inst.model=jsonO.instanceId
        inst.region=jsonO.region

        inst.area=this.productManager.area
        inst.bandwidth=this.productManager.bandwidth
        inst.sysDiskSize=this.productManager.sysDiskSize
        inst.datDiskSize=this.productManager.datDiskSize
        
        if(inst.sysDiskSize>0 || inst.datDiskSize>0){
            inst.sysDiskTypes=inst.datDiskTypes=PRODUCT_MANAGERS.get(ProductCate.DISK)?.filterProductsInVendor(inst.vendor,new Map([['region',inst.region]]))
            if(!inst.sysDiskTypes || inst.sysDiskTypes.length<=0) return null//没可使用的硬盘 vm实例也不显示
        }

        if(inst.bandwidth>0){
            inst.ipTypes=PRODUCT_MANAGERS.get(ProductCate.IP)?.filterProductsInVendor(inst.vendor,new Map([['region',inst.region]]))
            if(!inst.ipTypes || inst.ipTypes.length<=0) return null//没可使用的公网 vm也不显示
        }

        return inst
    }
    
}
export class Parser4AliyunDisk extends BaseParser{
    priceInfos:any={}
    specProducts:Array<AliyunDisk>=[]

    calPrice(specProduct: AliyunDisk, payMethod:PayMethod): PriceInfo {
        let rawPriceInfo=this.priceInfos[this.getPk(specProduct)]

        let dim=new DimPriceInfo()
        dim.payMethod=payMethod
        dim.dim='云盘:存储费用'
        if(payMethod.payType==PricePayType.PrevPay){
            dim.unitName='元/(GB*月)'
            dim.unitPrice=new Decimal(rawPriceInfo.monthlyPrice)
            dim.unitCnt=specProduct.size*(payMethod.granType/(24*30)*payMethod.granCnt)
            dim.oriPrice=dim.unitPrice.mul(dim.unitCnt)

            let discount=1
            if(payMethod.granType==PricePayTimeGranularity.Year){
                if(payMethod.granCnt==1){
                    discount = 0.85
                }else if(payMethod.granCnt==2){
                    discount = 0.7
                }else if(payMethod.granCnt>2){
                    discount=0.5
                }
            }
            dim.finPrice=dim.oriPrice.mul(discount)
        }else{
            dim.unitName='元/(GB*小时)'
            dim.unitPrice=new Decimal(rawPriceInfo.volumePrice)
            dim.unitCnt=specProduct.size*(payMethod.granType*payMethod.granCnt)
            dim.finPrice=dim.oriPrice=dim.unitPrice.mul(dim.unitCnt)
        }

        let priceInfo=new PriceInfo()
        priceInfo.dims=[dim]
        priceInfo.finPrice=dim.finPrice
        priceInfo.oriPrice=dim.oriPrice

        specProduct.price=priceInfo
        return priceInfo
    }
    parseFromJson(jsonO: any): AliyunDisk {
        let inst:AliyunDisk=new AliyunDisk()
        inst.tags=jsonO.tags
        inst.model=jsonO.type
        inst.region=jsonO.region
        return inst
    }
    
}

export class Parser4AliyunIp extends BaseParser{
    priceInfos:any={}
    specProducts:Array<AliyunEip>=[]

    calPrice(specProduct: AliyunEip, payMethod:PayMethod): PriceInfo {
        let rawPriceInfo=this.priceInfos[this.getPk(specProduct)]

        let dim=new DimPriceInfo()
        dim.payMethod=payMethod
        if(payMethod.payType==PricePayType.PrevPay){
            dim.dim='公网IP:带宽费用'
            let monthlyPriceOfBandwidth = [
                new Decimal(rawPriceInfo["1Month"]),
                new Decimal(rawPriceInfo["2Month"]),
                new Decimal(rawPriceInfo["3Month"]),
                new Decimal(rawPriceInfo["4Month"]),
                new Decimal(rawPriceInfo["5Month"]),
                new Decimal(rawPriceInfo["6Month"])
            ]

            dim.unitName=`元/(${specProduct.bandwidth}Mb*月)`
            dim.unitCnt=payMethod.granType/(24*30)*payMethod.granCnt
            if (specProduct.bandwidth < 6){
                dim.unitPrice=new Decimal(monthlyPriceOfBandwidth[specProduct.bandwidth-1])
            }else {
                dim.unitPrice=monthlyPriceOfBandwidth[4].add(monthlyPriceOfBandwidth[5].minus(monthlyPriceOfBandwidth[4]).mul(specProduct.bandwidth - 5))
            }
            dim.oriPrice=dim.unitPrice.mul(dim.unitCnt)

            let discount=1
            if(payMethod.granType==PricePayTimeGranularity.Year){
                discount = 0.85
            }
            dim.finPrice=dim.oriPrice.mul(discount)
        }else{
            dim.dim='公网IP:流量费用'
            dim.unitName='元/GB'
            dim.unitPrice=new Decimal(rawPriceInfo['1Gb'])
            dim.unitCnt=0//TODO 目前只显示单价 specProduct.flowSize
            dim.finPrice=dim.oriPrice=dim.unitPrice.mul(dim.unitCnt)
        }

        let priceInfo=new PriceInfo()
        priceInfo.dims=[dim]
        priceInfo.finPrice=dim.finPrice
        priceInfo.oriPrice=dim.oriPrice

        specProduct.price=priceInfo
        return priceInfo
    }
    parseFromJson(jsonO: any): AliyunEip {
        let inst=new AliyunEip()
        inst.tags=jsonO.tags
        inst.model='多线BGP'
        inst.region=jsonO.region
        return inst
    }
    
}

