import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';
import zhHans from 'vuetify/src/locale/zh-Hans';
import light from './vuetify/theme'

Vue.use(Vuetify);

export default new Vuetify({
    lang: {
      locales: { zhHans },
      current: 'zhHans',
    },
    theme: { 
      themes: { light },
      options: { customProperties: true }, //<style>可以引用主题变量
    },
});
