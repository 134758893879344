import Vue from 'vue';

export enum NotifyLevel {
    INFO='信息',
    SUCC='成功',
    WARN='警告',
    ERROR='错误'
}

export enum LocalstoreKey {
    CONF_LIST='CONF_LIST_V2',
    CART_LIST='CART_LIST_V2',
}
class IvcApp{
    notifyVue:any

    setNotifyVue(vue:Vue){
        this.notifyVue=vue        
    }

    showNotify(msg:string, level=NotifyLevel.INFO){
        this.notifyVue.showNotify(msg,level);
    }

}
const _IVC_APP=new IvcApp()

export function install(){
    Object.defineProperty(Vue.prototype, '$ivcApp', {
        get () { return _IVC_APP }
    })
}

// export default class IvcVue extends Vue{
//     readonly $ivcApp=_IVC_APP
// }

//加类型声明, 为了ts代码提示
declare module 'vue/types/vue' {
    interface Vue {
      $ivcApp: IvcApp
    }
}