import Vue from 'vue'
import App from './App.vue'
import router from './router'
import vuetify from './plugins/vuetify'


Vue.config.productionTip = false

import * as IvcVue from './cmn/IvcVue'
IvcVue.install()
// Vue.prototype.$app=app

new Vue({
  router,
  vuetify,
  render: h => h(App)
}).$mount('#app')
