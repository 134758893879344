import CMN from './common'
let TAGS = (() => {
    let region=[// 001
        {
            text:'亚太',value:'/001/001',
            children:[{
                text:'中国', value:'/001/001/001',
                children:[{
                    text:'华南', value:'/001/001/001/001'
                },{
                    text:'华东', value:'/001/001/001/002'
                },{
                    text:'华北', value:'/001/001/001/003'
                },{
                    text:'华中', value:'/001/001/001/004'
                },{
                    text:'东北', value:'/001/001/001/005'
                },{
                    text:'西南', value:'/001/001/001/006'
                },{
                    text:'西北', value:'/001/001/001/007'
                },{
                    text:'港澳台', value:'/001/001/001/008'
                },]
            },{
                text:'新加坡', value:'/001/001/002'
            },{
                text:'泰国', value:'/001/001/003'
            },{
                text:'日本', value:'/001/001/004'
            },{
                text:'韩国', value:'/001/001/005'
            },{
                text:'印度尼西亚', value:'/001/001/006'
            },{
                text:'澳大利亚', value:'/001/001/007'
            },{
                text:'马来西亚', value:'/001/001/008'
            },{
                text:'印度', value:'/001/001/009'
            },
            {text: '菲律宾', value: '/001/001/010'},
        ]
        },
        {
            text:'美洲',value:'/001/002',
            children:[{
                text:'美国', value:'/001/002/001'
            }]
        },
        {
            text:'欧洲',value:'/001/003',
            children:[{
                text:'法国', value:'/001/003/001'
            },{
                text:'俄国', value:'/001/003/002'
            },{
                text:'英国', value:'/001/003/003'
            },{
                text:'德国', value:'/001/003/004'
            }]
        },
        {
            text:'中东',value:'/001/004',
            children:[{
                text:'迪拜', value:'/001/004/001'
            }]
        },
        {
            text:'美洲',value:'/001/005',
            children:[{
                text:'美国', value:'/001/005/001'
            }]
        }
    ]

    let allFinalRegion=[]
    CMN.travelJson(region,(o,level,parent)=>{
        try{
            if(o instanceof Array){}
            else if(o instanceof Object){
                if(o.children && o.children.length>0) return
                allFinalRegion.push(o)
                return true
            }else{}
        }catch{}
    })

    let getAreaTags=(area)=>{
        if(!area) return []
        let tags = []

        allFinalRegion.forEach((o)=>{
            if(area == '国外'){
                if(!o.value.startsWith('/001/001/001'))
                    tags.push(o.value)
            }else{
                if(area == o.text){
                    tags.push(o.value)
                    
                }
            }
        })
        return tags
    }

    return {
        region,
        allFinalRegion,
        vmType:[//虚机类型: /002
            {text:'入门型',value:'/002/001'},
            {text:'突发型',value:'/002/002'},
            {text:'共享型',value:'/002/003'},
            {text:'独享型',value:'/002/004'},
            {text:'企业型',value:'/002/005'},
            {text:'内存型',value:'/002/006'},
            {text:'计算型',value:'/002/007'},
            {text:'网络型',value:'/002/008'},
            {text:'本地盘型',value:'/002/009'},
        ],
        diskType:[//磁盘类型 /003
            {text:'普通云盘',value:'/003/001'},
            {text:'高速云盘',value:'/003/002'},
            {text:'SSD云盘',value:'/003/003'},
            {text:'增强SSD云盘',value:'/003/004'},
        ],
        getAreaTags,
    }
})()
export default TAGS