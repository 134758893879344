import Decimal from "decimal.js"
import {BaseParser, BaseParser4Os} from './PriceInfoParser'
import { DimPriceInfo,AliyunOs,PayMethod, PriceInfo, PricePayTimeGranularity, PricePayType,ProductCate, AliyunOsPackage, SpecProduct_OsPackage, SpecProduct_Os} from "./model"

import {PRODUCT_MANAGERS, ProductManager_Os, ProductManager_OsPackage} from './ProductManger'
import CMN from "../cmn/js/common";


export class Parser4AliyunOs extends BaseParser4Os{
    declare productManager:ProductManager_Os
    priceInfos:any={}
    specProducts:Array<AliyunOs>=[]

    init(rawInfo:Array<any>): void {
        this.specProducts=[]
        rawInfo.forEach((item)=>{
            try{
                let product=this.parseFromJson(item)
                if(!product) return
                this.priceInfos[this.getPk(product)]=item
                this.specProducts.push(product)
            }catch(e){
                console.log('解析出错',e)
            }
        })
        
    }

    getPk(specProduct:SpecProduct):string{
        return specProduct.region+specProduct.model+specProduct.subType
    }


    calPrice(specProduct: AliyunOs, payMethod:PayMethod): PriceInfo {
        let rawPriceInfo=this.priceInfos[this.getPk(specProduct)]
        let priceInfo=new PriceInfo() 
        if(payMethod.payType==PricePayType.PrevPay){

            if(specProduct.osStorePackages.length == 0 || specProduct.osFlowPackages.length == 0
                || this.productManager.osStoreSize<=0 || this.productManager.osFlowSize<=0 ){
                    return null
                }
            //计算存储包
            let priceInfoTemp = this.computePackage(specProduct.osStoreSize,payMethod,specProduct.osStorePackages)
            if(!priceInfoTemp) return null
            priceInfo.dims = priceInfoTemp.dims    

            specProduct.osStoreTxt = ''
            priceInfoTemp.dims.forEach(dim=>{
                specProduct.osStoreTxt += `【${dim.dim.replace('AliyunOs:','')}】x ${dim.unitCnt}\n`
            }) 

            //计算流量包
            priceInfoTemp = this.computePackage(specProduct.osFlowSize, payMethod,specProduct.osFlowPackages)
            if(!priceInfoTemp) return null
            priceInfo.dims.concat(priceInfoTemp.dims)         
            

            specProduct.osFlowTxt = ''
            priceInfoTemp.dims.forEach(dim=>{
                specProduct.osFlowTxt += `【${dim.dim.replace('AliyunOs:','')}】x ${dim.unitCnt}\n`
            }) 

           
        }else{
            let dim = new DimPriceInfo()
            dim.payMethod = payMethod
            dim.dim='AliyunOs:存储费用'

            if(rawPriceInfo.price == null){
                if(rawPriceInfo.monthLte5GBPrice == null || rawPriceInfo.monthGt5GBPrice == null){
                    return null   
                }else{
                    let monthLte5GBPrice =  new Decimal(rawPriceInfo.monthLte5GBPrice)
                    let monthGt5GBPrice =  new Decimal(rawPriceInfo.monthGt5GBPrice)
                    dim.unitPrice = monthLte5GBPrice.mul(5)
                    if(specProduct.osStoreSize-5>0){
                        dim.unitPrice.add(monthGt5GBPrice.mul(specProduct.osStoreSize-5))
                    }
                }
            }else{
                dim.unitPrice = new Decimal(rawPriceInfo.price).mul(specProduct.osStoreSize)
            }
            
            dim.unitName=rawPriceInfo.priceUnit
            dim.unitCnt=payMethod.granType/PricePayTimeGranularity.Month*payMethod.granCnt
            dim.finPrice=dim.oriPrice=dim.unitPrice.mul(dim.unitCnt)
            priceInfo.dims.push(dim)

            //计算流量
            if(specProduct.osFlowSize>0){
                let dim=new DimPriceInfo()
                dim.payMethod=payMethod
                dim.dim='AliyunOs:流量费用'
                if(rawPriceInfo.priceFlow == null){
                   
                    if(rawPriceInfo.priceLte5GBFlow == null){
                        return null
                    }else{
                        let priceLte5GBFlow = new Decimal(rawPriceInfo.priceLte5GBFlow)
                        let price5GBTo10TBFlow = new Decimal(rawPriceInfo.price5GBTo10TBFlow)
                        let price10TBTo50TBFlow= new Decimal(rawPriceInfo.price10TBTo50TBFlow)
                        let price50TBTo150TBFlow= new Decimal(rawPriceInfo.price50TBTo150TBFlow)
                        let priceGt150TBFlow = new Decimal(rawPriceInfo.priceGt150TBFlow)
                        // 0-5GB(含)部分'
                        if(specProduct.osFlowSize<=5){
                            dim.unitPrice = new Decimal(rawPriceInfo.priceLte5GBFlow).mul(specProduct.osFlowSize)
                        }
                         //'5GB-10TB(含)部分',
                        if(specProduct.osFlowSize>5 && specProduct.osFlowSize<= 1024*10){
                            dim.unitPrice = priceLte5GBFlow.mul(5).add(price5GBTo10TBFlow.mul(specProduct.osFlowSize-5))
                        }
                       //'10TB-50TB(含)部分',
                        if(specProduct.osFlowSize>1024*10 && specProduct.osFlowSize<= 1024*50){
                            dim.unitPrice = priceLte5GBFlow.mul(5)
                            dim.unitPrice.add(price5GBTo10TBFlow.mul(1024*10))
                            dim.unitPrice.add(price10TBTo50TBFlow.mul(specProduct.osFlowSize-1024*10))
                        }
                        //50TB-150TB(含)部分'
                        if(specProduct.osFlowSize>1024*50 && specProduct.osFlowSize<= 1024*150){
                            dim.unitPrice = priceLte5GBFlow.mul(5)
                            dim.unitPrice.add(price5GBTo10TBFlow.mul(1024*10))
                            dim.unitPrice.add(price10TBTo50TBFlow.mul(1024*50))
                            dim.unitPrice.add(price50TBTo150TBFlow.mul(specProduct.osFlowSize-1024*50))
                        }
                        // '150TB以上部分'
                        if(specProduct.osFlowSize>1024*150){
                            dim.unitPrice = priceLte5GBFlow.mul(5)
                            dim.unitPrice.add(price5GBTo10TBFlow.mul(1024*10))
                            dim.unitPrice.add(price10TBTo50TBFlow.mul(1024*50))
                            dim.unitPrice.add(price50TBTo150TBFlow.mul(1024*150))
                            dim.unitPrice.add(priceGt150TBFlow.mul(specProduct.osFlowSize-1024*150))
                        }
                    }
                }else{
                    dim.unitPrice = new Decimal(rawPriceInfo.priceFlow).mul(specProduct.osFlowSize)
                }

                dim.unitName= rawPriceInfo.priceUnitFlow
                dim.unitCnt=payMethod.granType/PricePayTimeGranularity.Month*payMethod.granCnt
                dim.finPrice=dim.oriPrice=dim.unitPrice.mul(dim.unitCnt)
                priceInfo.dims.push(dim)

            }

        }

        priceInfo.dims.forEach(dim=>{
            priceInfo.finPrice=priceInfo.finPrice.add(dim.finPrice)
            priceInfo.oriPrice=priceInfo.oriPrice.add(dim.oriPrice)
        })

        specProduct.price=priceInfo
        return priceInfo
    }

    parseFromJson(jsonO: any): AliyunOs|null {
        let inst:AliyunOs = new AliyunOs()
        inst.region=jsonO.region
        inst.osType = jsonO.storageType
        inst.subType = jsonO.billingSubItem
        inst.tags=jsonO.tags
        inst.model=inst.osType
        inst.area=this.productManager.area
        inst.osStoreSize=this.productManager.osStoreSize
        inst.osFlowSize=this.productManager.osFlowSize 

        let regionTag = null
        try {
            regionTag = inst.tags.filter(tag=> tag.startsWith('/001/001/001'))[0]
        } catch (error) {
            console.log(error);
        }
        

        if(inst.osStoreSize>0){
            let resourceType = ''

            if(inst.osType.indexOf('标准')>-1){
                if(inst.subType.indexOf('本地')>-1){
                    resourceType = '标准(LRS)存储包'
                }
                if(inst.subType.indexOf('同城')>-1){
                    resourceType = '标准(ZRS)存储包'
                }
                
            }else if(inst.osType.indexOf('低频')>-1){
                if(inst.subType.indexOf('本地')>-1){
                    resourceType = '低频(LRS)存储包'
                }
                if(inst.subType.indexOf('同城')>-1){
                    resourceType = '低频(ZRS)存储包'

                }
            }else if(inst.osType == '归档型'){
                resourceType = '归档(LRS)存储包'
            }else if(inst.osType == '冷归档型'){
                resourceType = '无'
            }


            inst.osStorePackages = PRODUCT_MANAGERS.get(ProductCate.OS_PACKAGE)?.filterProductsInVendor(inst.vendor,new Map([['regionTag',regionTag],['resourceType',resourceType]]))
            
            //优先使用中国大陆通用包
            if(inst.osStorePackages && inst.osStorePackages.length>0){
                let temp =  inst.osStorePackages.filter(p =>  p.region == '中国大陆通用' )
                if(temp && temp.length>0){
                    inst.osStorePackages = temp
                }
            }
            
            if(!inst.osStorePackages) return null//没可使用的资源包，不显示实例

        }

        if(inst.osFlowSize>0){

            inst.osFlowPackages = PRODUCT_MANAGERS.get(ProductCate.OS_PACKAGE)?.filterProductsInVendor(inst.vendor,new Map([['regionTag',regionTag],['resourceType','下行流量包']]))
            
           //优先使用中国大陆通用包
           if(inst.osFlowPackages && inst.osFlowPackages.length>0){
                let temp =  inst.osFlowPackages.filter(p =>  p.region == '中国大陆通用' )
                if(temp && temp.length>0){
                    inst.osFlowPackages = temp
                }
            }
            if(!inst.osFlowPackages) return null//没可使用的资源包，不显示实例
        }

        return inst
    }
    
}


export class Parser4AliyunOsPackage extends BaseParser{
    declare productManager:ProductManager_OsPackage
    priceInfos:any={}
    specProducts:Array<AliyunOsPackage>=[]

    init(rawInfo:Array<any>): void {
        this.specProducts=[]
        rawInfo.forEach((item)=>{
            try{
                let product=this.parseFromJson(item)
                if(!product) return
                this.priceInfos[this.getPk(product)]=item
                this.specProducts.push(product)
            }catch(e){
                console.log('解析出错',e)
            }
        })
        
    }

    getPk(specProduct:SpecProduct):string{
        return specProduct.region+specProduct.model+specProduct.spec + specProduct.duration
    }

    calPrice(specProduct: AliyunOsPackage, payMethod:PayMethod): PriceInfo {
        let rawPriceInfo=this.priceInfos[this.getPk(specProduct)]
        let priceInfo=new PriceInfo()        
        let dim=new DimPriceInfo()
        dim.payMethod=payMethod
        dim.unitPrice=dim.finPrice=dim.oriPrice = new Decimal(rawPriceInfo.price)
        dim.unitCnt = 1
        dim.unitName = '元'
        priceInfo.dims.push(dim)

        priceInfo.dims.forEach(dim=>{
            priceInfo.finPrice=priceInfo.finPrice.add(dim.finPrice)
            priceInfo.oriPrice=priceInfo.oriPrice.add(dim.oriPrice)
        })

        specProduct.price=priceInfo
        return priceInfo
    }

    parseFromJson(jsonO: any): AliyunOsPackage|null {
        let inst:AliyunOsPackage = new AliyunOsPackage()
        inst.region=jsonO.region
        inst.resourceType = jsonO.resourceType
        inst.spec = jsonO.spec
        inst.duration = jsonO.duration
        inst.tags=jsonO.tags
        inst.model=inst.resourceType
        inst.area=this.productManager.area

        return inst
    }
    
}

