let ENUMS = (() => {
  return {
    CPU: [
      {
        value: 1,
        text: "1核",
      },
      {
        value: 2,
        text: "2核",
      },
      {
        value: 4,
        text: "4核",
      },
      {
        value: 8,
        text: "8核",
      },
      {
        value: 12,
        text: "12核",
      },
      {
        value: 16,
        text: "16核",
      },
      {
        value: 24,
        text: "24核",
      },
      {
        value: 32,
        text: "32核",
      },
      {
        value: 48,
        text: "48核",
      },
      {
        value: 64,
        text: "64核",
      },
      {
        value: 96,
        text: "96核",
      },
      {
        value: 128,
        text: "128核",
      },
    ],
    MEM: [
      {
        value: 0.5,
        text: "0.5",
      },
      {
        value: 1,
        text: "1G",
      },
      {
        value: 2,
        text: "2G",
      },
      {
        value: 4,
        text: "4G",
      },
      {
        value: 8,
        text: "8G",
      },
      {
        value: 12,
        text: "12G",
      },
      {
        value: 16,
        text: "16G",
      },
      {
        value: 24,
        text: "24G",
      },
      {
        value: 32,
        text: "32G",
      },
      {
        value: 48,
        text: "48G",
      },
      {
        value: 64,
        text: "64G",
      },
      {
        value: 96,
        text: "96G",
      },
      {
        value: 128,
        text: "128G",
      },
      {
        value: 256,
        text: "256G",
      },
      {
        value: 512,
        text: "512G",
      },
    ],
    IP: [
      {
        value: 0,
        text: "不需要",
      },
      {
        value: 1,
        text: "1M",
      },
      {
        value: 2,
        text: "2M",
      },
      {
        value: 3,
        text: "3M",
      },
      {
        value: 5,
        text: "5M",
      },
      {
        value: 10,
        text: "10M",
      },
      {
        value: 20,
        text: "20M",
      },
      {
        value: 30,
        text: "30M",
      },
      {
        value: 50,
        text: "50M",
      },
      {
        value: 100,
        text: "100M",
      },
      {
        value: 200,
        text: "200M",
      },
    ],
    DISK_TYPE: [
      {
        value: 0,
        text: "标准性能",
      },
      {
        value: 1,
        text: "高性能",
      },
    ],
    DISK_SPACE: [
      {
        value: 0,
        text: "不需要",
      },
      {
        value: 40,
        text: "40G",
      },
      {
        value: 100,
        text: "100G",
      },
      {
        value: 200,
        text: "200G",
      },
      {
        value: 300,
        text: "300G",
      },
      {
        value: 500,
        text: "500G",
      },
      {
        value: 1024,
        text: "1TB",
      },
      {
        value: 2048,
        text: "2TB",
      },
    ],
    SCENE: [
      "企业内部系统",
      "交易类网站",
      "非交易类网站",
      "游戏",
      "教育",
      "直播/点播",
      "物联网IoT",
      "数据分析",
      "容器/K8S",
    ],

    WINDOWS: [
      {
        value: false,
        text: "否",
      },
      {
        value: true,
        text: "是",
      },
    ],

    action: {
      add: {
        value: 0,
        title: "添加实例",
      },
      edit: {
        value: 1,
        title: "修改实例",
      },
      remove: {
        value: 2,
        title: "删除实例",
      },
    },

    PRODUCK_CATE: [
      { v: "全部", t: "全部" },
      { v: "弹性计算", t: "弹性计算" },
      { v: "数据库", t: "数据库" },
      { v: "安全", t: "安全" },
      { v: "网络", t: "网络" },
      { v: "CDN", t: "CDN" },
      { v: "AI", t: "AI" },
      { v: "点播/直播", t: "点播/直播" },
      { v: "应用中间件", t: "应用中间件" },
      { v: "大数据", t: "大数据" },
      { v: "物联网", t: "物联网" },
      { v: "云通讯", t: "云通讯" },
      { v: "其他", t: "其他" },
    ],

    SPECIAL_OFFER_STATUS: {
      noStart: { colour: "yellow" },
      ongoing: { colour: "green" },
      ended: { colour: "gray" },
    },
    DB_ENGINE: [
      {
        value: "MySQL",
        text: "MySQL",
        versions: [
          { text: "8.0", value: "8" },
          { text: "5.7", value: "5.7" },
          { text: "5.6", value: "5.6" },
          { text: "5.5", value: "5.5" },
        ],
      },
      {
        value: "SQLServer",
        text: "Microsoft SQL Server",
        versions: [
          { text: "2019版", value: "2019" },
          { text: "2017版", value: "2017" },
          { text: "2016版", value: "2016" },
          { text: "2014版", value: "2014" },
          { text: "2012版", value: "2012" },
          { text: "2008R2版", value: "2008R2" },
        ],
      },
      {
        value: "PostgreSQL",
        text: "PostgreSQL",
        versions: [
          { text: "13", value: "13" },
          { text: "12.4", value: "12.4" },
          { text: "12", value: "12" },
          { text: "11.8", value: "11.8" },
          { text: "11", value: "11" },
          { text: "10", value: "10" },
          { text: "9.6", value: "9.6" },
          { text: "9.5", value: "9.5" },
          { text: "9.4", value: "9.4" },
        ],
      },
    ],

    AREA_4_TABS: [
      {
        text: "华南",
        value: "华南",
      },
      {
        text: "华东",
        value: "华东",
      },
      {
        text: "华北",
        value: "华北",
      },
      {
        text: "华中",
        value: "华中",
      },
      {
        text: "东北",
        value: "东北",
      },
      {
        text: "西南",
        value: "西南",
      },
      {
        text: "西北",
        value: "西北",
      },
      {
        text: "港澳台",
        value: "港澳台",
      },
      {
        text: "国外",
        value: "国外",
      },
    ],
  };
})();
export default ENUMS;
