// src/plugins/vuetify/theme.js

import colors from "vuetify/lib/util/colors";

export default {
  primary: "#007AFF",
  error: colors.red,
  // success: colors.orange,
  // 自定义的 颜色
  bg: "#E8EFFA",
  bg2: "#E7EEF9",
  bg3: "#F3F9FF",
  bg4: "#F3F3F3",
  bg5: "#FE6549",
  bg6: "#FFF3DE",
  text_color: "#FE6549",
  text_color2: colors.shades.white,
  text_color3: colors.grey.lighten5,
  text_color4: "#FEA66F",
  text_color5: "#EB695F",
  text_black: "#2F2F2F",
  badge: colors.red,
  selected: colors.indigo,
  bg_white: "#FFFFFD",
  disable: "#969696",
  btn_red_orange: "#EC5861",
};

// {
//   primary: #3f51b5,
//   secondary: #ffc107,
//   accent: #cddc39,
//   error: #8bc34a,
//   warning: #ff5722,
//   info: #673ab7,
//   success: #2196f3
//   }
