


























































































































































































































































































































































































































































































import Vue from "vue";
import { Component, Prop, Watch } from "vue-property-decorator";

import ENUMS from "../cmn/js/enums";
import CMN from "../cmn/js/common";
import TAGS from "../cmn/js/tags";
import {
  PayMethod,
  PriceInfo,
  PricePayType,
  ProductCate,
  SpecProduct,
  SpecProduct_Db,
  SpecProduct_Vm,
  Vendor,
} from "../cmn/model";
import { PRODUCT_MANAGERS } from "@/cmn/ProductManger";

@Component({ components: {} })
export default class ProductBrowser extends Vue {
  @Prop({
    type: Map,
    default: function(): Map<Vendor, Array<SpecProduct>> {
      return new Map();
    },
  })
  productsByVendor!: Map<Vendor, Array<SpecProduct>>;
  @Prop({
    type: String,
    default: function(): ProductCate {
      return ProductCate.VM;
    },
  })
  productCate!: ProductCate;
  @Prop({
    type: PayMethod,
    default: function(): PayMethod {
      return new PayMethod();
    },
  })
  payMethod!: PayMethod;

  ENUMS = ENUMS;
  TAGS = TAGS;

  step = 1;
  computing = false;
  selectedVendorIdx = 0;
  selectedRegionIdx = 0;

  get headers(): any[] {
    let ret: any[] = [
      { text: "操作", value: "actions", sortable: false, class: "bg4" },
      { text: "价格", value: "price", sortable: false, class: "bg4" },
      {
        text: "产品",
        align: "start",
        value: "name",
        sortable: false,
        class: "bg4",
      },
      {
        text: "型号",
        align: "start",
        value: "model",
        sortable: false,
        class: "bg4",
      },
    ];
    switch (this.productCate) {
      case ProductCate.VM:
        ret = ret.concat([
          { text: "CPU", value: "cpu", sortable: false, class: "bg4" },
          { text: "内存", value: "mem", sortable: false, class: "bg4" },
          {
            text: "系统盘",
            value: "sysDiskSize",
            sortable: false,
            class: "bg4",
          },
          {
            text: "数据盘",
            value: "datDiskSize",
            sortable: false,
            class: "bg4",
          },
          { text: "带宽", value: "bandwidth", sortable: false, class: "bg4" },
        ]);
        break;
      case ProductCate.DB:
        ret = ret.concat([
          { text: "引擎", value: "engine", sortable: false, class: "bg4" },
          { text: "版本", value: "engineVer", sortable: false, class: "bg4" },
          { text: "数据盘", value: "storeSize", sortable: false, class: "bg4" },
          { text: "架构", value: "archType", sortable: false, class: "bg4" },
          { text: "规格", value: "specType", sortable: false, class: "bg4" },
          { text: "CPU", value: "cpu", sortable: false, class: "bg4" },
          { text: "内存", value: "mem", sortable: false, class: "bg4" },
        ]);
        break;
      case ProductCate.LB:
        ret = ret.concat([
          { text: "类型", value: "lbType", sortable: false, class: "bg4" },
          { text: "规格", value: "specType", sortable: false, class: "bg4" },
          { text: "带宽", value: "bandwidth", sortable: false, class: "bg4" },
        ]);
        break;
      case ProductCate.OS:
        ret = ret.concat([
          { text: "子类型", value: "subType", sortable: false, class: "bg4" },
        ]);
        if (this.payMethod.payType == PricePayType.PrevPay) {
          ret = ret.concat([
            {
              text: "存储大小",
              value: "osStoreTxt",
              sortable: false,
              class: "bg4",
            },
            {
              text: "流量大小",
              value: "osFlowTxt",
              sortable: false,
              class: "bg4",
            },
          ]);
        } else {
          ret = ret.concat([
            {
              text: "存储大小",
              value: "osStoreSize",
              sortable: false,
              class: "bg4",
            },
            {
              text: "流量大小",
              value: "osFlowSize",
              sortable: false,
              class: "bg4",
            },
          ]);
        }

        break;
      case ProductCate.CDN:
        ret = ret.concat([
          {
            text: "流量大小",
            value: "cdnFlowTxt",
            sortable: false,
            class: "bg4",
          },
        ]);
        break;
    }

    return ret;
  }

  mounted() {
    console.log("Product-Browser mounted", this.formatedProductInfo);
  }

  @Watch("productsByVendor", { deep: true })
  onProductsByVendorChanged(n, o) {
    this.selectedVendorIdx = 0;
    this.selectedRegionIdx = 0;
    this.step = 1;
  }

  get isMobileMode() {
    return CMN.judgeIsMobileMode(this);
  }
  get formatedProductInfo() {
    this.computing = true;

    // [
    //   {
    //     name:'aliyun'
    //     最低价:
    //     产品数量:
    //     城市:[{
    //       name: '广州'
    //       最低价:
    //       产品数量:
    //       型号:[ecs1,ecs2,...]
    //     }]
    //   }
    // ]

    let vendorItems: Record<string, any>[] = [];
    try {
      console.debug("update formatedProductInfo...", this.productsByVendor);
      let productsByVendor: Map<Vendor, Array<SpecProduct>> = CMN.deepCopy(
        this.productsByVendor
      );
      productsByVendor.forEach((vendorProducts, vendor) => {
        let curVendorItem = {
          name: vendor,
          floorPrice: 9999999,
          productCnt: 0,
          regions: [],
        };

        //按region分组
        let regionItems: any = {};
        vendorProducts.forEach((product) => {
          let region = regionItems[product.region];
          if (!region) {
            regionItems[product.region] = region = {
              name: product.region,
              floorPrice: 9999999,
              productCnt: 0,
              products: [],
            };
          }

          product.payMethod = this.payMethod;
          //算价钱
          // product.price=PRODUCT_MANAGERS.get(product.cate)!.calPrice(product,this.payMethod)

          try {
            product.price = PRODUCT_MANAGERS.get(product.cate)!.calPrice(
              product,
              this.payMethod
            );
          } catch (error) {
            console.error("calPrice错误：" + error, "product=", product);
            product.price = null;
          }

          if (product.price == null) {
            return;
          }
          //找出本厂商最低价
          if (curVendorItem.floorPrice > product.price.finPrice.toNumber()) {
            curVendorItem.floorPrice = product.price.finPrice.toNumber();
          }
          //找出本region最低价
          if (region.floorPrice > product.price.finPrice.toNumber()) {
            region.floorPrice = product.price.finPrice.toNumber();
          }

          curVendorItem.productCnt += 1;
          region.productCnt += 1;
          region.products.push(product);
        });

        for (let regionName in regionItems) {
          if (regionItems[regionName].productCnt > 0) {
            try {
              //地区下的产品按价格排序
              regionItems[regionName].products.sort((a: any, b: any) => {
                a.price.finPrice.toNumber();
                return (
                  a.price.finPrice.toNumber() - b.price.finPrice.toNumber()
                );
              });
            } catch (error) {
              console.error("地区下的产品按价格排序错误", error);
            }

            curVendorItem.regions.push(regionItems[regionName]);
          }
        }

        //地区按价格排序
        curVendorItem.regions.sort((a: any, b: any) => {
          return a.floorPrice - b.floorPrice;
        });

        if (curVendorItem.productCnt > 0) vendorItems.push(curVendorItem);
      });
      //厂商按价格排序
      vendorItems.sort((a: any, b: any) => {
        return a.floorPrice - b.floorPrice;
      });

      console.debug("formatedProductInfo updated", vendorItems);
      this.$emit("productListUpdated", vendorItems);
      return vendorItems;
    } finally {
      this.computing = false;
    }
  }

  onChangedVmDiskType(vm: SpecProduct_Vm, model: string, sysOrDat = true) {
    if (sysOrDat) {
      for (let i = 0; i < vm.sysDiskTypes.length; ++i) {
        let sysDiskType = vm.sysDiskTypes[i];
        if (sysDiskType.model == model) {
          vm.selectedSysDiskType = i;
          break;
        }
      }
    } else {
      for (let i = 0; i < vm.datDiskTypes.length; ++i) {
        let datDiskType = vm.datDiskTypes[i];
        if (datDiskType.model == model) {
          vm.selectedDatDiskType = i;
          break;
        }
      }
    }
    vm.price = PRODUCT_MANAGERS.get(ProductCate.VM)!.calPrice(
      vm,
      this.payMethod
    );
  }

  onChangedDbDiskType(db: SpecProduct_Db, model: string) {
    for (let i = 0; i < db.diskTypes.length; ++i) {
      let diskType = db.diskTypes[i];
      if (diskType.model == model) {
        db.selectedDiskType = i;
        break;
      }
    }
    db.price = PRODUCT_MANAGERS.get(ProductCate.DB)!.calPrice(
      db,
      this.payMethod
    );
  }

  vendorClicked(v) {
    this.selectedRegionIdx = 0;
    this.step = 2;
  }
  areaClicked(v) {
    this.step = 3;
  }
  findItemInEnums(enums, value) {
    let item = CMN.findItemInEnums(enums, value);
    if (!item) {
      return {};
    }
    return item;
  }
  generateDspByTag(obj, tags) {
    let str = null;
    obj.tags.forEach((o) => {
      let tag = CMN.findItemInEnums(tags, o);
      if (tag) {
        if (!str) {
          str = tag.text;
        } else {
          str = str + "," + tag.text;
        }
      }
    });
    return obj.model + "(" + str + ")";
  }
  addCart(product: SpecProduct) {
    this.$emit("addCart", CMN.deepCopy(product));
  }
  formatPriceDetail(priceInfo: PriceInfo) {
    let htmlStr = `总原价 ￥${priceInfo.oriPrice.toNumber().toFixed(2)}<br/>`;
    htmlStr += `总优惠价 ￥${priceInfo.finPrice.toNumber().toFixed(2)}<br/>`;
    htmlStr += "---------------<br/>";
    priceInfo.dims.forEach((dimInfo) => {
      htmlStr += `${dimInfo.dim}: 单价￥${dimInfo.unitPrice
        .toNumber()}${dimInfo.unitName} x ${
        dimInfo.unitCnt
      } = ￥${dimInfo.oriPrice
        .toNumber()
        .toFixed(2)}(优惠价￥${dimInfo.finPrice.toNumber().toFixed(2)})<br/>`;
    });
    return htmlStr;
  }
}
