import Decimal from "decimal.js"
import {BaseParser} from './PriceInfoParser'
import { DimPriceInfo,BaiduLb,PayMethod, PriceInfo, PricePayTimeGranularity, PricePayType,ProductCate} from "./model"

import {PRODUCT_MANAGERS, ProductManager_Lb} from './ProductManger'

export class Parser4BaiduLb extends BaseParser{
    declare productManager:ProductManager_Lb
    priceInfos:any={}
    specProducts:Array<BaiduLb>=[]

    init(rawInfo:Array<any>): void {
        this.specProducts=[]
        rawInfo.forEach((item)=>{
            try{
                let product=this.parseFromJson(item)
                this.priceInfos[this.getPk(product)]=item
                this.specProducts.push(product)
            }catch(e){
                console.log('解析出错',e)
            }
        })
    }

    calPrice(specProduct: BaiduLb, payMethod:PayMethod): PriceInfo {

        // baiduLb 只有后付费方式
        if(payMethod.payType==PricePayType.PrevPay){
            return null
        }
        let rawPriceInfo=this.priceInfos[this.getPk(specProduct)]
        if(rawPriceInfo.instMinutelyPrice == null || rawPriceInfo.instMinutelyPrice == undefined)
            return null

        let dim=new DimPriceInfo()
        dim.payMethod=payMethod
        dim.dim='BaiduLb:计算资源费用'
        dim.unitName='元/小时'
        dim.unitPrice=new Decimal(rawPriceInfo.instMinutelyPrice).mul(60)
        dim.unitCnt=payMethod.granType*payMethod.granCnt
        dim.finPrice=dim.oriPrice=dim.unitPrice.mul(dim.unitCnt)
        
        let priceInfo=new PriceInfo()
        priceInfo.dims.push(dim)

        //EIP维度
        if(specProduct.bandwidth>0){
            let eip=specProduct.ipTypes[specProduct.selectedIpType]
            eip.bandwidth=specProduct.bandwidth
            let eipPriceInfo=PRODUCT_MANAGERS!.get(ProductCate.LB_IP)!.calPrice(eip,payMethod)
            priceInfo.dims=priceInfo.dims.concat(eipPriceInfo.dims)
        }

        priceInfo.dims.forEach(dim=>{
            priceInfo.finPrice=priceInfo.finPrice.add(dim.finPrice)
            priceInfo.oriPrice=priceInfo.oriPrice.add(dim.oriPrice)
        })


        specProduct.price=priceInfo
        return priceInfo
    }

    parseFromJson(jsonO: any): BaiduLb|null {
        let inst:BaiduLb = new BaiduLb()
        inst.region=jsonO.region
        inst.lbType = jsonO.instType
        inst.spec = '-'
        inst.specType= '-'
        inst.tags=jsonO.tags
        inst.model= inst.lbType
        inst.area=this.productManager.area
        inst.bandwidth=this.productManager.bandwidth
        
        let regionTag = null
        try {
            inst.tags.forEach(tag => {
                if(tag.startsWith('/001')){
                    regionTag = tag
                    return
                }
            });
        } catch (error) {
            console.error(error);
        }
        
        if(inst.bandwidth>0){
            inst.ipTypes=PRODUCT_MANAGERS.get(ProductCate.LB_IP)?.filterProductsInVendor(inst.vendor,new Map([['regionTag',regionTag]]))
            if(!inst.ipTypes || inst.ipTypes.length<=0) return null//没可使用的公网 lb也不显示
        }

        return inst
    }
    
}

