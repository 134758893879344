<template>
  <v-card :v-bind="specialOffer">
    <v-flex ma-2 pa-2>
      <div
        style="margin-bottom: 15px; margin-top: 15px;padding-bottom: 15px;display:flex;justify-content:center;align-items:center;border-width: 0 0 1px 0;border-style: solid;border-color: var(--v-bg-base);
         "
      >
        <span
          v-if="
            specialOffer.colour == ENUMS.SPECIAL_OFFER_STATUS.ongoing.colour
          "
          class="greenColour"
        >
          ◕
        </span>
        <span
          v-if="
            specialOffer.colour == ENUMS.SPECIAL_OFFER_STATUS.noStart.colour
          "
          class="yellowColour"
        >
          ○
        </span>
        <span
          v-if="specialOffer.colour == ENUMS.SPECIAL_OFFER_STATUS.ended.colour"
          class="grayColour"
        >
          ◉
        </span>
        <span class="card-title">
          {{ specialOffer.product }} - {{ specialOffer.name }}
        </span>
      </div>
      <div style="position:relative">
        <span class="field"> 价格: </span>
        <div class="value right">{{ specialOffer.priceIntro }}</div>
      </div>
      <div style="margin-right: 15px">
        <span class="field">简介: </span>
        <div class="value">{{ specialOffer.intro }}</div>
      </div>
      <v-expansion-panels>
        <v-expansion-panel>
          <v-expansion-panel-header
            color="primary"
            dark
            style="height:30px;;min-height:30px; color:white;"
          >
            详情
          </v-expansion-panel-header>
          <v-expansion-panel-content style="padding-top:10px" color="bg">
            <div style="display:flex;justify-content:space-between">
              <span class="field"> 产品大类: </span>
              <span class="value"> {{ specialOffer.productCate }} </span>
            </div>
            <div style="display:flex;justify-content:space-between">
              <span class="field"> 约束条件: </span>
              <div class="value constraint">{{ specialOffer.conditions }}</div>
            </div>
            <div style="display:flex;justify-content:space-between">
              <span class="field "> 开始时间: </span>
              <span class="value"> {{ specialOffer.begin }}</span>
            </div>
            <div style="display:flex;justify-content:space-between">
              <span class="field"> 结束时间: </span>
              <span class="value">{{ specialOffer.end }} </span>
            </div>
            <div style="margin-right: 15px">
              <span class="field"> 备注: </span>
              <div class="value">{{ specialOffer.remark }}</div>
            </div>
            <div style="margin-bottom: 10px">
              <span class="field">活动链接:</span>
              <a :href="specialOffer.url" target="view_window" class="url">
                {{ specialOffer.url }}
              </a>
            </div>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </v-flex>
  </v-card>
</template>
<script>
import ENUMS from "../cmn/js/enums";

export default {
  name: "SpecialOfferCard",
  props: {
    specialOffer: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      ENUMS,
      panel: ["true"],
    };
  },
  methods: {
    // getTabName(){
    //     for(var item in this.dataList){
    //         for(var key in this.dataList[item]){
    //             console.log('$$$d1$$$$',key)
    //             this.tabName.push(key)
    //         }
    //     }
    // }
  },
};
</script>

<style type="text/css">
.field {
  font-size: 15px;
  font-weight: 450;
  color: black;
}
.value {
  font-size: 15px;
  color: var(--v-text_black-base);
  white-space: pre-line;
}
.card-title {
  font-size: 16px;
  font-weight: 700;
}
.card {
  width: 30%;
}
.url {
  font-size: 15px;
  width: 100%;
  display: block;
  overflow: hidden;
  word-break: keep-all;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.greenColour {
  font-size: 20px;
  color: green;
}
.yellowColour {
  font-size: 25px;
  color: yellow;
}
.grayColour {
  font-size: 20px;
  color: gray;
}
.right {
  color: var(--v-text_color5-base);
  text-align: right;
}
.constraint {
  color: var(--v-text_color5-base);
}
</style>
