import Decimal from "decimal.js"
import { parseVendorFromKey, PayMethod, PriceInfo, ProductCate, SpecProduct, SpecProduct_Db, SpecProduct_DbDisk, SpecProduct_Vm, SpecProduct_Lb,Vendor, SpecProduct_Os, SpecProduct_OsPackage, SpecProduct_CdnPackage, SpecProduct_Cdn } from "./model"

import CMN from '@/cmn/js/common.js'
import request from './js/requests.js'
import { IProductPriceParser } from "./PriceInfoParser"
import { Parser4AliyunDisk, Parser4AliyunIp, Parser4Ecs } from "./PriceInfoParserAliyunVm"
import { Parser4Cvm, Parser4TencentDisk, Parser4TencentIp } from "./PriceInfoParserTencentVm"
import { Parser4BaiduDisk, Parser4BaiduIp, Parser4Bcc } from "./PriceInfoParserBaiduVm"
import { Parser4HuaweiDisk, Parser4HuaweiEcs, Parser4HuaweiIp } from "./PriceInfoParserHuaweiVm"
import TAGS from './js/tags.js';
import { Parser4AliyunDb,Parser4AliyunDbDisk } from "./PriceInfoParserAliyunDb"
import { Parser4BaiduDb,Parser4BaiduDbDisk } from "./PriceInfoParserBaiduDb"
import { Parser4HuaweiDb,Parser4HuaweiDbDisk } from "./PriceInfoParserHuaweiDb"
import { Parser4TencentDb,Parser4TencentDbDisk } from "./PriceInfoParserTencentDb"
import { Parser4AliyunLb } from "./PriceInfoParserAliyunLb"
import { Parser4BaiduLb } from "./PriceInfoParserBaiduLb"
import { Parser4HuaweiLb } from "./PriceInfoParserHuaweiLb"
import { Parser4TencentLb,Parser4TencentLbIp } from "./PriceInfoParserTencentLb"
import { Parser4TencentOs, Parser4TencentOsPackage } from "./PriceInfoParserTencentOs"
import { Parser4BaiduOs, Parser4BaiduOsPackage } from "./PriceInfoParserBaiduOs "
import { Parser4HuaweiOs, Parser4HuaweiOsPackage } from "./PriceInfoParserHuaweiOs"
import { Parser4AliyunOs, Parser4AliyunOsPackage } from "./PriceInfoParserAliyunOs"
import { Parser4TencentCdn, Parser4TencentCdnPackage } from "./PriceInfoParserTencentCdn"
import { Parser4AliyunCdn, Parser4AliyunCdnPackage } from "./PriceInfoParserAliyunCdn"
import { Parser4BaiduCdn, Parser4BaiduCdnPackage } from "./PriceInfoParserBaiduCdn"
import { Parser4HuaweiCdn, Parser4HuaweiCdnPackage } from "./PriceInfoParserHuaweiCdn"




/**
 * 具体类型商品管理器
 * 管理本类型商品的各云信息
 */
export interface IProductManager{
    /**商品类型 */
    cate:ProductCate
    /**地区 */
    area:string
    
    /**
     * 按所选条件 从后台加载各云可选商品与价格
     */
    loadProductsAndPrices():void
    /**
     * 从具体厂商的可选商品中 筛选出符合条件的商品
     * @param vendor 
     * @param filters 
     */
    filterProductsInVendor(vendor:Vendor, filters: Map<string, any>): SpecProduct[]
    /**
     * 从所有厂商的可选商品中 筛选出符合条件的商品
     * @param filters 
     */
    filterProducts(filters:Map<string,any>):Map<Vendor,Array<SpecProduct>>
    /**
     * 对指定商品计价
     * @param specProduct 
     * @param payMethod 
     */
    calPrice(specProduct:SpecProduct,payMethod:PayMethod):PriceInfo
}
export abstract class BaseProductManger implements IProductManager{
    abstract cate: ProductCate
    abstract area: string

    // __priceInfos:Map<Vendor,any>=new Map()
    __products:Map<Vendor,Array<SpecProduct>>=new Map()

    abstract __parsers:Map<Vendor,IProductPriceParser>

    abstract fetchRawPriceInfo():any

    async loadProductsAndPrices() {
        let rawPriceInfos:any = await this.fetchRawPriceInfo()
        console.log('raw price info', rawPriceInfos)
        for(let vendorStr in rawPriceInfos){
            let vendor=parseVendorFromKey(vendorStr)
            //TODO test
            // if(vendor!=Vendor.Aliyun && vendor!=Vendor.Tencent && vendor!=Vendor.Baidu) continue
            let rawModelPriceInfos=rawPriceInfos[vendorStr]
            // this.__priceInfos.set(vendor,rawModelPriceInfos)

            let parser=this.__parsers.get(vendor)
            if(!parser) throw Error(`没厂商${vendor}解析器`)
            parser.init(rawModelPriceInfos)
            this.__products.set(vendor,parser.getSpecProducts())
        }
    }

    filterProductsInVendor(vendor:Vendor, filters: Map<string, any>): SpecProduct[] {
        let region=filters.get('region')
        let regionTag=filters.get('regionTag')

        let result:SpecProduct[]=[]
        let products:SpecProduct[]=CMN.deepCopy(this.__products.get(vendor))
        products.forEach((product)=>{

            if(region && product.region!=region){
                return
            }

            if(regionTag && product.tags.indexOf(regionTag)==-1){
                return
            }
            result.push(product)
        })

        return result
    }
    filterProducts(filters: Map<string, any>): Map<Vendor, SpecProduct[]> {
        console.log('filter start...', filters,this.__products)
        let result=new Map()

        // let productsByVender:Map<Vendor,Array<SpecProduct>>=Object.create(this.__products) //CMN.deepCopy(this.__products)

        // let productsByVender:Map<Vendor,Array<SpecProduct>>=this.__products
        // let productsByVender:Map<Vendor,Array<SpecProduct>>=new Map()
        // this.__products.forEach((products,vendor)=>{
        //     productsByVender.set(vendor,JSON.parse(JSON.stringify((products))))
        // })

        this.__products.forEach((products,vendor)=>{
            let resultInVendor=this.filterProductsInVendor(vendor,filters)
            if(resultInVendor&&resultInVendor.length>0){
                result.set(vendor,resultInVendor)
            }
        })
        console.log('filter end...', result,this.__products)

        return result
    }

    calPrice(specProduct: SpecProduct, payMethod: PayMethod): PriceInfo {
        return this.__parsers.get(specProduct.vendor)!.calPrice(specProduct,payMethod);
    }

}

export class ProductManager_Vm extends BaseProductManger{
    __parsers=new Map<Vendor, IProductPriceParser>([
        [Vendor.Aliyun, new Parser4Ecs(this)],
        [Vendor.Tencent, new Parser4Cvm(this)],
        [Vendor.Baidu, new Parser4Bcc(this)],
        [Vendor.Huawei, new Parser4HuaweiEcs(this)]
    ])
    __products:Map<Vendor,Array<SpecProduct_Vm>>=new Map()
    cate=ProductCate.VM
    area!: string
    cpu=2
    mem=4
    sysDiskSize=40
    datDiskSize=0
    bandwidth=5

    private static _inst=new ProductManager_Vm()
    static getInstance():ProductManager_Vm{ return ProductManager_Vm._inst}

    async fetchRawPriceInfo() {
        if(this.sysDiskSize>0 || this.datDiskSize>0){
            let productMng=PRODUCT_MANAGERS.get(ProductCate.DISK)
            productMng!.area = this.area
            await productMng!.loadProductsAndPrices()
        }
        if(this.bandwidth>0){
            let productMng=PRODUCT_MANAGERS.get(ProductCate.IP)
            productMng!.area = this.area
            await productMng!.loadProductsAndPrices()
        }
        let tags = TAGS.getAreaTags(this.area)
        return await request.getVm(this.cpu,this.mem,tags)
    }

    filterProductsInVendor(vendor:Vendor, filters: Map<string, any>): SpecProduct[] {
        let region=filters.get('region')
        let vmTags:string[]=filters.get('vmTags')
        let sysDiskTags:string[]=filters.get('sysDiskTags')
        let datDiskTags:string[]=filters.get('datDiskTags')
        let result:SpecProduct[]=[]
        let products:SpecProduct_Vm[]=CMN.deepCopy(this.__products.get(vendor))
        products.forEach((product)=>{

            if(region && product.region!=region){
                return
            }
            if(vmTags && vmTags.length>0){
                let match=false
                vmTags.some((tag)=>{
                    if(product.tags.indexOf(tag)>=0){// .has(tag)){
                        match=true
                        return true
                    }
                })
                if(!match) return
            }
            if(sysDiskTags && sysDiskTags.length>0){
                product.sysDiskTypes=product.sysDiskTypes.filter((disk)=>{
                    let match=false
                    sysDiskTags.some((tag)=>{
                        if(disk.tags.indexOf(tag)>=0){
                            match=true
                            return true
                        }
                    })
                    return match
                })
                if(product.sysDiskTypes.length<=0) return
            }
            if(datDiskTags && datDiskTags.length>0){
                product.datDiskTypes=product.datDiskTypes.filter((disk)=>{
                    let match=false
                    datDiskTags.some((tag)=>{
                        if(disk.tags.indexOf(tag)>=0){
                            match=true
                            return true
                        }
                    })
                    return match
                })
                if(product.datDiskTypes.length<=0) return
            }
            result.push(product)
        })

        return result
    }
}
export class ProductManager_Disk extends BaseProductManger{
    cate=ProductCate.DISK
    area!:string
    // storeSize=40

    __parsers=new Map<Vendor,IProductPriceParser>([
        [Vendor.Aliyun,new Parser4AliyunDisk(this)],
        [Vendor.Tencent,new Parser4TencentDisk(this)],
        [Vendor.Baidu,new Parser4BaiduDisk(this)],
        [Vendor.Huawei,new Parser4HuaweiDisk(this)],
    ])

    private static _inst=new ProductManager_Disk()
    static getInstance():ProductManager_Disk{ return ProductManager_Disk._inst}

    async fetchRawPriceInfo() {
        let tags = TAGS.getAreaTags(this.area)
        return await request.getDisk(tags)
    }

}
export class ProductManager_Ip extends BaseProductManger{
    cate=ProductCate.IP
    area!: string
    __parsers=new Map<Vendor, IProductPriceParser>([
        [Vendor.Aliyun, new Parser4AliyunIp(this)],
        [Vendor.Tencent, new Parser4TencentIp(this)],
        [Vendor.Baidu, new Parser4BaiduIp(this)],
        [Vendor.Huawei, new Parser4HuaweiIp(this)]
    ])
    async fetchRawPriceInfo() {
        let tags = TAGS.getAreaTags(this.area)
        return await request.getIp(tags)
    }

    private static _inst=new ProductManager_Ip()
    static getInstance():ProductManager_Ip{ return ProductManager_Ip._inst}

}

export class ProductManager_Db extends BaseProductManger{
    __parsers=new Map<Vendor, IProductPriceParser>([
        [Vendor.Aliyun, new Parser4AliyunDb(this)],
        [Vendor.Tencent, new Parser4TencentDb(this)],
        [Vendor.Baidu, new Parser4BaiduDb(this)],
        [Vendor.Huawei, new Parser4HuaweiDb(this)]
    ])

    cate=ProductCate.DB
    area!: string
    engine = 'MySql'
    engineVer = '5.7'
    cpu=2
    mem=4
    datDiskSize=40

    async fetchRawPriceInfo() {
        if(this.datDiskSize>0){
            let productMng=PRODUCT_MANAGERS.get(ProductCate.DB_DISK)
            productMng!.area = this.area
            await productMng!.loadProductsAndPrices()
        }      
        let tags = TAGS.getAreaTags(this.area)
        return await request.getDb(this.cpu,this.mem,this.engine,this.engineVer,tags)
    }

    private static _inst=new ProductManager_Db()
    static getInstance():ProductManager_Db{ return ProductManager_Db._inst}

    
    
    filterProductsInVendor(vendor:Vendor, filters: Map<string, any>): SpecProduct[] {
        let region=filters.get('region')
        let dbTags:string[]=filters.get('dbSpecTags')
        let archTags:string[]=filters.get('dbArchTags')
        let diskTags:string[]=filters.get('dbDiskTags')
        let result:SpecProduct[]=[]

        let products:SpecProduct_Db[]=CMN.deepCopy(this.__products.get(vendor))
        products.forEach((product)=>{
            if(region && product.region!=region){
                return
            }
            if(dbTags && dbTags.length>0){
                let match=false
                dbTags.some((tag)=>{
                    if(product.tags.indexOf(tag)>=0){// .has(tag)){
                        match=true
                        return true
                    }
                })
                if(!match) return
            }
            if(archTags && archTags.length>0){
                let match=false
                archTags.some((tag)=>{
                    if(product.tags.indexOf(tag)>=0){// .has(tag)){
                        match=true
                        return true
                    }
                })
                if(!match) return
            }

            if(diskTags && diskTags.length>0){
                product.diskTypes=product.diskTypes.filter((disk)=>{
                    let match=false
                    diskTags.some((tag)=>{
                        if(disk.tags.indexOf(tag)>=0){
                            match=true
                            return true
                        }
                    })
                    return match
                })
                if(product.diskTypes.length<=0) return
            }
            result.push(product)
        })

        return result
    }

}

export class ProductManager_DbDisk extends BaseProductManger{
    cate=ProductCate.DB_DISK
    area!:string
    // storeSize=40

    __parsers=new Map<Vendor,IProductPriceParser>([
        [Vendor.Aliyun,new Parser4AliyunDbDisk(this)],
        [Vendor.Tencent,new Parser4TencentDbDisk(this)],
        [Vendor.Baidu,new Parser4BaiduDbDisk(this)],
        [Vendor.Huawei,new Parser4HuaweiDbDisk(this)],
    ])

    private static _inst=new ProductManager_DbDisk()
    static getInstance():ProductManager_DbDisk{ return ProductManager_DbDisk._inst}

    async fetchRawPriceInfo() {
        let tags = TAGS.getAreaTags(this.area)
        return await request.getDbDisk(tags)
    }

    filterProductsInVendor(vendor:Vendor, filters: Map<string, any>): SpecProduct[] {
        let region=filters.get('region')
        let engine:string=filters.get('engine')
        let archType:string=filters.get('archType')

        let result:SpecProduct[]=[]

        let products:SpecProduct_DbDisk[]=CMN.deepCopy(this.__products.get(vendor))
        products.forEach((product)=>{
            if(region && product.region!=region){
                return
            }
            if(product.engine == engine
                 && product.archType == archType
            ){
                    result.push(product)
            }
            
        })

        return result
    }
}

export class ProductManager_Lb extends BaseProductManger{
    __parsers=new Map<Vendor, IProductPriceParser>([
        [Vendor.Aliyun, new Parser4AliyunLb(this)],
        [Vendor.Tencent, new Parser4TencentLb(this)],
        [Vendor.Baidu, new Parser4BaiduLb(this)],
        [Vendor.Huawei, new Parser4HuaweiLb(this)]
    ])

    __products:Map<Vendor,Array<SpecProduct_Lb>>=new Map()
    cate=ProductCate.LB
    area!: string
    bandwidth=5

    private static _inst=new ProductManager_Lb()
    static getInstance():ProductManager_Lb{ return ProductManager_Lb._inst}

    async fetchRawPriceInfo() {
        if(this.bandwidth>0){
            let productMng=PRODUCT_MANAGERS.get(ProductCate.LB_IP)
            productMng!.area = this.area
            await productMng!.loadProductsAndPrices()
        }
        let tags = TAGS.getAreaTags(this.area)
        return await request.getLb(tags,this.bandwidth)
    }

    filterProductsInVendor(vendor:Vendor, filters: Map<string, any>): SpecProduct[] {
        let region=filters.get('region')
        let lbTags:string[]=filters.get('lbTags')
        let result:SpecProduct[]=[]
        let products:SpecProduct_Lb[]=CMN.deepCopy(this.__products.get(vendor))
        products.forEach((product)=>{

            if(region && product.region!=region){
                return
            }
            if(lbTags && lbTags.length>0){
                let match=false
                lbTags.some((tag)=>{
                    if(product.tags.indexOf(tag)>=0){// .has(tag)){
                        match=true
                        return true
                    }
                })
                if(!match) return
            }
            result.push(product)
        })

        return result
    }
}

export class ProductManager_LbIp extends BaseProductManger{
    cate=ProductCate.LB_IP
    area!: string
    __parsers=new Map<Vendor, IProductPriceParser>([
        [Vendor.Aliyun, new Parser4AliyunIp(this)],
        [Vendor.Tencent, new Parser4TencentLbIp(this)],
        [Vendor.Baidu, new Parser4BaiduIp(this)],
        [Vendor.Huawei, new Parser4HuaweiIp(this)]
    ])
    async fetchRawPriceInfo() {
        let tags = TAGS.getAreaTags(this.area)
        return await request.getLbIp(tags)
    }
    private static _inst=new ProductManager_LbIp()
    static getInstance():ProductManager_LbIp{ return ProductManager_LbIp._inst}

}

export class ProductManager_Os extends BaseProductManger{
    __parsers=new Map<Vendor, IProductPriceParser>([
        [Vendor.Aliyun, new Parser4AliyunOs(this)],
        [Vendor.Tencent, new Parser4TencentOs(this)],
        [Vendor.Baidu, new Parser4BaiduOs(this)],
        [Vendor.Huawei, new Parser4HuaweiOs(this)]
    ])

    __products:Map<Vendor,Array<SpecProduct_Os>>=new Map()
    cate=ProductCate.OS
    area!: string
    osStoreSize = 40
    // osRequetCnt = 40
    osFlowSize = 40

    private static _inst=new ProductManager_Os()
    static getInstance():ProductManager_Os{ return ProductManager_Os._inst}

    async fetchRawPriceInfo() {
        let tags = TAGS.getAreaTags(this.area)
        if(this.osStoreSize>0 || this.osFlowSize>0){
            let productMng=PRODUCT_MANAGERS.get(ProductCate.OS_PACKAGE)
            productMng!.area = this.area
            await productMng!.loadProductsAndPrices()
        }
        return await request.getOs(tags)
    }

    filterProductsInVendor(vendor:Vendor, filters: Map<string, any>): SpecProduct[] {
        let result:SpecProduct[]=[]
        let products:SpecProduct_Os[]=CMN.deepCopy(this.__products.get(vendor))
        let osTags:string[]=filters.get('osTags')
        let zoneTags:string[]=filters.get('zoneTags')



        products.forEach((product)=>{
            
            if(osTags && osTags.length>0){
                let match=false
                osTags.some((tag)=>{
                    if(product.tags.indexOf(tag)>=0){// .has(tag)){
                        match=true
                        return true
                    }
                })
                if(!match) return
            }

            if(zoneTags && zoneTags.length>0){
                let match=false
                zoneTags.some((tag)=>{
                    if(product.tags.indexOf(tag)>=0){// .has(tag)){
                        match=true
                        return true
                    }
                })
                if(!match) return
            }
            result.push(product)
        })

        return result
    }
}


export class ProductManager_OsPackage extends BaseProductManger{
    __parsers=new Map<Vendor, IProductPriceParser>([
        [Vendor.Aliyun, new Parser4AliyunOsPackage(this)],
        [Vendor.Tencent, new Parser4TencentOsPackage(this)],
        [Vendor.Baidu, new Parser4BaiduOsPackage(this)],
        [Vendor.Huawei, new Parser4HuaweiOsPackage(this)]
    ])

    __products:Map<Vendor,Array<SpecProduct_OsPackage>>=new Map()
    cate=ProductCate.OS_PACKAGE
    area!: string

    private static _inst=new ProductManager_OsPackage()
    static getInstance():ProductManager_OsPackage{ return ProductManager_OsPackage._inst}

    async fetchRawPriceInfo() {
        let tags = TAGS.getAreaTags(this.area)
        return await request.getOsPackage(tags)
    }

    filterProductsInVendor(vendor:Vendor, filters: Map<string, any>): SpecProduct[] {
        let result:SpecProduct[]=[]
        let products:SpecProduct_OsPackage[]=CMN.deepCopy(this.__products.get(vendor))
        let resourceType = filters.get('resourceType')
        let regionTag=filters.get('regionTag')
        
        products.forEach((product)=>{
            if(resourceType && product.resourceType != resourceType) return
            if(regionTag && product.tags.indexOf(regionTag)==-1){
                return
            }
            result.push(product)
        })

        return result
    }
}


export class ProductManager_Cdn extends BaseProductManger{
    __parsers=new Map<Vendor, IProductPriceParser>([
        [Vendor.Aliyun, new Parser4AliyunCdn(this)],
        [Vendor.Tencent, new Parser4TencentCdn(this)],
        [Vendor.Baidu, new Parser4BaiduCdn(this)],
        [Vendor.Huawei, new Parser4HuaweiCdn(this)]
    ])

    __products:Map<Vendor,Array<SpecProduct_Cdn>>=new Map()
    cate=ProductCate.CDN
    area!: string
    cdnFlowSize = 40

    private static _inst=new ProductManager_Cdn()
    static getInstance():ProductManager_Cdn{ return ProductManager_Cdn._inst}

    async fetchRawPriceInfo() {
        let tags = TAGS.getAreaTags(this.area)
        if(this.cdnFlowSize>0){
            let productMng=PRODUCT_MANAGERS.get(ProductCate.CDN_PACKAGE)
            productMng!.area = this.area
            await productMng!.loadProductsAndPrices()
        }
        return await request.getCdn(tags)
    }

    
}

export class ProductManager_CdnPackage extends BaseProductManger{
    __parsers=new Map<Vendor, IProductPriceParser>([
        [Vendor.Aliyun, new Parser4AliyunCdnPackage(this)],
        [Vendor.Tencent, new Parser4TencentCdnPackage(this)],
        [Vendor.Baidu, new Parser4BaiduCdnPackage(this)],
        [Vendor.Huawei, new Parser4HuaweiCdnPackage(this)]
    ])

    __products:Map<Vendor,Array<SpecProduct_CdnPackage>>=new Map()
    cate=ProductCate.CDN_PACKAGE
    area!: string

    private static _inst=new ProductManager_CdnPackage()
    static getInstance():ProductManager_CdnPackage{ return ProductManager_CdnPackage._inst}

    async fetchRawPriceInfo() {
        let tags = TAGS.getAreaTags(this.area)
        return await request.getCdnPackage(tags)
    }

    filterProductsInVendor(vendor:Vendor, filters: Map<string, any>): SpecProduct[] {
        let result:SpecProduct[]=[]
        let products:SpecProduct_CdnPackage[]=CMN.deepCopy(this.__products.get(vendor))
        let resourceType = filters.get('resourceType')
        let regionTags=filters.get('regionTags')

        products.forEach((product)=>{
            if(resourceType && product.resourceType != resourceType) return

            if(regionTags && regionTags.length>0){
                let match=false
                regionTags.some((tag)=>{
                    if(product.tags.indexOf(tag)>=0){// .has(tag)){
                        match=true
                        return true
                    }
                })
                if(!match) return
            }
            result.push(product)
        })

        return result
    }
}


export let PRODUCT_MANAGERS=new Map<ProductCate,IProductManager>([
    [ProductCate.VM,ProductManager_Vm.getInstance()],
    [ProductCate.DISK,ProductManager_Disk.getInstance()],
    [ProductCate.IP,ProductManager_Ip.getInstance()],
    [ProductCate.DB,ProductManager_Db.getInstance()],
    [ProductCate.DB_DISK,ProductManager_DbDisk.getInstance()],
    [ProductCate.LB,ProductManager_Lb.getInstance()],
    [ProductCate.LB_IP,ProductManager_LbIp.getInstance()],
    [ProductCate.OS,ProductManager_Os.getInstance()],
    [ProductCate.OS_PACKAGE,ProductManager_OsPackage.getInstance()],
    [ProductCate.CDN,ProductManager_Cdn.getInstance()],
    [ProductCate.CDN_PACKAGE,ProductManager_CdnPackage.getInstance()],


])