import df from 'date-format'


let DU = {
    date2Str(date,format='yyyy-MM-dd'){
        return df.asString(format,date)
    },
    date2Str(date){
        return df.asString('yyyy-MM-dd',date)
    }

}
export default DU;

